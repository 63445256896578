/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import { Link } from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Create an Account
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
      </div>
      {/* begin:: Form group Role */}
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='role'
              value='student'
              id='kt_create_account_form_account_type_student'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_student'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Student</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  If you need more info, please check it out
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='role'
              value='teacher'
              id='kt_create_account_form_account_type_teacher'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_teacher'
            >
              <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Teacher</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Create teacher account to mane users
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='role' />
          </div>
        </div>
      </div>
      {/* end:: Form group Role */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <Field
            type='checkbox'
            className='form-check-input'
            name='acceptTerms'
            id='kt_login_toc_agree'
          />

          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='acceptTerms' />
        </div>
      </div>
      {/* end::Form group */}
    </div>
  )
}

export {Step1}
