import _ from 'lodash'
export interface Props {
  isError: boolean
  messages: any
}

const ErrorMessages = ({isError, messages}: Props) => {
  if(!isError){
    return null
  }
  //console.log('messages', messages)
  
  return _.isArray(messages) ? (
    <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
      <div className='d-flex flex-column'>
        {messages?.map((err: any) => (
          <span>{err}</span>
        ))}
      </div>
    </div>
  ) : (
    <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
      <div className='d-flex flex-column'>{messages || 'Something went wrong!'}</div>
    </div>
  )
}

export default ErrorMessages