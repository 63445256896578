import {FC, useEffect, useRef} from 'react'
import {Field, ErrorMessage} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {useAuth} from '../../../auth'
import useCurrentClientRoute from '../../../auth/hooks/useCurrentClientRoute'
import {Link as SLink} from 'react-scroll'
import {IProgram} from '../../core/_models'
import clsx from 'clsx'
interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  toScroll?: (name: string) => void
}
const Step1 = ({currentFormikVal, setFieldValue, toScroll}: Props) => {
  const {currentUser} = useAuth()
  const {currentClientRoute} = useCurrentClientRoute()

  const {getProgramsEnroll} = useAppRequests()
  const {
    isLoading,
    isError,
    data: productgroup_list,
  } = useQuery('programsEnroll', () =>
    getProgramsEnroll(currentUser ? currentUser.currentClient : currentClientRoute)
  )

  if (isLoading) {
    return (
      <div className='w-100'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  const updateCurrentproductgroup = (index: number) => {
    setFieldValue('productgroup', index)
    setFieldValue('product', -1)
    setFieldValue('academicTrack', '')
    if (toScroll) {
      toScroll('step1')
    }
  }

  if (isError) {
    return (
      <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Oops!</h5>
          <span>Could not load the programs!</span>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='pb-10 pb-lg-5 text-center'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          {currentClientRoute === '47821104'
            ? 'STEP 1: PLEASE CHOOSE YOUR CAMPUS LOCATION'
            : 'STEP 1: CHOOSE A PROGRAM'}
        </h2>
        <div className='d-flex flex-column fs-5 my-5'>
          {/* Open enrollment travel programs are listed in alphabetical order starting by country,
          state, or island name. Scroll to the bottom of the list if you are enrolling in a virtual
          internship or custom group travel program. */}
          <span className='fw-bolder'>
            Open enrollment {currentClientRoute === '47821104' ? 'pre-college' : 'travel' } programs are listed in alphabetical order.{' '}
          </span>
          <span>Be sure to enroll in the correct program before moving on to select corresponding session dates.
          </span>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          {productgroup_list?.programs.map((p, i) => (
            <div className='col-lg-12' key={i}>
              <SLink
                activeClass='active'
                to={'enrollment-step2'}
                spy={true}
                smooth={true}
                duration={250}
                onClick={() => updateCurrentproductgroup(i)}
              >
                <Field
                  type='radio'
                  className='btn-check'
                  name='productgroup'
                  value={i}
                  id={'productgroup_' + i}
                />
                <label
                  className={clsx(
                    'btn btn-outline btn-outline-dashed btn-outline-default py-2 px-5 d-flex align-items-center h-100 justify-content-between',
                    currentFormikVal.productgroup === i ? 'bg-light-primary' : ''
                  )}
                  htmlFor={'productgroup_' + i}
                  style={{borderWidth: '2px'}}
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bold d-block fs-6'></span>
                    {p.name}
                  </span>
                </label>
              </SLink>
            </div>
          ))}
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='productgroup' />
        </div>
      </div>
    </div>
  )
}

export default Step1
