import { QueryRequestProvider } from '../../../Shared/core/QueryRequestProvider'
import {OpportunitiesListViewProvider, useListView} from './core/OpportunitiesListViewProvider'

import {QueryResponseProvider} from './core/QueryResponseProvider'

type Props = {
  children: JSX.Element
}

const OpportunitiesProvider = ({children}: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <OpportunitiesListViewProvider>{children}</OpportunitiesListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default OpportunitiesProvider
