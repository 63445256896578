import React, { useState } from 'react'
import CustomForm, { IInitialValue } from './components/CustomForm'
import { axiosPrivate } from '../../../../axiosInstance'
import { ScrollTopComponent } from '../../../../_metronic/assets/ts/components'
  
const initialValues = {
  name: '',
  type: '',
  due: 0,
  amount: 0,
  qb_name: '',
  coa: '',
  division: '',
  is_active: true,
  scholarship_code: '',
  sf_id: '',
  pb_id: '',
}


const DiscountAdd = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = async (inputs: IInitialValue) => {
    try {
      const payload = {
        ...inputs,
        due: inputs.due || null
      }
      const res = await axiosPrivate.post('add_discount', payload)
      setSuccessMessage(res.data)
    } catch (error: any) {
      setErrorMessage(error?.message)
    } finally {
      setTimeout(() => {
        setSuccessMessage('')
        setErrorMessage('')
      }, 8000)
      ScrollTopComponent.goTop()
    }
  }

  return (
    <>
      {successMessage && (
          <div className='card-body'>
            <div className='alert alert-success d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>{successMessage}</span>
              </div>
            </div>
          </div>
        )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <CustomForm 
        initialValues={initialValues}
        // EnrollmentFormSchema={EnrollmentFormSchema}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default DiscountAdd