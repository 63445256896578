import {useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../auth'
import addPaymentMethodReq from '../Hooks/useAppRequests'
import updatePaymentMethodReq from '../Hooks/useAppRequests'
import _queryKeys from '../core/_queryKeys'
import PaymentMethodField from './Shared/PaymentMethodField'
import useAppRequests from '../Hooks/useAppRequests'
import PaymentModal from './modal/PaymentModal'

const ListPayment = () => {
  const [message, setMessage] = useState('')
  const {currentUser} = useAuth()

  const {
    getUserPaymentsReq,
    addPaymentMethodReq,
    updatePaymentMethodReq,
  } = useAppRequests()
  const {pMethodId} = useParams()

  const {isLoading: isUpdatepMethodLoading, data: payments_data} = useQuery(
    _queryKeys.getUserPaymentsReq,
    () => getUserPaymentsReq(currentUser?.id)
  )

  const selectedPayment = payments_data?.payment_methods.filter(
    (pm) => pm.id?.toString() === pMethodId
  )[0]

  const [itemId, setItemId] = useState<string | undefined>(undefined)
  const [addItem, setAddItem] = useState<boolean>(false)
  const addNewPayment = (): any => {
    setAddItem(true)
  }

  const active_payment = payments_data?.payment_methods.filter((pm) => !!pm.isActive)[0]

  const {
    isLoading: isCreatePMethodLoading,
    isSuccess: isCreatePMethodSuccess,
    isError: isCreatePMethodError,
    mutateAsync: createPMethodByAdminAsync,
  } = useMutation(addPaymentMethodReq)

  const {
    isLoading: isUpdatePMethodLoading,
    isSuccess: isUpdatePMethodSuccess,
    isError: isUpdatePMethodError,
    mutateAsync: updatePMethodByAdminAsync,
  } = useMutation(updatePaymentMethodReq)

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body py-3'>
        <button type='button' className='btn btn-sm btn-primary me-5' onClick={addNewPayment}>
          <i className='fa-solid fa-plus pb-1 pe-0'></i> Add New Payment Method
        </button>
      </div>
      {(isCreatePMethodSuccess || isUpdatePMethodSuccess) && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {(isCreatePMethodError || isUpdatePMethodError) && (
        <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span>Some error occurred while processing your request!</span>
          </div>
        </div>
      )}
      {payments_data && (
        <div className='mb-7'>
          <PaymentMethodField
            paymentData={payments_data}
            isPaymentLoading={isUpdatepMethodLoading}
            payment_method={selectedPayment}
            id={itemId}
            setMessage={setMessage}
            close={setItemId}
          />
        </div>
      )}
      {addItem && (
        <PaymentModal
          id={undefined}
          isPaymentLoading={isCreatePMethodLoading || isUpdatePMethodLoading}
          payment_method={selectedPayment}
          setMessage={setMessage}
          close={setAddItem}
          paymentMutateAsync={{
            createPMethodByAdminAsync,
            updatePMethodByAdminAsync,
          }}
        />
      )}
    </div>
  )
}

export default ListPayment
