// @ts-nocheck
import {ProductCustomHeader} from './ProductCustomHeader'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../auth'
import moment from 'moment'
import { currencyFormat } from '../../../../../../../utils/funcHelpers'

const productsColumns = [
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Product Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => {
      const {currentUser} = useAuth()
      return (
        <Link
          to={`/apps/${currentUser?.currentClient}/products-management/edit/${
            props.data[props.row.index].id
          }`}
        >
          {props.data[props.row.index].program_summary}
        </Link>
      )
    },
  },
  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='Country' className='min-w-125px' />
  //   ),
  //   accessor: 'country',
  // },
  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
  //   ),
  //   id: 'start_date',
  //   Cell: ({...props}) => {
  //     return <span>{moment(props.data[props.row.index].start_date).format('MMM DD, YYYY')}</span>
  //   },
  // },
  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='End Date' className='min-w-125px' />
  //   ),
  //   id: 'end_date',
  //   Cell: ({...props}) => {
  //     return <span>{moment(props.data[props.row.index].end_date).format('MMM DD, YYYY')}</span>
  //   },
  // },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Duration' className='min-w-125px' />
    ),
    accessor: 'duration',
  },
  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='Status' className='min-w-125px' />
  //   ),
  //   accessor: 'status',
  // },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title='Due Upon Application'
        className='min-w-125px'
      />
    ),
    id: 'due_upon_application',
    Cell: ({...props}) => {
      return <span>{currencyFormat.format(props.data[props.row.index].due_upon_application)}</span>
    },
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Tuition' className='min-w-125px' />
    ),
    id: 'tuition',
    Cell: ({...props}) => {
      return <span>{currencyFormat.format(props.data[props.row.index].tuition)}</span>
    },
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Client' className='min-w-125px' />
    ),
    accessor: 'portal_client.abbreviation',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => {
      console.log(props, 'asdasdasd')
      return (
        <div
          style={{
            backgroundColor: props.data[props.row.index].is_active ? '#e8fff3' : '#ffe8e8',
            color: props.data[props.row.index].is_active ? '#50cd89' : '#cd5050',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            width: 70,
            textAlign: 'center'
          }}
        >
          {props.data[props.row.index].is_active ? 'Active' : 'Inactive'}
        </div>
      )
    },
  },
]

export {productsColumns}
