import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../../../../utils'
import {Field, ErrorMessage} from 'formik'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { usStates } from '../../../../../utils'
import React, {useState} from 'react'

const Step2 = ({errMessage}: {errMessage: string}) => {

  const [selected, setSelected] = useState('')

  const changeSelectOptionHandler = (e: React.ChangeEvent<HTMLInputElement>): any => { 
    setSelected(e.target.value);
    // currentFormikVal.creditCardInfo.billingStateProvince = ""
    // currentFormikVal.creditCardInfo.billingCountry = e.target.value
  };
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Choose Your Clients</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>
      {errMessage && (
        <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
          <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
            <img src={toAbsoluteUrl('/media/icons/duotune/general/gen040.svg')} alt='alert' />
          </span>

          <div className='d-flex flex-column'>
            <span>{errMessage}</span>
          </div>
        </div>
      )}
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex justify-content-between align-items-center mb-10'
              htmlFor='gla'
            >
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen006.svg'
                  className='svg-icon-3x me-5'
                />
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>GLA</span>
                  <span className='text-gray-400 fw-bold fs-6'>Experience GLA</span>
                </span>
              </div>
              <Field
                type='checkbox'
                className='form-check-input'
                name='clientName'
                value='experienceGla'
                id='gla'
              />
            </label>
          </div>

          <div className='col-lg-12'>
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex justify-content-between align-items-center'
              htmlFor='summerSpringBoard'
            >
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen006.svg'
                  className='svg-icon-3x me-5'
                />
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>Summer Spring Board</span>
                  <span className='text-gray-400 fw-bold fs-6'>Summer Spring Board</span>
                </span>
              </div>
              <Field
                type='checkbox'
                className='form-check-input'
                name='clientName'
                value='summerSpringBoard'
                id='summerSpringBoard'
              />
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='clientName' />
          </div>
        </div>
      </div>
      <br />
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark mb-10'>Client Details</h2>
        {/* begin::Form group Email */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
          <Field
            type='email'
            className='form-control form-control-lg form-control-solid'
            name='email'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='email' />
          </div>
        </div>
        {/* end::Form group */}
        {/* begin::Form group firstName */}
        <div className='row fv-row mb-7'>
          <div className='col-xl-4'>
            <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='firstName'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='firstName' />
            </div>
          </div>
          <div className='col-xl-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Middle</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='middleName'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='middleName' />
            </div>
          </div>
          <div className='col-xl-4'>
            {/* begin::Form group Lastname */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Last name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='lastName'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='lastName' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-xl-12'>
            {/* begin::Form group address */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Address 1</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='address1'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='address1' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-xl-12'>
            {/* begin::Form group address */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Address 2</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='address2'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='address2' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-xl-4'>
            {/* begin::Form group city */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>City</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='city'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='city' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-xl-4'>
            {/* begin::Form group state */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>State</label>
              <Field
                as='select'
                name='state'
                className='form-select form-select-lg form-select-solid'
              >
                <option>Select</option>
                {usStates.map((state, idx) => {
                  return (
                    <option value={state.abbreviation} key={idx}>
                      {state.name}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='state' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-xl-4'>
            {/* begin::Form group zipcode */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Zipcode</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='zipcode'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='zipcode' />
              </div>
            </div>
            {/* end::Form group */}
          </div>
        </div>
        {/* end::Form group */}
      </div>
    </div>
  )
}

export {Step2}
