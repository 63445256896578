import {useMemo} from 'react'
import {Field, ErrorMessage} from 'formik'
import Moment from 'react-moment'
import {
  IProduct,
  IProductDetail,
  IProgram,
  IProgramDate,
  IGeneralFee,
  IEnrollmentExtra,
  IAcademicTrack,
} from '../../core/_models'
import {currencyFormat} from '../../../../../utils/funcHelpers'
import {useQueryClient} from 'react-query'
import { scroller } from 'react-scroll'
import _ from 'lodash'

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  toScroll?: (name: string) => void
}

const Step2 = ({currentFormikVal, setFieldValue, toScroll}: Props) => {
  const queryClient = useQueryClient()
  const productgroup_list = queryClient.getQueryData<{
    programs: IProduct[]
    general_fees: IGeneralFee[]
  }>('programsEnroll')

  const selected_productgroup =
    productgroup_list &&
    productgroup_list.programs.filter((p, i) => i == currentFormikVal?.productgroup)[0]

  const selected_productgroup_details = selected_productgroup?.detail

  let selected_products: IProductDetail[] = []
  productgroup_list?.programs.forEach((pd) =>
    pd.detail.forEach((dt) => {
      if (currentFormikVal.product == dt.id) {
        selected_products.push(dt)
      }
    })
  )

  const checkValidDate = (e: any, currentProgramDate: IProductDetail) => {
    const currentSelectedStartDate = new Date(currentProgramDate.startDate)
    const currentSelectedEndDate = new Date(currentProgramDate.endDate)
    console.log('check valid', selected_products)

    let setVal: string[] = []
    //removeProgramDate(currentProgramDate.ref_id)
    let flag = false
    if (currentFormikVal?.programDate && currentFormikVal?.programDate?.length > 0) {
      if (currentFormikVal?.programDate.includes(currentProgramDate.id.toString())) {
        removeProgramDate(currentProgramDate.id.toString())
        removeAdditionalOpt(currentProgramDate.id.toString())
        return
      }

      //updateAdditionalOpt(currentProgramDate)

      setVal = [...currentFormikVal?.programDate]
      selected_products?.forEach((p, idx) => {
        if (flag) {
          return
        }
        const selectedStartDate = new Date(p.startDate)
        const selectedEndDate = new Date(p.endDate)

        if (
          (currentSelectedStartDate >= selectedStartDate &&
            currentSelectedStartDate <= selectedEndDate) ||
          (currentSelectedEndDate >= selectedStartDate && currentSelectedEndDate <= selectedEndDate)
        ) {
          flag = true
          return window.alert(
            'This trip overlaps another trip you selected.\nTo add this trip, please remove trips with overlapping dates first.'
          )
        }
      })
      //console.log('end here!', flag);
      if (!flag) {
        setVal.push(currentProgramDate.id.toString())
      }

      setFieldValue('programDate', setVal)
    } else {
      setVal.push(currentProgramDate.id.toString())
      setFieldValue('programDate', setVal)
      updateAdditionalOpt(currentProgramDate)
    }
  }

  const removeProgramDate = (id: string | number) => {
    console.log('removeProgramDate', id)
    const programDate = currentFormikVal.programDate

    const index = programDate?.indexOf(id.toString())
    if (!_.isUndefined(index) && index > -1) {
      // only splice array when item is found
      programDate?.splice(index, 1)
      setFieldValue('programDate', programDate)
    }
  }
  const updateAdditionalOpt = (currentProgramDate: IProductDetail) => {
    const requiredFees = currentProgramDate.extras?.filter((ex) => ex.is_required)
    const additionalOptVal: string[] = []
    requiredFees?.forEach((a) =>
      additionalOptVal.push(
        JSON.stringify({
          program_id: currentProgramDate.id.toString(),
          additional: a.id,
        })
      )
    )
    let setVal: string[] = []
    if (currentFormikVal?.additionalOpt && currentFormikVal?.additionalOpt?.length > 0) {
      setVal = [...currentFormikVal?.additionalOpt, ...additionalOptVal]
      setFieldValue('additionalOpt', setVal)
    } else {
      setFieldValue('additionalOpt', additionalOptVal)
    }
  }

  const removeAdditionalOpt = (id: string | number) => {
    console.log('removeAdditionalOpt', id)
    const additionalOpt = currentFormikVal.additionalOpt
    const afterRemove = additionalOpt?.filter((a) => {
      const obj = JSON.parse(a)
      return obj.program_id !== id.toString()
    })
    setFieldValue('additionalOpt', afterRemove)
  }
  let ssb_program_detail: IProductDetail = {
    id: 0,
    name: '',
    country: '',
    startDate: '',
    endDate: '',
    duration: 0,
    tuition: 0,
    deposit: 0,
    status: '',
    type: '',
    extras: [],
    group: '',
    program_track: [],
  }

  if (!_.isNil(currentFormikVal.ssb_product) && !!selected_productgroup_details) {
    ssb_program_detail = selected_productgroup_details.filter(
      (p, i) => p.id == currentFormikVal?.ssb_product
    )[0]
  }
  const currentRoute = window.location.pathname.split('/')[2] || ''
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15 text-center text-dark'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          {currentFormikVal?.student_type
            ? 'STEP 2: SELECT ACADEMIC TRACKS'
            : 'STEP 2: SELECT PROGRAM DATES'}
        </h2>
        {selected_productgroup?.name ? (
          <span className='fs-5 my-5'>
            {currentRoute == '88562775'
              ? 'Choose your session dates for'
              : 'Choose the product(s) for'}{' '}
            <i className='text-info'>
              <b>{selected_productgroup?.name} </b>
            </i>
          </span>
        ) : (
          ''
        )}
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold bg-light'>
                <th className='ps-5'>START DATE</th>
                <th>END DATE</th>
                {selected_productgroup_details &&
                selected_productgroup_details[0].division == 'SSB' ? (
                  <th>STUDENT TYPE</th>
                ) : (
                  ''
                )}
                <th>TUITION</th>
                <th>DEPOSIT</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='fs-5'>
              {selected_productgroup_details
                ?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))
                .map((p) => (
                  <>
                    <tr
                      className='form-check-label ms-2 fs-6 fw-bold'
                      key={p.id}
                      style={{
                        padding: '10px',
                        backgroundColor: '#e3e3e3',
                      }}
                    >
                      <td className='ps-5'>
                        <span className='text-dark d-block'>
                          {<Moment format='MMM DD, YYYY'>{p.startDate}</Moment>}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark d-block'>
                          {<Moment format='MMM DD, YYYY'>{p.endDate}</Moment>}
                        </span>
                      </td>
                      {p.division == 'SSB' ? (
                        <td>
                          <span className='text-dark d-block'>{p.type}</span>
                        </td>
                      ) : (
                        ''
                      )}
                      <td>
                        <span className='text-dark d-block'>
                          {currencyFormat.format(p.tuition)}{' '}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark d-block'>
                          {currencyFormat.format(p.deposit)}{' '}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark d-block'>{p.status}</span>
                      </td>
                      <td className='text-center'>
                        <Field
                          type='radio'
                          className='form-check-input me-2'
                          name='product'
                          value={p.id.toString()}
                          id={'product_' + p.id}
                          style={{border: '2px solid #019ef7'}}
                          // onChange={(e: any) => checkValidDate(e, p)}
                          onClick={() => {
                            scroller.scrollTo('enrollment-step2a', {
                              duration: 300,
                              smooth: true,
                              offset: -22
                            });
                            setFieldValue('academicTrack', '')
                            if (toScroll) {
                              toScroll('step2')
                            }
                          }}
                        />
                        <label className='form-check-label' htmlFor={'product_' + p.id}>
                          Select
                        </label>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <div className='text-danger text-center fs-5 my-5'>
        <ErrorMessage name='product' className='' />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default Step2
