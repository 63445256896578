import React, { Dispatch, SetStateAction } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const listStatus = [
  {
    value: 0,
    label: 'All'
  }, {
    value: 2,
    label: 'Incomplete - Deposit Due'
  }, {
    value: 3,
    label: 'Incomplete - Deposit Paid'
  }, {
    value: 4,
    label: 'Applied - Pending'
  }, {
    value: 5,
    label: 'Enrolled - Balance Due'
  }, {
    value: 6,
    label: 'Enrolled - Paid'
  },
]

const listClient = [
  {
    value: '',
    label: 'All'
  }, {
    value: '47821104',
    label: 'Summer Springboard'
  }, {
    value: '88562775',
    label: 'Teen Travel Network'
  },
]

interface IProps {
  setStatus: Dispatch<SetStateAction<number>>
  setClient: Dispatch<SetStateAction<string>>
  setSearch: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setDates: Dispatch<SetStateAction<{createAt: string, createTo: string}>>
  dates: {
    createAt: string
    createTo: string
  }
  search: string
}

interface CustomDatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const Filter = ({setStatus, setClient, setSearch, setPage, setDates, dates, search}: IProps) => {

  const formatDate = (date: Date) => {
    if (!date) return '' 
    const formattedDate = date?.toISOString();
    const dob = moment(formattedDate).utc(true);
    return dob.format('MM-DD-YYYY')
  }

  const changeDate = (value: any) => {
    const [start, end] = value;
    const startDate = formatDate(start)
    const endDate = formatDate(end)

    setDates({
      createAt: startDate,
      createTo: endDate
    })
  }

  const CustomDatePickerInput = ({ value, onClick }: CustomDatePickerInputProps) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      className='form-control'
      style={{
        width: '100%',
        height: '38px',
        borderRadius: '4px',
        borderColor: '#d2d2d2',
        background: 'transparent',
        outline: 'none',
      }}
      placeholder="Select range date"
      readOnly
    />
  );

  return (
    <div className='card-header border-0 py-6 d-flex justify-content-between '>
      <div className='fv-row me-5'>
        <label htmlFor="" className='fw-bold ms-1 mb-2 '>
          Search
        </label>
        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
            />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search a name'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setPage(1)
            }}
          />
        </div>
      </div>

      <div className='card-toolbar p-0 m-0'>
        <div className='fv-row me-5'>
          <label htmlFor="" className='fw-bold ms-1 mb-2 '>
            Dates
          </label>
          <DatePicker
            // className='form-control form-control-lg'
            selectsRange
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText='Select range date'
            startDate={dates.createAt ? new Date(dates.createAt) : null}
            endDate={dates.createTo ? new Date(dates.createTo) : null}
            isClearable
            clearButtonClassName='bg-transparent'
            onChange={changeDate}
            onChangeRaw={(e: any) => e.preventDefault()}
            customInput={<CustomDatePickerInput />}
          />
        </div>
        <div className='fv-row me-5 '>
          <label htmlFor="" className='fw-bold ms-1 mb-2 '>
            Division
          </label>
          <Select
            options={listClient}
            noOptionsMessage={() => 'Select a type first'}
            isClearable={false}
            isSearchable={false}
            className='w-200px'
            defaultValue={{
              value: '',
              label: 'All'
            }}
            onChange={(value) => {
              if (value === null) {
                return
              }
              setClient(value.value)
              setPage(1)
            }}
          />
        </div>
        <div className='fv-row'>
          <label htmlFor="" className='fw-bold ms-1 mb-2 '>
            Application Status
          </label>
          <Select
            options={listStatus}
            noOptionsMessage={() => 'Select a type first'}
            isClearable={false}
            isSearchable={false}
            className='w-250px'
            defaultValue={{
              value: 0,
              label: 'All'
            }}
            onChange={(value) => {
              if (value === null) {
                return
              }
              setStatus(value.value)
              setPage(1)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter