import {lazy} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {DashboardWrapper} from '../../pages/dashboard/DashboardWrapper'
import SuspensedView from '../components/SuspensedView'
import {ROLES} from '../core/_models'
import RequireAuth from '../RequireAuth'
import Waiver from '../../pages/dashboard/GLA/uploads/Waiver'
import OpportunitiesPage from '../../modules/apps/opportunities-management/OpportunitiesPage'
import ProductsPage from '../../modules/apps/products-management/ProductsPage'
import {PaymentWrapperGLA} from '../../pages/dashboard/GLA/ManagePayment'
import {StudentList} from '../../pages/dashboard/GLA/StudentList'
import PredepartureItemPage from '../../pages/dashboard/GLA/PredepartureItemPage'
import PaymentSchedulesPage from '../../modules/apps/payment-schedules-management/PaymentSchedulesPage'
import FlightPlan from '../../pages/dashboard/FlightPlan'
import ClientsPage from '../../modules/apps/clients-management/ClientsPage'
import Flights from '../../modules/apps/flight-management/Flight'
import EnrollmentGroup from '../../modules/apps/enrollment-group/EnrollmentGroup'
import ProgramSession from '../../modules/apps/program-session-management/ProgramSession'
import EmailTemplate from '../../modules/apps/email-template/EmailTemplate'
import UserProgress from '../../modules/apps/user-progress-management/UserProgress'
import EnrollmentForm from '../../modules/apps/enrollment-form/EnrollmentForm'
import EnrollmentExtras from '../../modules/apps/enrollment-extras/EnrollmentExtras'
import ReportMain from '../../modules/apps/reports/ReportMain'
import Discount from '../../modules/apps/discount-management/Index'
import CouponCodes from '../../modules/apps/coupon-code-management/CouponCode'
import WaitlistManagement from '../../modules/apps/waitlist-management/WaitlistManagement'

const index = () => {
  const AccountPage = lazy(() => import('../../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../../modules/apps/user-management/UsersPage'))
  const TestPage = lazy(() => import('../../modules/Terra/testterra'))
  const HelloSign = lazy(() => import('../../modules/apps/hello-sign/helloSign'))

  return (
    <Routes>
      <Route path='dashboard/:client' element={<DashboardWrapper />} />
      <Route element={<RequireAuth allowedRoles={[ROLES.student, ROLES.parent]} />}>
        <Route
          path='portal/:client/:predepartureName/:opportunity'
          element={
            <SuspensedView>
              <PredepartureItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='portal/:client/uploads/:predepartureName/:opportunity'
          element={
            <SuspensedView>
              <PredepartureItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='portal/:client/docusign/waiver/'
          element={
            <SuspensedView>
              <Waiver />
            </SuspensedView>
          }
        />
        <Route
          key={Math.random()}
          path='portal/:client/flight-plan/:productId'
          element={
            <SuspensedView>
              <FlightPlan />
            </SuspensedView>
          }
        />
      </Route>
      <Route
        path='accounts/:client/*'
        element={
          <SuspensedView>
            <AccountPage />
          </SuspensedView>
        }
      />
      <Route element={<RequireAuth allowedRoles={[ROLES.superAdmin, ROLES.admin]} />}>
        <Route
          path='apps/:client/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/waitist-management/*'
          element={
            <SuspensedView>
              <WaitlistManagement />
            </SuspensedView>
          }
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.parent]} />}>
        <Route
          path='portal/:client/payment_management/*'
          element={
            <SuspensedView>
              <PaymentWrapperGLA />
            </SuspensedView>
          }
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.parent]} />}>
        <Route
          path='portal/:client/student/list/*'
          element={
            <SuspensedView>
              <StudentList />
            </SuspensedView>
          }
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.superAdmin, ROLES.admin]} />}>
        <Route
          path='apps/:client/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/waitist-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/enrollment-group-management/*'
          element={
            <SuspensedView>
              <EnrollmentGroup />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/enrollment-form-management/*'
          element={
            <SuspensedView>
              <EnrollmentForm />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/enrollment-extras-management/*'
          element={
            <SuspensedView>
              <EnrollmentExtras />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/opportunities-management/*'
          element={
            <SuspensedView>
              <OpportunitiesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/products-management/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/payment-schedules-management/*'
          element={
            <SuspensedView>
              <PaymentSchedulesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/clients-management/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/flight-management/*'
          element={
            <SuspensedView>
              <Flights />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/program-session-management/*'
          element={
            <SuspensedView>
              <ProgramSession />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/user-progress-management/*'
          element={
            <SuspensedView>
              <UserProgress />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/email-template-management/*'
          element={
            <SuspensedView>
              <EmailTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/discount-management/*'
          element={
            <SuspensedView>
              <Discount />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:client/coupon-code-management/*'
          element={
            <SuspensedView>
              <CouponCodes />
            </SuspensedView>
          }
        />


        <Route
          path='hello-sign/:client/*'
          element={
            <SuspensedView>
              <HelloSign />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/:client/reports/*'
          element={
            <SuspensedView>
              <ReportMain />
            </SuspensedView>
          }
        />
      </Route>
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default index
