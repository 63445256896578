import React, { useState } from 'react'
import { useQuery } from 'react-query'
import AddUpdateOpportunity from './components/AddUpdateOpportunity'
import _queryKeys from './components/core/_queryKeys'
import { useOpportunityRequests } from './Hooks/useOpportunityRequests'

const AddOpportunityPage = () => {
  const [message, setMessage] = useState('')
    const {getOpportunityFieldsReq} = useOpportunityRequests()
  const {isLoading: isFieldLoading, isError: isFieldError, data} = useQuery(
    _queryKeys.getOpportunityFieldsReq,
    () => getOpportunityFieldsReq()
  )

  return (
    <>
      <AddUpdateOpportunity isLoading={false} setMessage={setMessage} />
    </>
  )
}

export default AddOpportunityPage