import React from 'react'
import Select from 'react-select'

export interface IOption {
  value: string
  label: string
}

interface IProps {
  loading: boolean
  fetchTemplate: any
  setFieldValue: any
}

const option = [
  // {
  //   value: 'test',
  //   label: 'Test template'
  // }, 
  // {
  //   value: 'welcomeEmailNoPass',
  //   label: 'Welcome Email Register'
  // }, 
  {
    value: 'welcomeEmailNoPassSSB',
    label: 'Welcome Email Register for SSB'
  }, 
  {
    value: 'welcomeEmailNoPassTTN',
    label: 'Welcome Email Register for TTN'
  },
  {
    value: 'welcomeEmailNoPassSSBWaitlist',
    label: 'Waitlist Email Register for SSB'
  }
]

const CustomSelect = ({loading, fetchTemplate, setFieldValue}: IProps) => {
  return (
    <>
      <div className='fv-row mb-7'>
        <label htmlFor="" className=''>
          <h4>Division</h4>
        </label>
        <Select
          isDisabled={loading}
          options={option}
          noOptionsMessage={() => 'Select a type first'}
          isClearable={true}
          isSearchable={false}
          className=''
          onChange={(val) => fetchTemplate(setFieldValue, val?.value)}
        />
      </div>
    </>
  )
}

export default CustomSelect