import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { axiosPrivate } from '../../../../axiosInstance'
import { ScrollTopComponent } from '../../../../_metronic/assets/ts/components';
import CustomSelect, { IOption } from './components/CustomSelect'
import CustomHtmlEditor from './components/CustomHtmlEditor';
import Notes from './components/Notes'

const initialValues = {
  filename: '',
  newHtmlTemplate: ''
}

// const resetPasswordSchema = Yup.object().shape({
//   filename: Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Password is required'),
//   newPassword: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
//   confirmPassword: Yup.string()
//     .required('Password confirmation is required')
//     .when('newPassword', {
//     is: (val: string) => (val && val.length > 0 ? true : false),
//     then: Yup.string().oneOf([Yup.ref('newPassword')], "New Password and Confirm Password didn't match"),
//   }),
// })

const EmailTemplateMain = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      await axiosPrivate.post('/update_template', { filename: values.filename, newHtmlTemplate: values.newHtmlTemplate })
      setSuccessMessage('Enrollment Form Group added successfully')
      setLoading(false)
      setTimeout(() => {
        setSuccessMessage('')
      }, 10000)
    } catch (error) {
      setErrorMessage('Error while updating email template')
      setLoading(false)
      setTimeout(() => {
        setErrorMessage('')
      }, 10000)
    } finally {
      ScrollTopComponent.goTop()
    }
  }

  const fetchTemplate = async (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, value: IOption) => {
    if (!value) {
      setFieldValue('newHtmlTemplate', '')
      setFieldValue('filename', '')
      return
    } 
    
    setLoading(true)
    setFieldValue('filename', value)
    
    try {
      const res = await axiosPrivate.post('/get_template', { filename: value })
      if (res.status === 200) {
        setFieldValue('newHtmlTemplate', res.data)
        setLoading(false)
      }
    } catch (error) {
      setErrorMessage('Error while updating email template')
      setLoading(false)
      setTimeout(() => {
        setErrorMessage('')
      }, 10000)
    }
  }

  return (
    <>
      {successMessage && (
          <div className='card-body'>
            <div className='alert alert-success d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>{successMessage}</span>
              </div>
            </div>
          </div>
        )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <div className="card card-body p-0" style={{minHeight: 300}}>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body'>
            
            <Formik
              initialValues={initialValues}
              // validationSchema={EnrollmentFormSchema}
              onSubmit={onSubmit}
            >
              {({values, setFieldValue}) => {

                return (
                  <Form>
                    <CustomSelect loading={loading} fetchTemplate={fetchTemplate} setFieldValue={setFieldValue} />
                    <CustomHtmlEditor values={values} setFieldValue={setFieldValue} loading={loading} />
                    <Notes />
                    
                    <div className='pt-5'>
                      <button
                        disabled={loading}
                        type='submit'
                        className='btn btn-primary cursor-pointer'
                        data-kt-users-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </div>
                  </Form>
                )
              }}

            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailTemplateMain