import React, { useEffect } from 'react'
import ConfirmPayBanner from './components/ConfirmPay/ConfirmPayBanner'
import EnrollPartTwoStep from './components/EnrollPartTwoStep'
import GLALayout from './components/GLALayout'

const EnrollmentPartTwo = () => {
  const url = window.location.href;
  let opportunity = url.split("/")[6];

  // if (!opportunity) opportunity = '-1'; // Why is this necessary?
  return (
    <GLALayout>
      <>
        <ConfirmPayBanner
          title='YOUR SPOT HAS BEEN SAVED'
          subheader='PLEASE COMPLETE THE REMAINDER OF YOUR APPLICATION NOW, OR RETURN SOON TO COMPLETE IT. '
          supportingHeader='YOUR SPOT IS SAVED PENDING ACCEPTANCE OF YOUR COMPLETED APPLICATION.'
        />

        <div className='container'>
          <EnrollPartTwoStep />
        </div>
      </>
    </GLALayout>
  )
}

export default EnrollmentPartTwo