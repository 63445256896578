import {useCallback, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import Step1 from './EnrollSteps/Step1'
// import Step1a from './EnrollSteps/Step1a'
import Step2 from './EnrollSteps/Step2'
import Step2a from './EnrollSteps/Step2a'
import Step2b from './EnrollSteps/Step2b'
import Step3 from './EnrollSteps/Step3'
import Step4 from './EnrollSteps/Step4'
import Step5 from './EnrollSteps/Step5'
import Step5a from './EnrollSteps/Step5a'
import Step6 from './EnrollSteps/Step6'
import {useQuery} from 'react-query'
import {Link as SLink} from 'react-scroll'
import {Formik, Form, FormikValues} from 'formik'
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom'
import {useMutation} from 'react-query'
import {glaEnrollSchemas, IProgram, initsProgram} from '../core/_models'
import {useAuth} from '../../auth'
import useAppRequests from '../Hooks/useAppRequests'
import useAuthRequests from '../../auth/hooks/useAuthRequests'
import {baseTextYup, emailYup, phoneRegExp} from '../../Shared/core/_models'
import useCurrentClientRoute from '../../auth/hooks/useCurrentClientRoute'
import _ from 'lodash'
import {keyify, scrollToFormikError} from '../../../../utils/funcHelpers'
import {COUNTRIES} from '../../../../utils'
import {getSavedProgress} from '../../../../network/api'
import { axiosInstance } from '../../../../axiosInstance'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EnrolleeSelection from './EnrolleeSelection'
const NewEnrollStep = () => {
  const {currentUser, saveAuth, setCurrentUser} = useAuth()
  const {currentClientRoute} = useCurrentClientRoute()
  const { getProgramsEnroll, setupProgramsEnroll, setupProgramsEnrollAuthReq, getListOfEnrollees } = useAppRequests();
  const {progress_id} = useParams()
  const [progressData, setProgressData] = useState<any>(null)
  const [xstatus, setXStatus] = useState<boolean>(false);
  const notify = async (values: string) => {
    toast.warn(values);
  };
  const checkCapacity = async (values: number) => {
    toast.dismiss();
    const response = await axiosInstance.get(`/getEnrollee/${values}`);
    //setXStatus(response.data.isLimit);
    // if(response.data.isLimit) {
    //   notify(`We are excited to have you join this summer!
    //       Unfortunately at this stage, our ${response.data.name} is fully enrolled,
    //       however you may continue your application in order to join our waitlist.
    //       No application fee / deposit will be charged. If a spot becomes available,
    //       we will contact you in order to confirm your continued interest prior to enrolling you.
    //       In addition, we suggest that you separately apply to your next choice for the upcoming summer,
    //       so as not to miss out on what will be an incredible experience.
    //       We look forward to seeing you this summer.`);
    // } 

  }
  const {data: programData} = useQuery('programsEnroll', () =>
    getProgramsEnroll(currentUser ? currentUser.currentClient : currentClientRoute)
  )

  const termsConditions = () => {
    if (currentClientRoute === '47821104') {
      return "You must acknowledge and agree to SSB's Terms & Conditions and Privacy Policy to reserve your spot." 
    }
    if (currentClientRoute === '88562775') {
      return "You must acknowledge and agree to TTN's Terms & Conditions and Privacy Policy to reserve your spot." 
    }
    return "You must acknowledge and agree to GLA's Privacy Policy to reserve your spot."
  }

  const step4Schemas = Yup.object().shape({
    studentInfo: Yup.object({
      firstName: baseTextYup.required('First name is required'),
      lastName: baseTextYup.required('Last name is required'),
      tshirtSize: Yup.string().label('T-Shirt').required('T-Shirt size is required'),
      schoolAttending: baseTextYup.required('Name of school attending is required'),
      nickname: baseTextYup,
      country: Yup.string().label('Country').required('Country is required'),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
      gender: Yup.string().label('Gender').required('Gender is required'),
      sexAssignedAtBirth: Yup.string()
        .label('Sex Assigned At Birth')
        .required('Sex Assigned At Birth is required'),
      pronouns: Yup.string().label('Pronouns').required('Pronouns is required'),
      birthday: Yup.date().label('Birthday').required('Birthday is required'),
    }),
    parentInfo: Yup.object({
      firstName: baseTextYup.required('First name is required'),
      lastName: baseTextYup.required('Last name is required'),
      email: emailYup.required('Email is required'),
      country: Yup.string().required('Country is required'),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    }),
    secondParentInfo: Yup.object({
      firstName: baseTextYup,
      lastName: baseTextYup,
      email: emailYup,
      country: Yup.string(),
      phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }).nullable(),
    studentAddress: Yup.object({
      address: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      stateProvince: Yup.string().required('Required'),
      postalCode: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
    }),
    parentAddress: Yup.object({
      address: Yup.string(),
      city: Yup.string(),
      stateProvince: Yup.string(),
      postalCode: Yup.string(),
      country: Yup.string(),
    }),
  })

  const step3Schemas = Yup.object().shape({
    email: emailYup.required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Password confirmation is required')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  //need to bypass when user is already login, so Formik will not run validation
  const newEnrollSchemas = Yup.object({
    product: Yup.number()
      .moreThan(0, 'You must select a valid program')
      .required('You must select a program with date'),
    // student_type:
    //   !_.isEmpty(programData?.programs)
    //     ? Yup.string().required('Please choose a Student Type')
    //     : Yup.string(),
    // programDate: Yup.array().of(Yup.string()).min(1, 'Please choose a program date'),
    // studentAccount: step3Schemas,
    // contactInfo: step4Schemas,
    acceptTerms: Yup.bool()
      .isTrue(termsConditions())
      .required(termsConditions()),
    academicTrack: currentClientRoute === '47821104' ? Yup.string().required('Please select enrollment course') 
    : Yup.string().notRequired()
  })

  const loggedInEnrollSchemas = Yup.object({
        product: Yup.number()
      .moreThan(0, 'You must select a valid program')
      .required('You must select a program with date'),
    // student_type:
    //   !_.isEmpty(programData?.programs)
    //     ? Yup.string().required('Please choose a Student Type')
    //     : Yup.string().nullable(),

    // programDate: Yup.array().of(Yup.string()).min(1, 'Please choose a program date'),
    acceptTerms: Yup.bool()
      .isTrue(termsConditions())
      .required(termsConditions()),
    academicTrack: currentClientRoute === '47821104' ? Yup.string().required('Please select enrollment course') 
    : Yup.string().notRequired()
  })

  const {getCurrentUser} = useAuthRequests()
  // const [currentSchema, setCurrentSchema] = useState(
  //   currentUser ? loggedInEnrollSchemas : newEnrollSchemas
  // )
  const [initValues] = useState<IProgram>(initsProgram)
  const navigate = useNavigate()

  const [errMessage, setErrMessage] = useState('')

  const {
    mutateAsync: sendProgram,
    isLoading,
    isError,
  } = useMutation(currentUser ? setupProgramsEnrollAuthReq : setupProgramsEnroll)

  useEffect(() => {
    return () => {
      setErrMessage('')
    }
  }, [])

  const [listOfEnrollees, setListOfEnrollees] = useState([]);
  const [selectedEnrollee , setSelectedEnrollee] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    client_id: '',
    sf_id: '',
    enrolleeAddress: {
      address: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: ''
    }
  });

  useEffect(() => {
    const fetchListOfEnrollees = async () => {
      const data = await getListOfEnrollees(currentUser ? currentUser.id : '')

      // console.log('- - - List of Enrollees - - -')
      // console.log(data)

      setListOfEnrollees(data)
    }

    if (currentUser?.role === "parent") {
      fetchListOfEnrollees();
    }
  }, []);

  const submitStep = async (values: IProgram, actions: FormikValues) => {
    const currentRoute = currentUser ? currentUser.currentClient : currentClientRoute
    values.client_id = currentRoute;

    let sendValue = values
    sendValue.isLimit = xstatus;
    if (COUNTRIES && sendValue.contactInfo) {
      for (let c of COUNTRIES) {
        if (c.code === sendValue.contactInfo?.studentInfo.country && !isError) {
          sendValue.contactInfo.studentInfo.phoneNumber =
            '(' + c.mobileCode + ') ' + sendValue.contactInfo.studentInfo.phoneNumber
        }
        if (c.code === sendValue.contactInfo?.parentInfo.country && !isError) {
          sendValue.contactInfo.parentInfo.phoneNumber =
            '(' + c.mobileCode + ') ' + sendValue.contactInfo.parentInfo.phoneNumber
        }
        if (sendValue.contactInfo?.secondParentInfo) {
          if (c.code === sendValue.contactInfo?.secondParentInfo.country && !isError) {
            sendValue.contactInfo.secondParentInfo.phoneNumber =
              '(' + c.mobileCode + ') ' + sendValue.contactInfo.secondParentInfo.phoneNumber
          }
        }
      }
    }

    if (currentUser) {
      if (currentUser.role === "parent") {
        if (sendValue.studentAccount) {
          sendValue.studentAccount.email = selectedEnrollee.email
        }

        if (sendValue.contactInfo) {
          // Student Info
          sendValue.contactInfo.studentInfo.firstName = selectedEnrollee.firstName;
          sendValue.contactInfo.studentInfo.lastName = selectedEnrollee.lastName;
          sendValue.contactInfo.studentInfo.email = selectedEnrollee.email;

          // Student Address
          sendValue.contactInfo.studentAddress.address = selectedEnrollee.enrolleeAddress.address;
          sendValue.contactInfo.studentAddress.city = selectedEnrollee.enrolleeAddress.city;
          sendValue.contactInfo.studentAddress.stateProvince = selectedEnrollee.enrolleeAddress.stateProvince;
          sendValue.contactInfo.studentAddress.postalCode = selectedEnrollee.enrolleeAddress.postalCode;
          sendValue.contactInfo.studentAddress.country = selectedEnrollee.enrolleeAddress.country;
        }
      }
    }

    // console.log('- - - Values to Send for Submitting All Steps - - -');
    // console.log(sendValue);

    try {
      const res = await sendProgram(sendValue)

      if (res.api_token) {
        saveAuth({api_token: res.api_token})
        const {currentUser} = await getCurrentUser()
        setCurrentUser(currentUser)
      }

      if (currentUser) {
        setCurrentUser({
          ...currentUser,
          currentOpportunity: res.opportunity,
        })
      }

      navigate(`/enroll/${currentRoute}/confirm-and-pay/${res.opportunity}`, {
        replace: true,
      })
    } catch (err: any) {
      setErrMessage(
        err?.response?.data?.message || 'Something went wrong while processing the request!'
      )
    }
  }

  const getSavedProgressHandler = async () => {
    try {
      const reponse = await getSavedProgress(
        currentUser ? currentUser.currentClient : currentClientRoute,
        progress_id
      )

      setProgressData(reponse?.progress)
    } catch (error) {
      console.log(error, 'errror')
    }
  }

  useEffect(() => {
    getSavedProgressHandler()
  }, [])

  const scrollToError = () => {
    setTimeout(function () {
      const textDangerElements = document.querySelectorAll('.text-danger')
      const nonEmptyTextDangerElements = Array.from(textDangerElements).filter(
        (element) => element.innerHTML.trim() !== ''
      )

      if (nonEmptyTextDangerElements.length > 0) {
        const firstNonEmptyTextDangerElement = nonEmptyTextDangerElements[0]
        // firstNonEmptyTextDangerElement.scrollIntoView();
        firstNonEmptyTextDangerElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
      }
    }, 20)
  }

  const initialValues = progressData !== null ? progressData : initValues
  initialValues.client_id = currentUser ? currentUser.currentClient : currentClientRoute
  const enableReinitialize = currentUser ? false : progressData !== null && Object.keys(progressData).length > 0

  // console.log('- - - Initial Values for Formik - - -');
  // console.log(initValues);

  // console.log('- - - Progress Data for Formik - - -');
  // console.log(progressData);

  return (
    <div className='d-flex flex-row-fluid flex-center'>
      <Formik
        validationSchema={currentUser ? loggedInEnrollSchemas : newEnrollSchemas}
        initialValues={initialValues}
        onSubmit={submitStep}
        enableReinitialize={enableReinitialize}
      >
        {({values, setFieldValue, errors }) => {

          return (
            <Form className='py-20 w-100 px-5' noValidate id='kt_create_account_form'>
              {currentUser
                && currentUser?.role === "parent"
                && <EnrolleeSelection
                  listOfEnrollees = {listOfEnrollees}
                  selectedEnrollee = {selectedEnrollee}
                  setSelectedEnrollee = {setSelectedEnrollee} />}
              <ToastContainer style={{ width: "90vw" }}
                position="top-center"
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <div id='enrollment-step1' className='py-10'>
                <Step1 currentFormikVal={values} setFieldValue={setFieldValue} />
              </div>
              <hr />
              {/* {!_.isEmpty(programData?.programs) 
              && !_.isEmpty(programData?.programs[0]?.detail) 
              && !_.isEmpty(programData?.programs[0]?.detail[0].program_track)
              // && (programData?.programs[0]?.detail[0]?.program_track.length > 0)
              && (
                <div id='enrollment-step1a' className='py-10'>
                  <Step1a currentFormikVal={values} setFieldValue={setFieldValue} />
                </div>
              )} */}
              <div id='enrollment-step2' className='py-10'>
                <Step2 currentFormikVal={values} setFieldValue={setFieldValue} />
              </div>
              <hr />
              {currentClientRoute === '47821104' ? (
                <>
                  <div id='enrollment-step2a' className='py-10'>
                    <Step2a currentFormikVal={values} setFieldValue={setFieldValue} checkCapacity={checkCapacity} errors={errors} isLimit={xstatus}/>
                  </div>
                  <div id='enrollment-step2b' className='py-10'>
                    <Step2b currentFormikVal={values} setFieldValue={setFieldValue} />
                  </div>
                </>
              ) : ''}
              <div id='enrollment-step3' className='py-10'>
                <Step3 currentFormikVal={values} setFieldValue={setFieldValue} />
              </div>
              <div id='enrollment-step4' className='py-10'>
                <Step4 currentFormikVal={values} setFieldValue={setFieldValue} />
              </div>
              <div id='enrollment-step5' className='py-10'>
                {!_.isEmpty(programData?.programs) &&
                !_.isEmpty(programData?.programs[0]?.detail) &&
                !_.isEmpty(programData?.programs[0]?.detail[0].program_track) ? (
                  <Step5a currentFormikVal={values} setFieldValue={setFieldValue} />
                ) : (
                  <Step5a currentFormikVal={values} setFieldValue={setFieldValue} />
                )}
              </div>
              {isError && (
                <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
                  <div className='d-flex flex-column'>
                    <h5 className='mb-1'>Oops!</h5>
                    <span>{errMessage}</span>
                  </div>
                </div>
              )}
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary me-3'
                  disabled={isLoading}
                  onClick={() => {
                    // scrollToFormikError(errors)
                    // submitForm()
                    scrollToError()
                  }}
                >
                  {!isLoading && (
                    <span className='indicator-label fs-2'>
                      Reserve Your Spot
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  )}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewEnrollStep
