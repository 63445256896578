import {KTSVG} from '../../../../../_metronic/helpers'

const Step3 = ({accountEmail}: {accountEmail: string;}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Your Are Done!</h2>
      </div>

      <div className='mb-0'>
        <div className='fs-4 text-gray-600 mb-5'>
          We sent a link to your email:
          <span className='fs-4 fw-bold text-primary'> {accountEmail}</span>
        </div>

        <div className=' d-flex bg-light-success rounded border-success border border-dashed p-6'>
          <KTSVG
            path='/media/icons/duotune/general/gen043.svg'
            className='svg-icon-2tx svg-icon-success me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
              <div className='fs-6 text-gray-600'>
                To start using our platform, please, please check your email and set your password!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
