import {useEffect, useMemo, useState} from 'react'
import DynamicTable from '../../../../_metronic/shared-components/dynamic-table'
import {useAuth} from '../../auth'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import { addOrEditCouponCode, fetchAllCouponCode, fetchAllEnrollmentExtras } from '../../../../network/api'
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination'
import Header from '../../../../_metronic/shared-components/Header'
import LoadingGIF from '../../../../assets/Logo/loading.gif'
import { ICouponCode } from './model'

const CouponCodeList = () => {

  // STATES
  const [coupon, setCoupon] = useState<ICouponCode[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleData, setVisibleData] = useState<ICouponCode[]>([])
  const [search, setSearch] = useState('')

  // HOOKS
  const navigate = useNavigate()
  const {client} = useParams()

  // Functions
    
  const EditCouponCode = (e: any, coupon_code_id: any) => {
    navigate(
      `/apps/${client}/coupon-code-management/edit-coupon-code-list/${coupon_code_id}`
    )
  }

  const handleOpenAction = (id: any) => {
    navigate(`/apps/${client}/coupon-code-management/coupon-code-list`)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setLoading(true)
    const getAllCouponCode = async () => {
      const response = await fetchAllCouponCode()
      setCoupon(response)
      setLoading(false)
    }
    getAllCouponCode()
  }, [])

  useEffect(() => {
    const filterCouponCode = search.length !== 0 
    ? coupon.filter(item => item.code_number?.toLowerCase().includes(search.toLowerCase())) 
    : coupon

    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    setVisibleData(filterCouponCode?.slice(startIndex, endIndex))
  }, [coupon, currentPage, search])

  const Filtered = Math.ceil(coupon.filter(item => item.code_number?.toLowerCase().includes(search.toLocaleLowerCase()))?.length / 10)

  const columns = useMemo(
    () => [
      {
        Header: 'Code Name',
        accessor: 'code_number',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({value}: any) => (
          <div
            style={{
              backgroundColor: value === 'active' ? '#e8fff3' : '#ffe8e8',
              color: value === 'active' ? '#50cd89' : '#cd5050',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              minWidth: value === 'active' ? 60 : 70,
              maxWidth: value === 'active' ? 60 : 70,
            }}
          >
            {value === 'active' ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: any) => (
          <>
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.value)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={(e) => {
                    EditCouponCode(e, props.value)
                  }}
                >
                  Edit
                </a>
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='card card-body p-0'>
        <Header
          buttonLink={`/apps/${client}/coupon-code-management/add-coupon-code`}
          buttonText='Add Coupon Code'
          placeholder='Search Coupon Code'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt='LOADING...'/> 
          </div>
        )}
      </div>
      {(!loading && visibleData.length !== 0) && (
        <CustomPagination
        currentPage={currentPage}
        totalPages={Filtered}
        onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default CouponCodeList