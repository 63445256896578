/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { useQuery } from 'react-query'
import {PageTitle} from '../../../../_metronic/layout/core'
import { useAuth } from '../../../modules/auth'
import NotSelectedProprams from '../../../modules/application/components/Dashboard/NotSelectedProprams'
import Predeparture from '../../../modules/application/components/Dashboard/Predeparture'
import ListPayment from '../../../modules/application/components/ListPayment'
import StudentListComponent from '../../../modules/application/components/StudentList'
import YourPrograms from '../../../modules/application/components/Dashboard/YourPrograms'
import _queryKeys from '../../../modules/application/core/_queryKeys'
import useAppRequests from '../../../modules/application/Hooks/useAppRequests'

const StudentListPage: FC = () => {
   const {currentUser} = useAuth()

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-lg-12'>
          <StudentListComponent />

        </div>
      </div>
    </>
  )}

const StudentList: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.STUDENTLIST'})}</PageTitle>
      <StudentListPage />
    </>
  )
}

export {StudentList}
