import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  opportunity: number
}

interface Plan {
  date: string,
  amount: string,
  isPaid: boolean,
  isRemoved?: boolean
}

const EditPlan = ({opportunity}: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [newPlan, setNewPlan] = useState<Plan[]>([])

  const fetchOpportunity = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.post('/fetch_opportunity_payment_plan', {opportunity})
      const parsePaymentPlan = JSON.parse(res.data.payment_plan)
      
      if (parsePaymentPlan.length) {
        setNewPlan(parsePaymentPlan[parsePaymentPlan.length - 1])
      }
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (showModal) {
      fetchOpportunity()
    }
  }, [showModal])

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
    setNewPlan([])
  }

  const onSubmit = async () => {
    setLoading(true)
    setErrorMessage('')
    setSuccessMessage('')
    try {
      const res = await axiosPrivate.post('update_payment_plan', {opportunity, newPlan})
      setSuccessMessage(res.data)
      setTimeout(() => {
        handleClose()
      }, 1000)
    } catch (error: any) {
      setErrorMessage(error.message)     
    } finally {
      setLoading(false)
    }
  }

  const handleOnChange = (index: number, value: any) => {
    setNewPlan(prev => prev.map((item, idx: number) => {
      if (idx === index) {
        return {
          ...item,
          amount: value || ''
        }
      } else {
        return item
      }
    }))
  }

  const checkHandleOnChange = (index: number, value: any) => {
    setNewPlan(prev => prev.map((item, idx: number) => {
      if (idx === index) {
        return {
          ...item,
          isRemoved: value || false
        }
      } else {
        return item
      }
    }))
  }
  return (
    <>
      <span className='text-primary' style={{cursor: 'pointer'}} onClick={handleClose}> - Edit</span>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Payment Plan</Modal.Title>
          </Modal.Header>

          {newPlan.map((item, index: number) => (
            <div className='fv-row my-7 px-7' style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                opacity: item.isPaid ? 0.4 : 1,
                pointerEvents: item.isPaid ? 'none' : 'auto'
              }}>
                {item.date}
                <input 
                  type="number"
                  value={Number(item.amount) || undefined} 
                  className='form-control' 
                  style={{width: 200, margin: '0 30px 0 30px'}} 
                  onChange={(e: any) => handleOnChange(index, e.target.value)} />
              </div>
              <input
                  type="checkbox"
                  checked={(item.isRemoved || false)}
                  className='form-check-input'
                  onChange={(e: any) => checkHandleOnChange(index, e.target.checked)} />
              <span className='text-success mx-7'>{item.isPaid ? 'Paid' : ''}</span>
            </div>
          ))}

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

        </Modal.Body>
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Update'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditPlan