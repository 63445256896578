import { useEffect, useMemo, useState } from "react"
import DynamicTable from "../../../../_metronic/shared-components/dynamic-table"
import { useAuth } from "../../auth"
import { getAllEnrollmentForm } from "../../../../network/api"
import CustomPagination from "../../../../_metronic/shared-components/CustomPagination"
import { KTSVG } from "../../../../_metronic/helpers"
import { useNavigate, useParams } from "react-router-dom"
import { IEnrollmentForm } from "./data"
import Header from "../../../../_metronic/shared-components/Header"
import LoadingGIF from '../../../../assets/Logo/loading.gif'

const EnrollmentFormList = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const params = useParams()
  const [enrollmentForm, setEnrollmentForm] = useState<IEnrollmentForm[]>([]) 
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleData, setVisibleData] = useState<IEnrollmentForm[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    const getAllEnrollmentGroup = async () => {
      const { data } = await getAllEnrollmentForm()
      data.sort((a: any, b: any) => b.id - a.id)
      setEnrollmentForm(data)
      setLoading(false)
    }
    getAllEnrollmentGroup()
  }, [])

  useEffect(() => {
    if (search.length > 0) {
      setCurrentPage(1)
    }

    const filterEnrollement = search.length !== 0 
    ? enrollmentForm.filter(item => {
      if (item.name?.toLowerCase().includes(search.toLowerCase())
      || item.label?.toLowerCase().includes(search.toLowerCase())
      || item.helper_text?.toLowerCase().includes(search.toLowerCase())) {
        return item
      }
      return ''
    }) 
    : enrollmentForm

    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    
    setVisibleData(filterEnrollement?.slice(startIndex, endIndex))
  }, [enrollmentForm, currentPage, search])
  
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const editEnrollmengGroup = (e: any, id: any) => {
    navigate(
      `/apps/${currentUser?.currentClient}/enrollment-form-management/edit-enrollment-form/${id}`
    )
  }

  const handleOpenAction = (id: any) => {
    console.log(id, 'id')
    navigate(`/apps/${params.client}/enrollment-form-management/enrollment-form`)
  }

  const filtered = Math.ceil(enrollmentForm
    .filter(item => item.name?.toLowerCase().includes(search.toLocaleLowerCase()))?.length / 10)

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (props: any) => {
        const type = props?.row?.original?.type
        return type || 'N/A'
      }
    },
    {
      Header: 'Field Type',
      accessor: 'field_type',
      Cell: (props: any) => {
        const field_type = props?.row?.original?.field_type
        return field_type || 'N/A'
      }
    },
    {
      Header: 'Required',
      accessor: 'required',
      Cell: (props: any) => {
        const required = props?.row?.original?.required
        if (required === null || false) {
          return 'false'
        }
        return 'true'
      }
    },
    {
      Header: 'Group',
      accessor: 'group',
      Cell: (props: any) => {
        const group = props?.row?.original?.group
        return group || 'N/A'
      }
    },
    {
      Header: 'Client ID',
      accessor: 'client_id',
      Cell: (props: any) => {
        const client_id = props?.row?.original?.client_id
        return client_id || 'N/A'
      }
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: (props: any) => (
        <>
          <a
            href='javascript:void(0)'
            onClick={() => {
              handleOpenAction(props.value)
            }}
            className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            id={`action_button_` + props.value}
          >
            Actions
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
          </a>
          {/* begin::Menu */}
          <div
            id={'subMenu_' + props.value}
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                onClick={(e) => {
                  editEnrollmengGroup(e, props.value)
                }}
              >
                Edit
              </a>
            </div>
          </div>
        </>
      ),
    },
  ], [])

  return (
    <>
      <div className="card card-body p-0">
        <Header
          buttonLink={`/apps/${currentUser?.currentClient}/enrollment-form-management/add-enrollment-form`}
          buttonText='Add Enrollment Form'
          placeholder='Search enrollment form'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt="" />
          </div>
        )}
      </div>
      {(!loading && visibleData.length !== 0) && (
        <CustomPagination
          currentPage={currentPage}
          totalPages={filtered}
          onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default EnrollmentFormList