import {Formik} from 'formik'
import {Field, ErrorMessage} from 'formik'
import {
  glaPaymentSchemas,
  initsPayOpt,
  IPayOpt,
  IEnrollmentObject,
  // IPaymentSchedule,
  IOpportunityExtra,
  IPaymentMethod,
  ISFProduct,
} from '../../core/_models'
import PaybyCreditCard from './PayOpt/PaybyCreditCard'
import PayByBankAccount from './PayOpt/PayByBankAccount'
import PaymentSchedule from './PayOpt/PaymentSchedule'
import PaymentTerms from './PaymentTerms'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {useAuth} from '../../../auth'
import _queryKeys from '../../core/_queryKeys'
import {currencyFormat, parse_payment} from '../../../../../utils/funcHelpers'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ErrorMessages from '../../../Shared/components/ErrorMessages'

const glaFee = 95

const PayOpt = ({inputObject, isLimit}: any) => {
  const {client} = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {setupProgramsEnrollPayment} = useAppRequests()
  const {
    isLoading,
    isError: isSendPaymentError,
    mutate: sendPayment,
  } = useMutation(setupProgramsEnrollPayment)
  const {currentUser} = useAuth()
  const [sendPaymentError, setSendPaymentError] = useState<any>(null)
  const [tabClicked, setTabClicked] = useState(true)

  const enrollmentSummary = queryClient.getQueryData<{
    opportunity: number,
    opportunity_sf_id: string,
    enrollment_objects: IEnrollmentObject[]
    // schedules: IPaymentSchedule[]
    payment_methods: IPaymentMethod[]
    extras: IOpportunityExtra[]
    sync_product_code: ISFProduct[]
    scholarship_list: any[],
    protection_plan: any[],
    loginuser_id: number
  }>(_queryKeys.getRecentPaymentSummariesReq)

  const product_id = enrollmentSummary?.protection_plan ? enrollmentSummary?.protection_plan[0]?.id : null;
  const product_name = enrollmentSummary?.protection_plan ? enrollmentSummary?.protection_plan[0]?.name : null;
  const product_sf_id = enrollmentSummary?.protection_plan ? enrollmentSummary?.protection_plan[0]?.sf_id : null;
  const product_pricebook_entry = enrollmentSummary?.protection_plan ? enrollmentSummary?.protection_plan[0]?.pricebook_entry : null;

  let totalDue =
  enrollmentSummary && enrollmentSummary?.enrollment_objects?.reduce((accumulator, obj) => {
      return accumulator + obj.tuition
    }, 0) || 0
  let totalDuenow =
  enrollmentSummary &&
  enrollmentSummary?.enrollment_objects?.reduce((accumulator, obj) => {
      return accumulator + obj.due_upon_application
    }, 0) || 0
  totalDuenow = !inputObject.isChecked ? totalDuenow : totalDuenow + inputObject.protection_price
  let comment = 'Product(s): $' + totalDuenow +';\n';
  if (enrollmentSummary && enrollmentSummary.extras && enrollmentSummary.extras.length > 0) {
    enrollmentSummary.extras.forEach(ext => {
      if (ext?.program_product_extra?.enrollment_extra.category === 8) {
        totalDuenow += ext.program_product_extra.enrollment_extra.amount
      }
      totalDue += ext.program_product_extra.enrollment_extra.amount
      comment += 'Extra: ' + ext.program_product_extra.enrollment_extra.name + ', amount: $' + ext.program_product_extra.enrollment_extra.amount + '\n'
    })
  } else {
    totalDuenow += glaFee
    totalDue += glaFee
  }

  if (enrollmentSummary && enrollmentSummary?.scholarship_list) {
    for (let item of enrollmentSummary.scholarship_list) {
      if (item?.type === 'Discount') {
        totalDuenow += item.due_upon_application
      }
    }
  }

  const onSubmitPayment = (values: IPayOpt): any => {
    values.opportunity = enrollmentSummary?.opportunity
    values.totalAmount = totalDue || 0
    values.amount = totalDuenow || 0
    values.comment = comment
    values.program_protection_plan = inputObject.isChecked
    values.protection_price = inputObject.protection_price
    values.protection_product_id = product_id
    values.protection_product_name = product_name
    values.protection_product_sf_id = product_sf_id
    values.protection_product_pricebook = product_pricebook_entry
    values.login_id = enrollmentSummary?.loginuser_id
    values.opportunity_sf_id = enrollmentSummary?.opportunity_sf_id
    if (client === '88562775') {
      values.firstPay = true
    }
    sendPayment(values, {
      onSuccess: () => {
        return navigate(`/enroll/${currentUser?.currentClient}/enroll-part-two/${enrollmentSummary?.opportunity}`, {replace: true})
      },
      onError: (err:any) => {
        const message = err.response?.data.message
        // if (_.isArray(message) && message.length > 0) {
        //   setSendPaymentError(message)
        // } else {
        //   setSendPaymentError([message ? message : 'Something went wrong!'])
        // }
        setSendPaymentError(message)
      },
    })
  }
  
  const setPaymentMethod = (e: any, setFieldValue: any): any => {
    e.currentTarget.id === 'creditCard'
      ? setFieldValue('paymentMethod', 'creditCard')
      : e.currentTarget.id === 'bankAccount'
      ? setFieldValue('paymentMethod', 'bankAccount')
      : setFieldValue('paymentMethod', 'sponsored')
  }

  const setAmount = (e: any, setFieldValue: any): any => {
    e.currentTarget.id === 'deposit'
      ? setFieldValue('amount', totalDuenow)
      : e.currentTarget.id === 'fullamount'
      ? setFieldValue('amount', totalDue)
      : setFieldValue('amount', 0)
  }

  const setScheduleOptions = (event: any) => {
    if (!!event) {
      setTabClicked(true)
    } else {
      setTabClicked(false)
    }
  }

  const pMethodSaved = (!enrollmentSummary) ? null : enrollmentSummary?.payment_methods?.filter((pm) => pm.isActive)
  const pMethodSavedCredit = (!enrollmentSummary) ? null : enrollmentSummary?.payment_methods?.filter((pm) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!enrollmentSummary) ? null : enrollmentSummary?.payment_methods?.filter((pm) => pm.paymentType === 'bankAccount')
  let ccCount = 0
  let ACHCount = 0
  if(pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if(pMethodSavedACH) ACHCount = pMethodSavedACH.length
  // const pMethodSavedText = pMethodSaved ? parse_payment(pMethodSaved[0]) : null

  // set pMethodSaved as default payment
  initsPayOpt.paymentMethod =  pMethodSaved && pMethodSaved.length > 1
    ? `funding_${pMethodSaved[0]?.paymentType}_${pMethodSaved[0]?.id}`
    : initsPayOpt.paymentMethod || "bankAccount"
  console.log(pMethodSaved, "Yoshi Logs 2")
  return (
    <div className='mt-10'>
      <Formik
        validationSchema={glaPaymentSchemas}
        initialValues={initsPayOpt}
        onSubmit={onSubmitPayment}
      >
        {({values, setFieldValue, errors, setErrors, handleSubmit}) => {
          console.log(values, "yoshi Logs")
          return (
            <form onSubmit={handleSubmit}>
              <div className='fv-row row'>
                <h2>Payment method</h2>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs'>
                  <li className='nav-item col-lg-4'>
                    <a
                      id='bankAccount'
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#pmt_ach'
                      onClick={(e) => setPaymentMethod(e, setFieldValue)}
                    >
                      BANK ACCOUNT (ACH PAYMENT)
                    </a>
                  </li>
                  <li className='nav-item col-lg-4'>
                    <a
                      id='creditCard'
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#pmt_credit_card'
                      onClick={(e) => setPaymentMethod(e, setFieldValue)}
                    >
                      CREDIT CARD
                    </a>
                  </li>
                  <li className='nav-item col-lg-4'>
                    <a
                      id='sponsored'
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#pmt_sponsor_code'
                      onClick={(e) => setPaymentMethod(e, setFieldValue)}
                    >
                      SPONSORING ORGANIZATION
                    </a>
                  </li>
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade show active' id='pmt_ach' role='tabpanel'>
                    {pMethodSaved && pMethodSaved.map((data: any) => {return data.paymentType  === 'bankAccount'}) && ACHCount > 0 ? 
                      (<>
                          <div className='d-flex flex-column my-5'>
                            {pMethodSavedACH?.map((data: any) => {
                              const dataSaveText = data ? parse_payment(data) : null
                              return (
                                  <div className='mb-7 me-5'>
                                  <Field
                                    type='radio'
                                    className='form-check-input me-2'
                                    value={`funding_${data.paymentType}_${data?.id}`}
                                    name={'selectPMethod'}
                                    id={`funding_${data.paymentType}_${data?.id}`}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                  >
                                    <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                    Ending in {data?.cardNumber?.replaceAll('x', '')}
                                  </label>
                                </div>
                              )
                            })}
                            <div className='mb-7 me-5'>
                              <Field
                                type='radio'
                                className='form-check-input me-2'
                                value={'bankAccount'}
                                name={'selectPMethod'}
                                id={'bankAccount_selectPMethod'}
                              />
                              <label className='form-check-label' htmlFor={'bankAccount_selectPMethod'}>
                                Use a new payment method
                              </label>
                            </div>
                          </div>
                          {values.selectPMethod === 'bankAccount' && <PayByBankAccount />}
                        </>
                        ) : (
                    <PayByBankAccount />
                    )}
                  </div>
                  <div className='tab-pane fade' id='pmt_credit_card' role='tabpanel'>
                    {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === 'creditCard'})  && ccCount > 0 ? (
                      <>
                        <div className='d-flex flex-column my-5'>
                        {pMethodSavedCredit?.map((data: any) => {
                              const dataSaveText = data ? parse_payment(data) : null
                              return (
                                  <div className='mb-7 me-5'>
                                  <Field
                                    type='radio'
                                    className='form-check-input me-2'
                                    value={`funding_${data.paymentType}_${data?.id}`}
                                    name={'selectPMethod'}
                                    id={`funding_${data.paymentType}_${data?.id}`}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                  >
                                    <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                    Ending in {data?.cardNumber?.replaceAll('x', '')}
                                  </label>
                                </div>
                              )
                            })}

                          <div className='mb-7 me-5'>
                            <Field
                              type='radio'
                              className='form-check-input me-2'
                              value={'creditCard'}
                              name={'selectPMethod'}
                              id={'creditCard_selectPMethod'}
                            />
                            <label className='form-check-label' htmlFor={'creditCard'}>
                              Use a new payment method
                            </label>
                          </div>
                        </div>
                        {values.selectPMethod === 'creditCard' && <PaybyCreditCard  currentFormikVal={values}/>}
                      </>
                    ) : (
                    <PaybyCreditCard  currentFormikVal={values}/>
                   )}
                  </div>
                  <div className='tab-pane fade' id='pmt_sponsor_code' role='tabpanel'>
                    <div className='text-danger mt-2'>
                      <div className='col-lg-6 d-flex my-7'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            SPONSOR
                          </span>
                        </div>
                        <Field
                          type='string'
                          className='form-control form-control-lg form-control-solid me-5'
                          name='sponsoredInfo.sponsorCode'
                        />
                      </div>
                      <ErrorMessage name='sponsoredInfo.sponsorCode' />
                    </div>
                  </div>
                </div>

                {(client === '47821104' && values.paymentMethod !== 'sponsored' && !isLimit) ? (
                  <>
                    <h2>Payment amount</h2>
                    <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs'>
                      <li className='nav-item col-lg-4' onClick={() => setTabClicked(true)}>
                        <a
                          id='paymentSchedule'
                          className='nav-link active'
                          data-bs-toggle='tab'
                          href='#amt_schedule'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          PAYMENT SCHEDULE
                        </a>
                      </li>
                      {/* <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(true)}>
                        <a
                          id='customAmount'
                          className='nav-link'
                          data-bs-toggle='tab'
                          href='#amt_custom'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          PAY OTHER AMOUNT
                        </a>
                      </li>
                      <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(false)}>
                        <a
                          id='fullamount'
                          className='nav-link'
                          data-bs-toggle='tab'
                          href='#amt_full'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          PAY IN FULL
                        </a>
                      </li> */}
                    </ul>
                    
                    {/* <div className='tab-content' id='myTabContent'>
                      <div className='tab-pane fade' id='amt_deposit' role='tabpanel'></div>
                      <div className='tab-pane fade' id='amt_custom' role='tabpanel'>
                        <div className='col-lg-4 col-md-4 d-flex flex-column m-auto'>
                          <p>Other amount</p>
                          <Field
                            type='number'
                            className='form-control form-control-solid'
                            placeholder=''
                            name='amount'
                          />
                          <p>
                            Enter an amount between the deposit (${totalDuenow}) and the full amount ($
                            {totalDue}))
                          </p>
                        </div>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='amount' />
                        </div>
                      </div>
                      <div className='tab-pane fade' id='amt_full' role='tabpanel'></div>
                    </div> */}
                    <PaymentSchedule required={true} />
                  </>
                ) : ''}

                <div className='text-danger mt-2'>
                    <span>{errors.paymentMethod}</span>
                  <ErrorMessage name='paymentMethod' />
                </div>
                {!isLimit ? <><PaymentTerms />
                <div className='row bg-success py-10'>
                  <div className='col-lg-6'>
                    <Field
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Parent: Write your name here *'
                      name='parentSignatureTerms'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='parentSignatureTerms' />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    By writing your name here, you are validating that you have read and agree to
                    the above Terms & Conditions.
                  </div>
                </div>
                </> : ""}
                {/* {isSendPaymentError && (
                  <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
                    <div className='d-flex flex-column'>
                      {sendPaymentError?.map((err: any) => (
                        <span>{err}</span>
                      ))}
                    </div>
                  </div>
                )} */}
                <ErrorMessages isError={isSendPaymentError} messages={sendPaymentError} />
                {!isLimit ? <div className='d-flex justify-content-end my-10'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2 me-5'>Reserve your spot!</span>
                    <button type='submit' className='btn btn-lg btn-primary me-3' disabled={isLoading}>
                      {!isLoading && (
                        <span className='indicator-label'>
                          Next Step
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      )}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div> : <div className='d-flex justify-content-end my-10'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2 me-5'>Reserve your spot!</span>
                    <button type='submit' className='btn btn-lg btn-primary me-3' disabled={isLoading}>
                      {!isLoading && (
                        <span className='indicator-label'>
                          Place me on waitlist
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      )}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>}
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PayOpt
