import React, { useEffect, useState } from 'react'
import CustomForm, { IInitialValue } from './components/CustomForm'
import { useParams } from 'react-router-dom'
import { axiosPrivate } from '../../../../axiosInstance'
import LoadingGIF from '../../../../assets/Logo/loading.gif'
import { ScrollTopComponent } from '../../../../_metronic/assets/ts/components'

const initValues = {
  name: '',
  type: '',
  due: 0,
  amount: 0,
  qb_name: '',
  coa: '',
  division: '',
  is_active: true,
  scholarship_code: '',
  sf_id: '',
  pb_id: '',
}


const DiscountEdit = () => {
  const { discount_id } = useParams()
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [initialValues, setInitialValues] = useState(initValues)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const res = await axiosPrivate.get(`get_discounts_by_id/${discount_id}`)
        setInitialValues(res.data)
      } catch (error: any) {
        setErrorMessage(error?.message)
      } finally {
        setLoading(false)
        setTimeout(() => {
          setErrorMessage('')
        }, 8000)
      }
    }
    fetchData()
  }, [discount_id])

  const onSubmit = async (inputs: IInitialValue) => {
    try {
      const payload = {
        ...inputs,
        due: inputs.due || null
      }
      const res = await axiosPrivate.post(`edit_discount/${discount_id}`, payload)
      setSuccessMessage(res.data)
    } catch (error: any) {
      console.log(error, 'qweqweqwe')
      setErrorMessage(error?.message)
    } finally {
      setTimeout(() => {
        setSuccessMessage('')
        setErrorMessage('')
      }, 5000)
      ScrollTopComponent.goTop()
    }
  }

  if (loading) {
    return (
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card'>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={LoadingGIF} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {successMessage && (
          <div className='card-body'>
            <div className='alert alert-success d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>{successMessage}</span>
              </div>
            </div>
          </div>
        )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <CustomForm 
        initialValues={initialValues}
        // EnrollmentFormSchema={EnrollmentFormSchema}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default DiscountEdit