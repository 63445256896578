import DatePicker from 'react-datepicker'
import {useField} from 'formik'
import moment from 'moment'

const DatePickerField = ({dateFormat, ...props}: any) => {
  const [field, , {setValue}] = useField(props)
  return (
    <DatePicker
      {...field}
      {...props}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setValue(moment(val).format(dateFormat ? dateFormat :'MMM DD, YYYY'))
      }}
      placeholderText='Select a date'
      onChangeRaw={(e) => e.preventDefault()}
    />
  )
}

export default DatePickerField
