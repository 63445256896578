import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ICouponCode } from './model'
import { addOrEditCouponCode, fetchCouponCodeById } from '../../../../network/api'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import { Form, Formik } from 'formik'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import { CouponCodeSchema } from '../coupon-code-management/Schemas/CouponCodeSchema'
import { codeMessage } from '../../../../network/codeMessage'
import { useAuth } from '../../../../app/modules/auth'

const CouponCodeEdit = () => {

  const [formData, setFormData] = useState({} as ICouponCode)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { coupon_code_id, client } = useParams()
  const navigate = useNavigate()

  const initialValues = {
    code_number: formData?.code_number,
    status: formData?.status === 'active' ? true : false,
  }

  useEffect(() => {
    setLoading(true)

    const fetchFormData = async () => {
      const data = await fetchCouponCodeById(coupon_code_id)
      setLoading(false)
      setFormData(data)
    }

    fetchFormData()
  }, [])

  const onSubmit = async (values: ICouponCode) => {
    try {
      const modifiedValues = {
        code_number: values.code_number,
        status: values.status ? 'active' : 'inactive',
      }
      const res = await addOrEditCouponCode(modifiedValues, coupon_code_id ? coupon_code_id : null);
      const Success = codeMessage[res.status]
      setSuccessMessage(Success)

      setTimeout(() => { 
        navigate(`/apps/${client}/coupon-code-management/coupon-code-list`)
      }, 3000)
    } catch (error: any) {
      const errorMsg = codeMessage[error.response.status]
      setErrorMessage(errorMsg)

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  return (
    <>
    {successMessage && (
      <div className='card-body'>
        <div className='alert alert-success d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{successMessage}</span>
          </div>
        </div>
      </div>
    )}

    {errorMessage && (
      <div className='card-body'>
        <div className='alert alert-danger d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
    )}

    {loading ? (
    <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
      <span style={{height: '40px', width: '40px'}} className='spinner-border spinner-border-sm align-middle mt-20' /> 
    </span>
    ) : (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            validationSchema={CouponCodeSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({isSubmitting}) => {
              return (
                <Form>

                <CustomInput label='Code Name' name='code_number' placeholder='Enter a Valid Code' />

                <CustomCheckbox label='Status' type='checkbox' name='status' />

                <div className='text-center pt-15'>
                  <button
                    disabled={isSubmitting}
                    type='submit'
                    className='btn btn-primary cursor-pointer'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Update</span>
                  </button>
                </div>
              </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
    )}
  </>
  )
}

export default CouponCodeEdit