import React from 'react'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, useAuth } from '../../../../modules/auth'
import { axiosPrivate } from '../../../../../axiosInstance'
import { useParams } from 'react-router-dom'
import useAppRequests from "../../../../modules/application/Hooks/useAppRequests";
import { IPredepartureItem } from "../../../../modules/application/core/_models"
import moment from "moment";

interface Props {
  predeparture?: IPredepartureItem
}

const Waiver = ({predeparture}: Props) => {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const [waiverDocumentData, setWaiverDocumentData] = useState<any>([])
  const [waiverDocumentDataErr, setWaiverDocumentDataErr] = useState("")
  const { client, opportunity } = useParams()

  const [disable, setDisable] = useState(false)
  const [parentSignedOn, setParentSignedOn] = useState(0);
  const [studentSignedOn, setStudentSignedOn] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [studentStatus, setStudentStatus] = useState('not yet signed')
  const [parentStatus, setParentStatus] = useState('not yet signed')

  const { fetchHelloSignRequestByIDReq, updatePredepartureTaggingReq } = useAppRequests();

  const fetchHelloSignRequestByID = async () => {
    const helloSignRequest = await fetchHelloSignRequestByIDReq(opportunity, predeparture?.id)

    if (helloSignRequest) {
      const student = helloSignRequest.signatures.find((signature: any) => signature.signer_name === "student");
      const parent = helloSignRequest.signatures.find((signature: any) => signature.signer_name === "parent");

      if (student) {
        setStudentSignedOn(student?.signed_at)
        setStudentStatus(student?.status_code)
      }
      
      if (parent) {
        setParentSignedOn(parent?.signed_at)
        setParentStatus(parent?.status_code)
      }
    }
  }
  
  const DateFormatterUnix = (unixcode: number) => {
    if (unixcode === 0 || unixcode === null) {
      return 'N / A'
    }
    return moment.unix(unixcode).format('LLL')
  }
  
  const getHelloSignDocuments = async () => {
    try {
      const response = await axiosPrivate.get(`/hellosign/documents/${opportunity}/${predeparture?.id}`)
      setWaiverDocumentData(response.data.document_list);
    } catch (error) {
      console.error(error)
    }
  }

  const isUserAnAdmin = async () => {
    const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)

    if (prevAuth) {
      setIsAdmin(true)
    }
  }

  useEffect(() => {
    if (predeparture) {
      isUserAnAdmin()
      fetchHelloSignRequestByID();
    }
  }, [predeparture]
  )

  useEffect(() => {
    if (predeparture) {
      getHelloSignDocuments();
    }
  }, [predeparture, isAdmin])

  const changeDocumentStatus = (event: any) => {
    setSelectedStatus(event.target.value);
  }

  const saveDocumentStatus = async (event: any) => {
    event.preventDefault();

    const formData = {
      opportunity_id: Number(opportunity),
      predeparture_id: predeparture?.id,
      predeparture_task_name: predeparture?.name,
      status_name: selectedStatus
    }

    const response = await updatePredepartureTaggingReq(formData);

    if (response) {
      setStatusMessage(response.message);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSelectedStatus("");
      setStatusMessage("");
    }, 5000);
  }, [statusMessage])

  const handleRequestSignature = async (programs: any) => {
    setDisable(true)
    await handleCallRequest(programs.program_id, programs.id, programs.program_document_id, )
  }

  const handleCallRequest = async (programID: number, documentID: number, programDocumentID: number, ) =>{
    const dataToSend = {
      program_id: programID,
      document_id: documentID,
      program_document_id: programDocumentID,
      opportunity_id: Number(opportunity),
    }

    try {
      await axiosPrivate.post('/hellosign/request', dataToSend)
    } catch (error: any) {
      setDisable(false)
      alert(error.response.data.message)
    }
  }

  const capitalFirstLetter = (string: string) => {
    return string.toLowerCase().replace(
      /(^|\s)\S/g,
      (match) => match.toUpperCase())
  }

  const renderSpanOrButton = studentStatus === "awaiting_signature" ||
  studentStatus === "signed"
      ? <span className="text-muted">No further action</span>
      : <button
          className="btn btn-primary"
          disabled={disable ? true : false}
          onClick={()=>{handleRequestSignature(waiverDocumentData)}}
        >Request Signature</button>

  const statusClassName = studentStatus === "signed" 
  ? "text-success"
  : studentStatus === "awaiting_signature"
      ? "text-primary"
      : "text-dark"

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card-header px-0 mb-10'>
          <div>
            <h1>Complete The Waiver Signature</h1>
            <span>Sign your {client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'} waiver here.</span>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='bg-light text-center text-muted fw-bold'>
                  <th className='ps-4 rounded-start'>Name</th>
                  <th>Student Signature Status</th>
                  <th>Signed by Student On</th>
                  <th>Parent Signature Status</th>
                  <th>Signed by Parent On</th>
                  <th className='rounded-end'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              <tbody className='fs-5'>
                <tr className="text-center">
                  <td>
                    <span className='text-dark d-block'>{waiverDocumentData?.template_title}</span>
                  </td>
                  <td className={statusClassName}>
                    <span>{capitalFirstLetter(studentStatus === 'awaiting_signature' ? 'awaiting signature' : studentStatus)}</span>
                  </td>

                  <td>
                    <span className="text-muted">
                      {DateFormatterUnix(studentSignedOn)}
                    </span>
                  </td>

                  <td className={statusClassName}>
                    <span>{capitalFirstLetter(parentStatus === 'awaiting_signature' ? 'awaiting signature' : parentStatus)}</span>
                  </td>

                  <td>
                    <span className="text-muted">
                      {DateFormatterUnix(parentSignedOn)}
                    </span>
                  </td>

                  <td>
                    <span className="text-dark d-block">
                      {renderSpanOrButton}
                    </span>
                  </td>  
                </tr>
              </tbody>
            </table>
           
            {isAdmin &&
              <section className="mt-12">
                <hr />

                <form
                  className="d-flex flex-column my-3"
                  onSubmit={(event: any) => saveDocumentStatus(event)}>
                  <h3 className="ps-4">Change Document Status:</h3>

                  <select
                    className="form-select border border-primary w-50 mt-3 mb-5 py-2"
                    value={selectedStatus}
                    onChange={changeDocumentStatus}>
                    <option value="">Choose New Status</option>
                    <option value="Incomplete">Incomplete</option>
                    <option value="Confirmed">Confirmed</option>
                  </select>

                  <button type="submit" className="btn btn-primary w-50">Save</button>
                </form>

                {statusMessage &&
                  <span
                    className={statusMessage === "Predeparture Status successfully updated!"
                      ? "w-50 text-center text-success"
                      : "w-50 text-center text-danger"}>
                    {statusMessage}
                  </span>}
              </section>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Waiver