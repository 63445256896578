import { IEnrollmentSummary } from "./core/_models"

type Props = {
  setIsChecked: (boolean: boolean) => void
  data?: IEnrollmentSummary
  isChecked?: boolean
  isLoading?: boolean
}

const PaymentProtectionPlan = ({data, setIsChecked, isLoading, isChecked} : Props) => {
  return (
    <>
    {!isLoading ? <div>
      <div className='d-flex justify-content-center rounded bg-primary w-75 mx-auto col-md-12'>
        <div className='bg-opacity-50 w-xl-700px  text-center py-5 text-light border-radius-20'>
        <h1 className='fw-bolder fs-2x text-light text-uppercase'>Tuition Protection Plan</h1>
      </div>
    </div>
      <form className='container'>
        <fieldset id='ppp'>
          <div className='mx-auto d-flex gap-5 p-10 my-5  rounded' style={{ border: '1px black solid' }}>
            <input defaultChecked style={{transform: 'scale(1.5)'}} type='radio' name='protectionPlan' onClick={() => setIsChecked(false)}  /><h6>No.</h6>
          </div>   
          <div className='mx-auto p-10 d-flex gap-10 mb-5 rounded' style={{ border: '1px black solid', flexDirection: 'column'}}>
            <div className='d-flex gap-5'>
              <input style={{transform: 'scale(1.5)'}} type='radio' name='protectionPlan' onClick={() => setIsChecked(true)} /><h6>Yes, I would like to add Tuition Protection Plan of $499.00 total.</h6>
            </div>
            <p>The Tuition Protection Plan allows cancellation with full refund, for any reason up to the day the program starts.</p>
          </div>
        </fieldset>
      </form>
    </div>: ''}
    </>
  )
}

export default PaymentProtectionPlan