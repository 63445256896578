//console.log('window.location', window.location, process.env.REACT_APP_API_URL)

const localPort = process.env.PORT || "3000"

const origin = window.origin

//export const BASE_API = origin.replace(localPort, process.env.SERVER_PORT || '8080')
//export const BASE_API = 'https://terrabl.meijunlabs.com'
//export const BASE_API = 'http://localhost:8080'
// export const BASE_API = 'https://terrabackstg.meijunlabs.com'
export const BASE_API =  process.env.REACT_APP_API_BACKEND || 'https://back.terradev.org'
