import {Formik} from 'formik'
import {Field, ErrorMessage} from 'formik'
import {
  glaPaymentSchemas,
  initsPayOpt,
  IPayOpt,
  IEnrollmentObject,
  IPaymentSchedule,
  IPaymentMethod,
} from '../../core/_models'
import PaybyCreditCard from './PayOpt/PaybyCreditCard'
import PayByBankAccount from './PayOpt/PayByBankAccount'
import PaymentSchedule from './PayOpt/PaymentSchedule'
import PaymentTerms from './PaymentTerms'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useParams, Navigate, useNavigate} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {useAuth} from '../../../auth'
import _queryKeys from '../../core/_queryKeys'
import {currencyFormat, parse_payment} from '../../../../../utils/funcHelpers'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import _ from 'lodash'
import ErrorMessages from '../../../Shared/components/ErrorMessages'

const glaFee = 100

const PayOpt = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {setupProgramsEnrollPayment, getOpportunityReq} = useAppRequests()
  const { client, opportunity } = useParams()

  const {
    isLoading,
    isError: isSendPaymentError,
    mutate: sendPayment,
  } = useMutation(setupProgramsEnrollPayment)

  const [sendPaymentError, setSendPaymentError] = useState<any>(null)

  const {currentUser} = useAuth()

  const { data, error, isLoading: opportunityLoading } = useQuery('', () => getOpportunityReq(client, Number(opportunity)));

  const enrollmentSummary: any = data || {};

  let totalDuenow = 0
  const totalDue = enrollmentSummary?.remaining_balance

  const onSubmitPayment = (values: IPayOpt): any => {
    console.log('onSubmitPayment:\n', values)
    values.opportunity = enrollmentSummary?.id
    values.totalAmount = Number(enrollmentSummary?.remaining_balance) || 0
    // values.amount = totalDuenow || 0

    sendPayment(values, {
      onSuccess: () => {
        console.log('go to', '/enroll-part-two')
        return navigate(
          `/dashboard/${currentUser?.currentClient}`,
          {replace: true}
        )
      },
      onError: (err: any) => {
        console.log('payment error', err.response)
        const message = err.response?.data.message
        // if (_.isArray(message) && message.length > 0) {
        //   setSendPaymentError(message)
        // } else {
        //   setSendPaymentError([message ? message : 'Something went wrong!'])
        // }
        setSendPaymentError(message)
      },
    })
  }

  const setPaymentMethod = (e: any, setFieldValue: any): any => {
    e.currentTarget.id === 'creditCard'
      ? setFieldValue('paymentMethod', 'creditCard')
      : e.currentTarget.id === 'bankAccount'
      ? setFieldValue('paymentMethod', 'bankAccount')
      : setFieldValue('paymentMethod', 'sponsored')
  }

  const setAmount = (e: any, setFieldValue: any): any => {
    if (e.currentTarget.id === 'customAmount') {
        setFieldValue('amount', totalDuenow)
    } else if (e.currentTarget.id === 'fullamount') {
        setFieldValue('amount', Number(totalDue))
        console.log('amount', totalDue)
    } else {
        setFieldValue('amount', 0)
    }
  }

  let tabClicked = true
  const setScheduleOptions = (event: any) => {
    if (!!event) {
      tabClicked = true
    } else {
      tabClicked = false
    }
  }
  
  const pMethodSaved = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.isActive)
  const pMethodSavedCredit = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.paymentType === 'bankAccount')
  let ccCount = 0
  let ACHCount = 0
  if(pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if(pMethodSavedACH) ACHCount = pMethodSavedACH.length
  // const pMethodSavedText = pMethodSaved ? parse_payment(pMethodSaved[0]) : null

  // set pMethodSaved as default payment
  initsPayOpt.paymentMethod =  pMethodSaved && pMethodSaved.length > 1
    ? `funding_${pMethodSaved[0]?.paymentType}_${pMethodSaved[0]?.id}`
    : initsPayOpt.paymentMethod || "bankAccount"

  return (
    <div className='mt-10'>
    <Formik
      validationSchema={glaPaymentSchemas}
      initialValues={initsPayOpt}
      onSubmit={onSubmitPayment}
    >
      {({values, setFieldValue, errors, setErrors, handleSubmit}) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className='d-flex flex-column'>
              <h2>Payment method</h2>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs show active '>
                <li className='nav-item col-lg-6'>
                  <a
                    id='bankAccount'
                    className='nav-link active'
                    data-bs-toggle='tab'
                    href='#pmt_ach'
                    onClick={(e) => setPaymentMethod(e, setFieldValue)}
                  >
                    BANK ACCOUNT (ACH PAYMENT)
                  </a>
                </li>
                <li className='nav-item col-lg-6'>
                  <a
                    id='creditCard'
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#pmt_credit_card'
                    onClick={(e) => setPaymentMethod(e, setFieldValue)}
                  >
                    CREDIT CARD
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade show active' id='pmt_ach' role='tabpanel'>
                  {pMethodSaved && pMethodSaved.map((data: any) => {return data.paymentType  === 'bankAccount'}) && ACHCount > 0 ? 
                      (<>
                          <div className='d-flex flex-column my-5'>
                            {pMethodSavedACH?.map((data: any) => {
                              const dataSaveText = data ? parse_payment(data) : null
                              return (
                                  <div className='mb-7 me-5'>
                                  <Field
                                    type='radio'
                                    className='form-check-input me-2'
                                    value={`funding_${data.paymentType}_${data?.id}`}
                                    name={'selectPMethod'}
                                    id={`funding_${data.paymentType}_${data?.id}`}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                  >
                                    <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                    Ending in {data?.cardNumber?.replaceAll('x', '')}
                                  </label>
                                </div>
                              )
                            })}
                            <div className='mb-7 me-5'>
                              <Field
                                type='radio'
                                className='form-check-input me-2'
                                value={'bankAccount'}
                                name={'selectPMethod'}
                                id={'bankAccount_selectPMethod'}
                              />
                              <label className='form-check-label' htmlFor={'bankAccount_selectPMethod'}>
                                Use a new payment method
                              </label>
                            </div>
                          </div>
                          {values.selectPMethod === 'bankAccount' && <PayByBankAccount />}
                        </>
                        ) : (
                    <PayByBankAccount />
                    )}
                </div>
                <div className='tab-pane fade' id='pmt_credit_card' role='tabpanel'>
                {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === 'creditCard'})  && ccCount > 0 ? (
                      <>
                        <div className='d-flex flex-column my-5'>
                        {pMethodSavedCredit?.map((data: any) => {
                              const dataSaveText = data ? parse_payment(data) : null
                              return (
                                  <div className='mb-7 me-5'>
                                  <Field
                                    type='radio'
                                    className='form-check-input me-2'
                                    value={`funding_${data.paymentType}_${data?.id}`}
                                    name={'selectPMethod'}
                                    id={`funding_${data.paymentType}_${data?.id}`}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                  >
                                    <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                    Ending in {data?.cardNumber?.replaceAll('x', '')}
                                  </label>
                                </div>
                              )
                            })}

                          <div className='mb-7 me-5'>
                            <Field
                              type='radio'
                              className='form-check-input me-2'
                              value={'creditCard'}
                              name={'selectPMethod'}
                              id={'creditCard_selectPMethod'}
                            />
                            <label className='form-check-label' htmlFor={'creditCard'}>
                              Use a new payment method
                            </label>
                          </div>
                        </div>
                        {values.selectPMethod === 'creditCard' && <PaybyCreditCard  currentFormikVal={values}/>}
                      </>
                    ) : (
                    <PaybyCreditCard  currentFormikVal={values}/>
                   )}
                </div>
              </div>

                <h2>Payment amount</h2>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs'>
                  {client === '47821104' ? (
                    <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(true)}>
                    <a
                      id='paymentSchedule'
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#amt_schedule'
                      onClick={(e) => setAmount(e, setFieldValue)}
                    >
                      PAYMENT SCHEDULE
                    </a>
                  </li>
                  ) : ""}
                  <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(false)}>
                    <a
                      id='customAmount'
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#amt_custom'
                      onClick={(e) => setAmount(e, setFieldValue)}
                    >
                      CHOOSE AMOUNT
                    </a>
                  </li>
                  <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(false)}>
                    <a
                      id='fullamount'
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#amt_full'
                      onClick={(e) => setAmount(e, setFieldValue)}
                    >
                      PAY IN FULL
                    </a>
                  </li>
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade' id='amt_schedule' role='tabpanel'>
                      {tabClicked == true && <PaymentSchedule />}
                  </div>
                  <div className='tab-pane fade show active' id='amt_custom' role='tabpanel'>
                      {opportunityLoading ? (
                        <div className='d-flex justify-content-center align-items-center m-auto'>
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ): (
                        <div className='col-lg-4 col-md-4 d-flex flex-column m-auto'>
                          <p>Other amount</p>
                          <Field
                            type='number'
                            className='form-control form-control-solid'
                            placeholder=''
                            name='amount'
                          />
                          <p>
                            Enter an amount between the deposit (${totalDuenow}) and the full amount ($
                            {totalDue})
                          </p>
                        </div>
                      )}
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='amount' />
                    </div>
                  </div>
                  <div className='tab-pane fade' id='amt_full' role='tabpanel'>
                    <div className='col-lg-4 col-md-4 d-flex flex-column m-auto'>
                      <p>Pay all of remaining prices.</p>
                    </div>
                  </div>
                </div>

              {/* {enrollmentSummary?.schedules &&
                enrollmentSummary?.schedules.length > 0 &&
                tabClicked == true && <PaymentSchedule />} */}

              <div className='text-danger mt-2'>
                <span>{errors.paymentMethod}</span>
                <ErrorMessage name='paymentMethod' />
              </div>
              <PaymentTerms />
              <div className='row bg-success py-10'>
                <div className='col-lg-6'>
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Parent: Write your name here *'
                    name='parentSignatureTerms'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='parentSignatureTerms' />
                  </div>
                </div>
                <div className='col-lg-6'>
                  By writing your name here, you are validating that you have read and agree to
                  the above Terms & Conditions.
                </div>
              </div>
              {/* {isSendPaymentError && (
                <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
                  <div className='d-flex flex-column'>
                    {sendPaymentError?.map((err: any) => (
                      <span>{err}</span>
                    ))}
                  </div>
                </div>
              )} */}
              <ErrorMessages isError={isSendPaymentError} messages={sendPaymentError} />
              <div className='d-flex justify-content-end my-10'>
                <div className='d-flex align-items-center'>
                  {/* <span className='fs-2 me-5'>Reserve your spot!</span> */}
                  <button type='submit' className='btn btn-lg btn-primary me-3' disabled={isLoading}>
                    {!isLoading && (
                      <span className='indicator-label'>
                        Next Step
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    )}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  </div>
  )
}

export default PayOpt
