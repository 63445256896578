import React from 'react'

const DefaultSpinnerLoading = ({title}: {title?: string}) => {
  return (
    <div className='w-100'>
      <div className='d-flex justify-content-center align-items-center'>
        <span className='me-5'>{title ? title : 'Please wait...'}</span>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default DefaultSpinnerLoading