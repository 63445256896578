import { Form, Formik } from 'formik'
import CustomSelect from './CustomSelect'
import CustomInput from './CustomInput'
import CustomTextArea from './CustomTextArea'
import { IEnrollmentForm, IEnrollmentFormGroup, client, collumn } from '../data'
import { useState } from 'react'
import CustomModalOption from './CustomModalOption'
import CustomChoices from './CustomChoices'
import CustomCheckbox from './CustomCheckbox'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomReactSelect from '../../../../../_metronic/shared-components/CustomReactSelect'
import { enrollment_questionnaires } from '../model'

interface IProps {
  initialValues: IEnrollmentForm
  EnrollmentFormSchema: any
  onSubmit: any
  enrollmentGroup: IEnrollmentFormGroup[]
}

const CustomForm = ({initialValues, EnrollmentFormSchema, onSubmit, enrollmentGroup}: IProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const enrollmentGroupWithClient = enrollmentGroup.map((item: IEnrollmentFormGroup) => {
    return {
      ...item,
      name: `(${item.portal_client?.abbreviation}) ${item.name}`
    }
  })

  const helper_text_isDisabled = (values: IEnrollmentForm) => {
    if (values.type === 'question' && (values.field_type === 'choice' || values.field_type === 'checkbox')) return false
    if (values.type === 'question' || values.type === 'boolean') return true 
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            validationSchema={EnrollmentFormSchema}
            onSubmit={onSubmit}
          >
            {({isSubmitting, setFieldValue, values}) => {
              const filterType = collumn.type.find(item => item.value === values.type)

              return (
                <Form>
                  <CustomSelect
                    required={true}
                    label='Client ID'
                    name='client_id'
                    placeholder='Select a Client'
                    options={client.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  />

                  <CustomSelect
                    required={true}
                    label='Enrollment Group'
                    name='enrollment_form_group_id'
                    placeholder='Select a Type'
                    options={enrollmentGroupWithClient.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />

                  <CustomSelect
                    name='type' 
                    label='Type'
                    options={collumn.type}
                    placeholder='Select a type'
                    onChange={(e: any) => {
                      setFieldValue('type', e.value)
                      if (e.value === 'text') {
                        setFieldValue('field_type', 'text')
                        setFieldValue('required', false)
                        setFieldValue('label', '')
                        return
                      } 
                      if (e.value === 'boolean') {
                        setFieldValue('field_type', 'hidden')
                        setFieldValue('helper_text', '')
                        return
                      }
                      setFieldValue('field_type', '')
                      setFieldValue('helper_text', '')
                    }}
                    required={true}
                  />

                  <CustomSelect
                    name='field_type' 
                    label='Field Type'
                    options={filterType?.field_type || []}
                    placeholder='Select a field type'
                    required={true}
                    isDisabled={values.type === 'text' || values.type === 'boolean'}
                  />

                  {(values.field_type === 'choice' || values.field_type === 'checkbox') ? (
                    <>
                      <CustomChoices 
                        values={values} 
                        setFieldValue={setFieldValue} 
                        setOpenModal={setOpenModal} 
                      />
                    </>
                  ) : ''}

                  <CustomSelect
                    required={true}
                    label='Required'
                    name='required'
                    placeholder='Select a option'
                    options={filterType?.required || []}
                    isDisabled={values.type === 'text'}
                  />

                  <CustomInput
                    required={true} 
                    label='Label' 
                    name='label' 
                    placeholder='Enter a label'
                    disabled={values.type === 'text'}
                  />

                  {/* <CustomTextArea
                    required={values.field_type === 'choice' ? false : true} 
                    label='Description' 
                    name="helper_text" 
                    placeholder='Enter a description' 
                    disabled={helper_text_isDisabled(values)} 
                  />
                   */}

                  <div className='fv-row mb-7 position-relative'>
                    <label className='flex-grow-1 fw-bold fs-6 mb-2 required'>Description</label>
                    <ReactQuill 
                      theme="snow"
                      placeholder='Enter a description'
                      value={values.helper_text}
                      readOnly={helper_text_isDisabled(values)}
                      onChange={(value) => {
                        setFieldValue('helper_text', value)
                      }}
                    />
                    {helper_text_isDisabled(values) ? (
                      <div style={{
                        position: 'absolute',
                        top: 28,
                        left: 0,
                        height: 'calc(100% - 28px)',
                        width: '100%',
                        borderRadius: 5,
                        background: '#e5e5e57a'
                      }} />
                    ) : ''}
                  </div>
                  
                  <CustomReactSelect
                    options={enrollment_questionnaires.map((question) => ({
                      value: question.value,
                      label: question.label,
                    }))} 
                    required={true} 
                    label='Name' 
                    name='name' 
                    placeholder='Enter a salesforce name' 
                  />
                  
                  <CustomCheckbox
                    label='Status'
                    name='status'
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <div className='text-center pt-15'>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='btn btn-primary cursor-pointer'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>

                  {openModal && (
                    <CustomModalOption 
                      setOpenModal={setOpenModal} 
                      setFieldValue={setFieldValue} 
                      values={values} 
                    />
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default CustomForm