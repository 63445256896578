import {AxiosResponse} from 'axios'
import {IDocumentFile} from '../../../application/core/_models'
import useAxiosPrivate from '../../../auth/hooks/useAxiosPrivate'
import {IFormResponseTab, IOtherTab, OpportunitiesQueryResponse} from '../components/core/_models'

export const useOpportunityRequests = () => {
  const axisosPrivate = useAxiosPrivate()
  const getAllOpportunitiesReq = (query: string): Promise<OpportunitiesQueryResponse> => {
    return axisosPrivate
      .get(`/get_all_opportunities/?${query}`)
      .then((d: AxiosResponse<OpportunitiesQueryResponse>) => d.data)
  }

  const getAllFormResponsesReq = async (opportunityId: string | undefined) => {
    if (!opportunityId) {
      return
    }
    const {data} = await axisosPrivate.get<{
      data: IFormResponseTab[]
      other_tabs: IOtherTab
    }>(`/get_opportunity_and_responses/${opportunityId}`)
    return data
  }
  const getOpportunityFieldsReq = async () => {
    const {data} = await axisosPrivate.get<{
      data: IFormResponseTab[]
    }>(`/get_opportunity_fields`)
    return data
  }

  const saveOpportunityAndResponses = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/save_opportunity_and_responses`, inputs)
    return data
  }

  return {
    getAllOpportunitiesReq,
    getAllFormResponsesReq,
    getOpportunityFieldsReq,
    saveOpportunityAndResponses,
  }
}
