/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {CLIENT_NAMES_LIST} from '../../../../utils'
import useAuthRequests from '../hooks/useAuthRequests'
import {IPortalClients, IPortalClient} from '../core/_models'
import {useQuery} from 'react-query'
import ProgramList from './partials/ProgramList'
import {TbCircleArrowLeftFilled} from 'react-icons/tb'
import './Login.css'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  subdomain: Yup.string().required('Please select a client program'),
  // client: Yup.object().shape({
  //   value: Yup.string().required('Please select a client program'),
  // }),
})
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function getDefaultClientOption(domain: string): string {
  const client = CLIENT_NAMES_LIST.filter((c) => c.domainName === domain)
  return Array.isArray(client) && client.length > 0 ? client[0].value : ''
}

export function Login() {
  var temp: IPortalClients = {
    portalClient: [
      {
        value: '',
        name: '',
        abbreviation: '',
        reference_id: '',
      },
    ],
  }
  const {getPrograms} = useAuthRequests()
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(`getPrograms`, () => {
    return getPrograms()
  })
  const navigate = useNavigate()
  const [redirectClient, setRedirectClient] = useState('')
  const [isMobileView, setIsMobileView] = useState(false)

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, currentLocalDomain} = useAuth()
  const {getCurrentUser} = useAuthRequests()

  const changeRedirectOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    if (e.target.value != 'Choose a Program') setRedirectClient(e.target.value)
  }

  const initialValues = {
    email: '',
    password: '',
    subdomain: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password, values.subdomain)
        if (auth.api_token) {
          saveAuth(auth)
          const {currentUser} = await getCurrentUser()
          //const reponse = await axisosPrivate('/authenticate')
          setCurrentUser(currentUser)
        } else if (auth.redirectUrl) {
          window.location.href = auth.redirectUrl
        }
      } catch (error) {
        console.log(error)
        saveAuth(undefined)

        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992)
    }

    handleResize() // Check initial width
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const navigationHandler = () => {
    navigate('/')
  }
  if (isLoading) {
    return (
      <div>
        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </div>
    )
  } else {
    return (
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        {/* <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In to Terra Education</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            New Here? <br />
            Choose the program to enroll into:
            <select
              className='form-select form-select-lg form-select-solid'
              onChange={changeRedirectOptionHandler}
            >
              <option>Choose a Program</option>
              {response?.map((c, idx) => {
                if (c.name != 'terraEducation') {
                  return (
                    <option value={c.reference_id} key={idx}>
                      {c.name}
                    </option>
                  )
                }
              })}
            </select>
            <Link to={`/enroll/${redirectClient}`} className='link-primary fw-bolder'>
              Click here to start Enrollment.
            </Link>
          </div>
        </div> */}
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In to Terra Education</h1>
        </div>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block text-danger'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Log in as:</label>
          <div className='card-container'>
            {response
              ?.sort((a: any, b: any) => a.id - b.id)
              .map((c, idx) => {
                if (c.name !== 'terraEducation') {
                  return (
                    <div key={c.id}>
                      <div
                        className={`card_body ${
                          formik.values.subdomain === c.reference_id ? 'card-active' : ''
                        }`}
                        onClick={() => formik.setFieldValue('subdomain', c.reference_id)}
                        style={{cursor: 'pointer'}}
                      >
                        <p className='card-text'>{c.name}</p>
                      </div>
                    </div>
                  )
                }
              })}
          </div>
          {formik.touched.subdomain && formik.errors.subdomain && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.subdomain}</span>
            </div>
          )}
        </div>
        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='goback-btn' onClick={navigationHandler}>
          <TbCircleArrowLeftFilled className='icon' size={window.innerWidth <= 300 ? 20 : 30} />
          <div>{isMobileView ? 'Go back' : 'Go back to enrollment page'}</div>
        </div>
        {/* end::Action */}
      </form>
    )
  }
}
