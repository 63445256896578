import {axiosInstance, axiosPrivate} from '../../../../axiosInstance'
import { getAuth } from './AuthHelpers'
import {IAuth, IResetPassword, IResetToken, IResgister, IUser} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return IAuth
export function login(email: string, password: string, client: string) {
  return axiosInstance.post<IAuth>('/login', {
    email,
    password,
    client,
  })
}

export async function axiosLogout(data: null) {
  const response = await axiosInstance.get('/logout')
  return response
}

// Server should return IAuth
export async function axiosRegister(data: IResgister) {
  return await axiosInstance.post('/signup', data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstance.post<{result: boolean}>('/forgot_password', {
    email,
  })
}

export function getUserByToken(token: string) {
  return axiosInstance.post<IUser>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

// export async function getCurrentUser() {
//   const {data} = await axiosInstance.get<{message: string; currentUser: IUser}>('/authenticate',   {headers: {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${getAuth()?.api_token}`,
//   }})
//   return data
// }

export async function checkConnection() {
  return await axiosInstance.head('/')
}

export const axiosCheckResetPasswordToken = async ({hash, token}: IResetToken) => {
  const {data} = await axiosInstance.get(`/auth/reset_password_token/${hash}/${token}`)
  return data
}

export const automateSwitchClient = async (client:string|undefined) => {
  if (!client) {
    return
  }
    const {data} = await axiosPrivate.post(`/auth/switch_client`, {client})
    return data
}

export const axiosProcessResetPassword = async (input: IResetPassword) => {
  const {data} = await axiosInstance.post(`/auth/reset_password`, input)
  return data
}
