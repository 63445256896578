import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {ErrorMessage, Form, Formik, FormikValues, Field} from 'formik'
import {UseMutateAsyncFunction, useQueryClient} from 'react-query'
import React, {Dispatch, SetStateAction, useState, useRef} from 'react'
import {IProductDetailData, productSchema} from './core/_models'
import _queryKeys from './core/_queryKeys'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import FilterField from '../../../application/components/Shared/FilterField'
import {currencyFormat} from '../../../../../utils/funcHelpers'
import PriceModal from './modal/PriceModal'
import {ITab} from '../../../Shared/core/_models'
import {useQueryRequest} from '../../../Shared/core/QueryRequestProvider'
import moment from 'moment'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import AsyncSelect from 'react-select/async'

const initProduct = {} as {[key: string]: any}

interface Props {
  productId?: string
  isLoading: boolean
  setMessage: Dispatch<SetStateAction<string>>
  updateProductByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
  addProductByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
}

const AddUpdateProduct = ({
  productId,
  isLoading,
  setMessage,
  updateProductByAdminAsync,
  addProductByAdminAsync,
}: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()
  const [query] = useState<string>(stringifyRequestQuery(state))
  const {currentUser} = useAuth()
  const [itemForEditPrice, setItemForEditPrice] = useState<{[key: string]: any} | undefined>(
    undefined
  )
  const formFields = queryClient.getQueryData<{
    data: IProductDetailData
  }>(productId ? _queryKeys.getProductDetailFieldsReq : _queryKeys.getProductFieldsReq)

  const durationRef = useRef<HTMLInputElement>(null)

  //create init formik
  if (productId) {
    //for edit
    const programDetailTab = formFields?.data.programDetailTab
    if (programDetailTab) {
      initProduct[programDetailTab.group] = {}
      programDetailTab.form_data.forEach((val) => {
        initProduct[programDetailTab.group][val.name] = val.answer
      })
      initProduct[programDetailTab.group].tuition = 0
    }
    formFields?.data.programTabs.forEach((tab) => {
      if (!initProduct[tab.group]) {
        initProduct[tab.group] = {}
      }
      tab.form_data.forEach((val) => {
        initProduct[tab.group][val.name] = val.answer || ''
      })
    })

    const program_sessions_selected = formFields?.data.selectedSessions

    if (program_sessions_selected) {
      initProduct.program_sessions = program_sessions_selected.map((item) => {
        return item.program_session_id
      })
    } else {
      initProduct.program_sessions = []
    }

    const enrollment_forms_selected = formFields?.data.selectedGroups
    if (enrollment_forms_selected) {
      initProduct.enrollment_form_groups = enrollment_forms_selected.map((item) => {
        return item.form_group_id
      })
    } else {
      initProduct.enrollment_form_groups = []
    }

    const enrollment_extra = formFields?.data.selectedExtraItems

    if (enrollment_extra) {
      initProduct.enrollment_extras = enrollment_extra.map((val) => ({
        id: val.enrollment_extra_id,
        is_required: val.is_required,
      }))
    }

    const tuition = formFields?.data.tuitionTabs
    
    if (tuition) {
      initProduct.product_detail.tuition = tuition.table_data?.[0].tuition
    }
  } else {
    //for add
    formFields?.data.programTabs.forEach((tab) => {
      initProduct[tab.group] = {}
      tab.form_data.forEach((val) => {
        initProduct[tab.group][val.name] = ''
      })
    })
    const programDetailTab = formFields?.data.programDetailTab
    if (programDetailTab) {
      initProduct[programDetailTab.group] = {}
      programDetailTab.form_data.forEach((val) => {
        initProduct[programDetailTab.group][val.name] = ''
      })

      initProduct[programDetailTab.group].is_active = true
      initProduct[programDetailTab.group].group = false
      initProduct[programDetailTab.group].tuition = 0
      initProduct[programDetailTab.group].duration = 0
      initProduct[programDetailTab.group].start_date = ''
      initProduct[programDetailTab.group].end_date = ''
    }

    initProduct.enrollment_form_groups = []
    initProduct.program_sessions = []

    initProduct.enrollment_extras = []
  }

  const [dataForEdit] = useState<{[key: string]: any}>(initProduct)

  const onSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      if (productId && updateProductByAdminAsync) {
        // delete values.product_detail.tuition
        values.product_detail.duration = durationRef.current?.value
        const res = await updateProductByAdminAsync(values)
        if (res) {
          setMessage('Product has been updated successfully!')
        }
      } else {
        values.product_detail.start_date = moment(values.product_detail.start_date).format(
          'MM/DD/YY'
        )
        values.product_detail.end_date = moment(values.product_detail.end_date).format('MM/DD/YY')
        delete values.product_detail.id
        if (!addProductByAdminAsync) return

        const reminder =
          'Note: If the application extra item is selected but is_required is not checked, it will default to an optional item.'
        alert(reminder)

        const res = await addProductByAdminAsync(values)
        console.log('res', res)
        if (res) {
          setMessage('New product has been added successfully!')
          actions.resetForm()
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      actions.setSubmitting(true)
      ScrollTopComponent.goTop()
    }
  }

  const onPriceSubmit = async () => {
    try {
      if (productId && updateProductByAdminAsync) {
        const res = await updateProductByAdminAsync({product_price: itemForEditPrice})
        if (res) {
          setMessage('Product has been updated successfully!')
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      queryClient.prefetchQuery(_queryKeys.getProductDetailFieldsReq)
      queryClient.prefetchQuery(`${QUERIES.PRODUCTS_LIST}-${query}`)
      setItemForEditPrice(undefined)
    }
  }

  const PriceBody = (
    <>
      <div className='row mb-7'>
        <div className='col-lg-6'>
          <label className={'form-label fw-bolder text-dark fs-6'}>Product Name:</label>
        </div>
        <div className='col-lg-6'>
          <span className={'text-dark fs-6'}>{itemForEditPrice?.name}</span>
        </div>
      </div>
      <div className='row mb-7'>
        <div className='col-lg-6'>
          <label className={'form-label fw-bolder text-dark fs-6'}>List Price:</label>
        </div>
        <div className='col-lg-6'>
          <input
            type='number'
            className='form-control form-control-solid'
            value={itemForEditPrice?.tuition}
            onChange={(e) =>
              setItemForEditPrice({
                ...itemForEditPrice,
                tuition: e.target.value,
              })
            }
          />
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-lg-6'>
          <label className={'form-label fw-bolder text-dark fs-6'}>Active:</label>
        </div>
        <div className='col-lg-6'>
          <input
            className='form-check-input'
            type='checkbox'
            //value={itemForEditPrice?.is_active}
            checked={itemForEditPrice?.is_active}
            onChange={(e) =>
              setItemForEditPrice({
                ...itemForEditPrice,
                is_active: e.target.checked,
              })
            }
          />
        </div>
      </div> */}
    </>
  )

  const generateTab = (tab: ITab, idx: number, currentValues: any) => {
    return (
      <>
        {tab.title === 'Schedule' && (
          <div className='rounded'>
            <div
              className={'accordion-header rounded ps-5 bg-light py-4 d-flex'}
              data-bs-toggle='collapse'
              data-bs-target={`#kt_accordion_item_1`}
            >
              <span className='accordion-icon'>
                <KTSVG
                  className='svg-icon svg-icon-4'
                  path='/media/icons/duotune/arrows/arr064.svg'
                />
              </span>

              <h3 className='fs-3 text-gray-800 fw-bold mb-0 ms-4'>{tab.title}</h3>
            </div>
            <div
              id={`kt_accordion_item_1`}
              className={'fs-6 collapse ps-10 mt-7 show'}
              data-bs-parent={`#kt_accordion_item_1`}
              style={{transition: 'all 0.2s ease-in-out'}}
            >
              {tab.form_data.map((form, id) => {
                const start = moment(currentValues.product_detail.start_date)
                const end = moment(currentValues.product_detail.end_date)
                const duration = end.diff(start, 'days')

                return (
                  <div className='row' key={id}>
                    {form.group === 'product_detail' && (
                      <>
                        <div className='col-lg-6 mb-5' key={id}>
                          <label className={'form-label fw-bolder text-dark fs-6 required'}>
                            {form.label}
                          </label>
                        </div>
                        <div className='col-lg-6 mb-5'>
                          {form.field_type !== 'number' ? (
                            <FilterField 
                              form={form} 
                              prefixFieldName={tab.group + '.'} 
                              dateFormat='MM/DD/YYYY'
                            />
                          ) : (
                            <input
                              ref={durationRef}
                              type="number" 
                              className='form-control form-control-l'
                              value={duration}
                              disabled
                            />
                          )}
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={tab.group + '.' + form.name} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
              {tab.group === 'product_ssb' && defaultExtraFees(currentValues)}
            </div>
          </div>
        )}
      </>
    )
  }

  //const glaTabs = formFields?.data.programTabs.filter((p) => p.group === 'predeparture')
  const ssbTabs = formFields?.data.programTabs.filter((p) => p.group === 'product_ssb')
  const defaulTabs = formFields?.data.programTabs.filter((p) => p.group !== 'product_ssb')

  const defaultExtraFees = (currentValues: any) => {
    const currentExtrasIds = currentValues.product_ssb.enrollmentExtrasIds
    //console.log('currentExtrasIds', currentExtrasIds)

    //console.log('defaultExtraFields', defaultExtraFields)
    if (!currentExtrasIds || currentExtrasIds.length === 0) {
      return null
    }
    const extraFreeFields =
      ssbTabs &&
      ssbTabs[0].form_data.filter((f) => f.name === 'enrollmentExtrasIds')[0].choicesObject
    //console.log('currentExtraFreeFields', extraFreeFields)
    const defaultExtraFields = extraFreeFields?.filter((f) =>
      currentExtrasIds.includes(f.value.toString())
    )
    return (
      <div className='row'>
        <div className='col-lg-6 mb-5'>
          <label className={'form-label fw-bolder text-dark fs-6 required'}>
            Select Required Fees
          </label>
        </div>
        <div className='col-lg-6 mb-5'>
          {defaultExtraFields?.map((c, idx) => (
            <div className='mb-4' key={idx}>
              <Field
                type='checkbox'
                className='form-check-input me-2'
                name={'product_ssb.defaultExtra'}
                value={c.value.toString()}
                id={'defaultExtra_' + c.value}
              />
              <label className='form-check-label' htmlFor={'defaultExtra_' + c.value}>
                {c.title}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const [tableData] = useState<any>(formFields?.data.enrollmentExtras)

  const [characters, setCharacters] = useState<any>(formFields?.data.formGroups)

  const [inputValue, setInputValue] = useState<any>('')

  const sessions: Array<{id: number; name: string; start_date: string; end_date: string}> =
    formFields?.data.sessions || []

  return (
    <>
      <Formik
        validationSchema={productSchema}
        initialValues={dataForEdit}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({values, setFieldValue, isSubmitting, handleBlur}) => {

          const loadOptions = (inputValue: string, callback: any) => {
            if (inputValue.trim().length === 0) {
              callback([])
              return
            }

            const sessions = formFields?.data.sessions || []

            const filteredOptions = sessions?.filter((session: any) =>
              session?.name.toLowerCase().includes(inputValue.toLowerCase())
            )

            const options = filteredOptions?.map((session: any) => ({
              value: session.id,
              label: `${session.name} (${moment(session.start_date).format(
                'MM/DD/YYYY'
              )} - ${moment(session.end_date).format('MM/DD/YYYY')})`,
            }))

            callback(options)
          }

          const handleSelectChange = (selectedOption: any) => {
            const selectedSessionIds = selectedOption
              ? selectedOption.map((option: any) => option.value)
              : []
            setFieldValue('program_sessions', selectedSessionIds)
          }

          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const {name, checked} = e.target
            const regexResult = /enrollment_extras\[(\d+)\]\.is_required/.exec(name)

            if (regexResult) {
              const fieldIndex = parseInt(regexResult[1])
              let enrollmentExtras = [...(values.enrollment_extras || [])]

              const targetIndex = enrollmentExtras.findIndex((item) => item.id === fieldIndex)

              if (targetIndex !== -1) {
                enrollmentExtras[targetIndex].is_required = checked
              } else {
                const newItem = {
                  id: fieldIndex,
                  is_required: checked,
                }
                enrollmentExtras.push(newItem)
              }

              enrollmentExtras.sort((a, b) => a.id - b.id)

              setFieldValue('enrollment_extras', enrollmentExtras)
            }
          }

          const enrollmentExtras = (id: number) => {
            const newItem = {
              id: id,
              is_required: false
            }
            if (values.enrollment_extras.length === 0) {
              setFieldValue('enrollment_extras', [newItem])
              return;
            }
            const curId = values.enrollment_extras.find((item: any) => item.id === id)
            if (curId) {
              const newArr = values.enrollment_extras.filter((item: any) => item.id !== id)
              setFieldValue('enrollment_extras', newArr)
              return
            }
            setFieldValue('enrollment_extras', [...values.enrollment_extras, newItem])
          }

          const updateIsRequired = (id: number) => {
            const newVal = values.enrollment_extras.map((item: any) => {
              if (Number(item.id) === id) {
                return {...item, is_required: !item.is_required}
              } 
              return item
            })
            setFieldValue('enrollment_extras', newVal)
          }

          const enrollmentFormGroupSelected = (id: any) => {
            if (values.enrollment_form_groups.includes(id)) {
              const newArr = values.enrollment_form_groups.filter((item: number) => item !== id)
              setFieldValue('enrollment_form_groups', newArr)
              return
            }
            setFieldValue('enrollment_form_groups', [...values.enrollment_form_groups, id])
          }

          return (
            <Form>
              <div className='d-flex my-5 p-5 bg-white sticky-top' style={{top: '120px'}}>
                {/* begin::Actions */}
                <div className='text-center'>
                  <button
                    type='reset'
                    onClick={() => {
                      navigate(`/apps/${currentUser?.currentClient}/products-management/products`, {
                        replace: true,
                      })
                    }}
                    className='btn btn-secondary me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={isLoading}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isLoading}
                  >
                    {!isLoading && <span className='indicator-label'>Submit</span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              <div className='card mb-7'>
                <div className='card-header'>
                  <h3 className='card-title'>{formFields?.data.programDetailTab.title}</h3>
                </div>
                <div className='card-body'>
                  {formFields?.data.programDetailTab.form_data.map((form, id) => {
                    console.log(form,'formform')
                    return (
                      <div className='row' key={id}>
                        {form.type === 'question' && form.name !== 'program_summary' && (
                          <>
                            <div className='col-lg-6 mb-5' key={id}>
                              <label className={'form-label fw-bolder text-dark fs-6 required'}>
                                {form.label}
                              </label>
                            </div>
                            <div className='col-lg-6 mb-5'>
                              <FilterField
                                form={form}
                                prefixFieldName={formFields?.data.programDetailTab.group + '.'}
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage
                                  name={formFields?.data.programDetailTab.group + '.' + form.name}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='card mb-7'>
                <div className='card-header bg-primary'>
                  <h3 className='card-title text-light '>{formFields?.data.tuitionTabs.title}</h3>
                </div>
                <div className='card-body'>
                  {productId ? (
                    <div className='row'>
                      <div className='col-lg-6 mb-5'>
                        <label className={'form-label fw-bolder text-dark fs-6 required'}>
                          Tuition
                        </label>
                      </div>
                      <div className='col-lg-6 mb-5'>
                        <input 
                          type='number'
                          className='form-control'
                          min="0"
                          value={values.product_detail.tuition}
                          onChange={(e) => {
                            setFieldValue('product_detail', {
                              ...values.product_detail,
                              tuition: Number(e.target.value)
                            })
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    formFields?.data.tuitionTabs?.form_data?.map((tab, id) => {
                      return (
                        <div className='row' key={id}>
                          {tab.type === 'question' && (
                            <>
                              <div className='col-lg-6 mb-5' key={id}>
                                <label className={'form-label fw-bolder text-dark fs-6 required'}>
                                  {tab.label}
                                </label>
                              </div>
                              <div className='col-lg-6 mb-5'>
                                <FilterField form={tab} prefixFieldName={tab.group + '.'} />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name={tab.group + '.' + tab.name} />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )
                    })
                  )}
                </div>
              </div>











              <div className='card mb-7'>
                <div className='card-body'>
                  <div className='accordion accordion-icon-toggle' id='kt_accordion'>
                    {/* {formFields?.data.programTabs.map((tab, idx) => {
                      return generateTab(tab, idx)
                    })} */}
                    {defaulTabs?.map((tab, idx) => generateTab(tab, idx, values))}
                    {/* {values.product_detail.client_id === '84984438' &&
                      glaTabs?.map((tab, idx) => generateTab(tab, idx, values))} */}
                    {values.product_detail.client_id === '47821104' &&
                      ssbTabs?.map((tab, idx) => generateTab(tab, idx, values))}
                  </div>
                </div>
              </div>

              {/* Enrollment Extras  */}
              <div className='card mb-7'>
                <div className='card-body'>
                  <div className='accordion accordion-icon-toggle' id='kt_accordion'>
                    <div className='rounded'>
                      <div
                        className='accordion-header rounded ps-5 bg-light py-4 d-flex'
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_accordion_item_2`}
                      >
                        <span className='accordion-icon'>
                          <KTSVG
                            className='svg-icon svg-icon-4'
                            path='/media/icons/duotune/arrows/arr064.svg'
                          />
                        </span>
                        <h3 className='fs-3 text-gray-800 fw-bold mb-0 ms-4'>
                          Program Product Extras
                        </h3>
                      </div>
                      <div
                        id={`kt_accordion_item_2`}
                        className={'fs-6 collapse ps-10 mt-7 show'}
                        data-bs-parent={`#kt_accordion_item_2`}
                        style={{transition: 'all 0.2s ease'}}
                      >
                        <div className='row'>
                          <div className='col-lg-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Enrollment Extras
                            </label>
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='enrollment_extras' />
                            </div>
                          </div>

                          <div className='col-lg-6 mb-5'>
                            <div
                              style={{
                                minHeight: '150px' /* Adjust the value based on your content */,
                                marginBottom: '20px',
                              }}
                            >
                              {tableData.map((item: any) => {
                                const isActive = values.enrollment_extras.find((item2: any) => item2.id === item.id)
                    
                                if (item.default_client !== values.product_detail.client_id) {
                                  return () => {}
                                }
                 
                                return (
                                  <div 
                                    key={item.id} className='d-flex justify-content-between align-items-center px-3 my-2 rounded cursor-pointer'
                                    style={{
                                      height: 50,
                                      transition: '.4s',
                                      background: isActive ? '#009ef7' : 'white',
                                      border: isActive ? '1px solid transparent' : '1px solid black',
                                      color: isActive ? 'white' : 'black'
                                    }}
                                    onClick={() => {
                                      enrollmentExtras(item.id)
                                    }}
                                  >
                                    <div className='w-100 d-flex align-items-center'>
                                      <h2 
                                        className='m-0' 
                                        style={{
                                          color: isActive ? 'white' : 'black'
                                        }}
                                      >
                                        {item.default_client === '47821104' && 'SSB'}
                                        {item.default_client === '88562775' && 'TTN'}
                                      </h2>
                                      <p className='m-0 mx-4'>{item.name}</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                      <p 
                                        className='m-0 fw-bold' 
                                        style={{
                                          marginLeft: 'auto',
                                        }}
                                      >${item.amount}</p>
                                      <input
                                        className='justify-content-end'
                                        type='checkbox'
                                        name={`enrollment_extras`}
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 5,
                                          display: isActive ? 'block' : 'none'
                                        }}
                                        checked={isActive?.is_required ? true : false}
                                        onClick={(e: any) => {
                                          e.stopPropagation()
                                          updateIsRequired(item.id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Enrollment Form Group */}
              <div className='card mb-7'>
                <div className='card-body'>
                  <div className='accordion accordion-icon-toggle' id='kt_accordion'>
                    <div className='rounded'>
                      <div
                        className='accordion-header rounded ps-5 bg-light py-4 d-flex'
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_accordion_item_3`}
                      >
                        <span className='accordion-icon'>
                          <KTSVG
                            className='svg-icon svg-icon-4'
                            path='/media/icons/duotune/arrows/arr064.svg'
                          />
                        </span>
                        <h3 className='fs-3 text-gray-800 fw-bold mb-0 ms-4'>
                          Enrollment Form Group
                        </h3>
                      </div>
                      <div
                        id={`kt_accordion_item_3`}
                        className={'fs-6 collapse ps-10 mt-7 show'}
                        data-bs-parent={`#kt_accordion_item_3`}
                        style={{transition: 'all 0.2s ease'}}
                      >
                        <div className='row'>
                          <div className='col-lg-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Enrollment Part 2 Form Sequence
                            </label>
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='enrollment_form_groups' />
                            </div>
                          </div>

                          <div className='col-lg-6 mb-5'>
                            <div
                              style={{
                                minHeight: '150px' /* Adjust the value based on your content */,
                                marginBottom: '20px',
                              }}
                            >
                              {/* characters */}

                              {characters.map((item: any) => {
                                const isActive = values.enrollment_form_groups.includes(item.id)
                                const isNum = values.enrollment_form_groups.findIndex(
                                  (val: number) => val === item.id
                                )
                                if (item.client_id === values.product_detail.client_id) {
                                  return (
                                    <div
                                      key={item.id}
                                      className={`d-flex justify-content-between align-items-center px-6 my-2 rounded border border-dark cursor-pointer`}
                                      onClick={() => enrollmentFormGroupSelected(item.id)}
                                    >
                                      <Field
                                        type='checkbox'
                                        name={`enrollment_form_groups`}
                                        value={item.id}
                                        className='d-none'
                                      />
                                      <label htmlFor={`enrollment_form_groups`} className='py-4'>
                                        {item.name}
                                      </label>
                                      {isActive && (
                                        <div
                                          style={{
                                            fontWeight: 'bolder',
                                            fontSize: 18,
                                            width: 40,
                                            height: 40,
                                            borderRadius: 100,
                                            background: '#009ef7',
                                            color: 'white',
                                            display: 'grid',
                                            placeItems: 'center',
                                          }}
                                        >
                                          {isNum + 1}
                                        </div>
                                      )}
                                    </div>
                                  )
                                }
                                return ''
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-7'>
                <div className='card-body'>
                  <div className='accordion accordion-icon-toggle' id='kt_accordion'>
                    <div className='rounded'>
                      <div
                        className='accordion-header rounded ps-5 bg-light py-4 d-flex'
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_accordion_item_4`}
                      >
                        <span className='accordion-icon'>
                          <KTSVG
                            className='svg-icon svg-icon-4'
                            path='/media/icons/duotune/arrows/arr064.svg'
                          />
                        </span>
                        <h3 className='fs-3 text-gray-800 fw-bold mb-0 ms-4'>Program Session</h3>
                      </div>
                      <div
                        id={`kt_accordion_item_4`}
                        className={'fs-6 collapse ps-10 mt-7 show'}
                        data-bs-parent={`#kt_accordion_item_4`}
                        style={{transition: 'all 0.2s ease'}}
                      >
                        <div className='row'>
                          <div className='col-lg-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Program Session
                            </label>
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='enrollment_form_groups' />
                            </div>
                          </div>

                          <div className='col-lg-6 mb-5'>
                            <div
                              style={{
                                minHeight: '150px',
                                marginBottom: '20px',
                              }}
                            >
                              <AsyncSelect
                                isMulti
                                loadOptions={loadOptions}
                                inputId='program_sessions'
                                name='program_sessions'
                                placeholder='Program Sessions'
                                isClearable
                                onChange={handleSelectChange}
                                onInputChange={(inputValue) => setInputValue(inputValue)}
                                value={
                                  values.program_sessions?.map((id: any) => {
                                    const session = sessions.find(
                                      (session: any) => session.id === id
                                    )
                                    return {
                                      value: id,
                                      label: session
                                        ? `${session.name} - (${session.start_date} - ${session.end_date})`
                                        : '',
                                    }
                                  }) || []
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      {itemForEditPrice && (
        <PriceModal
          header='Tuition Edit'
          body={PriceBody}
          setItem={setItemForEditPrice}
          isLoading={isLoading}
          onSubmitMethod={onPriceSubmit}
        />
      )}
    </>
  )
}

export default AddUpdateProduct
