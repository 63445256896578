import {Dispatch, SetStateAction, useState, useEffect} from 'react'
import {UseMutateAsyncFunction, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {IPaymentSchedule} from '../../../application/core/_models'
import {IPortalClient, IStatus, useAuth} from '../../../auth'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import _queryKeys from './core/_queryKeys'
import {initClient, clientSchema} from './core/_models'
import DatePicker from 'react-datepicker'
import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import _ from 'lodash'
import moment from 'moment'
import {useQueryRequest} from '../../../Shared/core/QueryRequestProvider'

interface Props {
  itemId?: string
  isLoading: boolean
  setMessage: Dispatch<SetStateAction<string>>
  updateClientByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
  addClientByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
}
const AddUpdateClient = ({
  itemId,
  isLoading,
  setMessage,
  updateClientByAdminAsync,
  addClientByAdminAsync,
}: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()
  const [query] = useState<string>(stringifyRequestQuery(state))
  const {currentUser} = useAuth()
  const psDetail = queryClient.getQueryData<{
    data: IPortalClient
  }>(_queryKeys.getClientDetailReq)
  const status = queryClient.getQueryData<{data: IStatus[]}>('getStatus')

  const [initDataForEdit, setInitDataForEdit] = useState<IPortalClient>(initClient)
  //create init formik

  useEffect(() => {
    let dataForEdit = {...initClient}
    if (itemId) {
      dataForEdit.name = psDetail?.data.name || initClient.name
      dataForEdit.abbreviation = psDetail?.data.abbreviation || initClient.abbreviation
      dataForEdit.reference_id = psDetail?.data.reference_id || initClient.reference_id
      dataForEdit.status_id = psDetail?.data.status_id || initClient.status_id
      setInitDataForEdit(dataForEdit)
    } else {
      initClient.reference_id = Math.random().toString().slice(2, 10).toString()
      setInitDataForEdit(initClient)
    }

    return () => {
      setInitDataForEdit(initClient)
    }
  }, [psDetail])

  const onSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      if (itemId && updateClientByAdminAsync) {
        console.log('updateClient', values)
        values.id = psDetail?.data.id
        delete values.value
        const res = await updateClientByAdminAsync(values)
        if (res) {
          setMessage('Client has been updated successfully!')
        }
      } else {
        console.log('addPS', values)
        delete values.value
        if (!addClientByAdminAsync) return
        const res = await addClientByAdminAsync(values)
        if (res) {
          setMessage('New client has been added successfully!')
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      queryClient.prefetchQuery(`${QUERIES.CLIENTS_LIST}-${query}`)
      queryClient.prefetchQuery(`getPrograms`)
      actions.setSubmitting(true)
    }
  }

  return (
    <>
      <Formik
        validationSchema={clientSchema}
        initialValues={initDataForEdit}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({values, errors, setFieldValue}) => {

          return (
            <Form>
              <div className='d-flex my-5'>
                {/* begin::Actions */}
                <div className='text-center'>
                  <button
                    type='reset'
                    onClick={() => {
                      navigate(`/apps/${currentUser?.currentClient}/clients-management/clients`, {
                        replace: true,
                      })
                    }}
                    className='btn btn-secondary me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={isLoading}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isLoading}
                  >
                    {!isLoading && <span className='indicator-label'>Submit</span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              <div className='card mb-7'>
                <div className='card-header'>
                  <h3 className='card-title'>Client</h3>
                </div>
                <div className='card-body'>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>Name</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field type='text' className='form-control form-control-md' name='name' />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='name' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Abbreviation
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        className='form-control form-control-md'
                        name='abbreviation'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='abbreviation' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Reference Id
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        className='form-control form-control-md'
                        name='reference_id'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Status
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field as='select' name='status_id' className='form-select form-select-md'>
                        <option value=''>Select Status</option>
                        {status?.data.map((s, i) =>
                          s.type === 'general' ? (
                            <option value={s.id} key={i}>
                              {s.name}
                            </option>
                          ) : null
                        )}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='status_id' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default AddUpdateClient
