import { Field, ErrorMessage } from 'formik'
import { COUNTRIES } from '../../../../../../utils'
import { User } from '../../../../apps/user-management/users-list/core/_models'
import { IProgram } from '../../../core/_models'

interface Props {
  currentFormikVal?: IProgram
  opt: boolean
}
const ParentInfo = ({ currentFormikVal, opt }: Props) => {
  return (
    <>
      <div className='mb-7'>
        <h4 className='fw-bold'>{opt ? 'Secondary' : 'Primary'} Parent/Guardian Information </h4>
        <span className='opacity-0'>note</span>
      </div>
      <div className='fv-row row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 required'>First Name</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={opt ? 'contactInfo.secondParentInfo.firstName' : 'contactInfo.parentInfo.firstName'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage
            name={
              opt ? 'contactInfo.secondParentInfo.firstName' : 'contactInfo.parentInfo.firstName'
            }
          />
        </div>
      </div>
      <div className='fv-row row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Last Name</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={opt ? 'contactInfo.secondParentInfo.lastName' : 'contactInfo.parentInfo.lastName'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage
            name={opt ? 'contactInfo.secondParentInfo.lastName' : 'contactInfo.parentInfo.lastName'}
          />
        </div>
      </div>
      <div className='fv-row row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Country</label>
        <Field
          as='select'
          name={opt ? 'contactInfo.secondParentInfo.country' : 'contactInfo.parentInfo.country'}
          className='form-select form-select-lg form-select-solid'
        >
          <option>Select Country</option>
          {COUNTRIES.map((c, idx) => {
            return (
              <option value={c.code} key={idx}>
                {c.name + " (" + c.mobileCode + ")"}
              </option>
            )
          })}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage
            name={opt ? 'contactInfo.secondParentInfo.country' : 'contactInfo.parentInfo.country'}
          />
        </div>
      </div>
      <div className='fv-row row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
        <Field
          type='string'
          className='form-control form-control-lg form-control-solid'
          name={
            opt ? 'contactInfo.secondParentInfo.phoneNumber' : 'contactInfo.parentInfo.phoneNumber'
          }
        />
        <div className='text-danger mt-2'>
          <ErrorMessage
            name={
              opt
                ? 'contactInfo.secondParentInfo.phoneNumber'
                : 'contactInfo.parentInfo.phoneNumber'
            }
          />
        </div>
      </div>
      <div className='fv-row row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 required'>
          Parent/Guardian Email Address
        </label>
        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name={opt ? 'contactInfo.secondParentInfo.email' : 'contactInfo.parentInfo.email'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage
            name={opt ? 'contactInfo.secondParentInfo.email' : 'contactInfo.parentInfo.email'}
          />
        </div>
      </div>
      <div className='fv-row row mb-7'>
        <span>
          The parent or guardian's email address must be different from the student's email address. Both must
          be valid since you will receive important communications related to your program at this
          address.
        </span>
      </div>
    </>
  )
}

export default ParentInfo
