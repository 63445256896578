import {useEffect, useState, useMemo} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'
import {useQueryRequest} from '../../Shared/core/QueryRequestProvider'
import AddUpdateClient from './components/AddUpdateClient'
import _queryKeys from './components/core/_queryKeys'
import {useClientsRequests} from './Hooks/useClientsRequests'

const AddClientPage = () => {
  const [message, setMessage] = useState('')
  const {clientId} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {addClientDetailReq} = useClientsRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isLoading: isAddClientLoading,
    isSuccess: isAddClientSuccess,
    isError: isAddClientError,
    error,
    mutateAsync: addClientByAdminAsync,
  } = useMutation(addClientDetailReq)

  return (
    <>
      {isAddClientSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {isAddClientError && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>Some error occurred while processing your request!</span>
            </div>
          </div>
        </div>
      )}
      <AddUpdateClient
        isLoading={isAddClientLoading}
        setMessage={setMessage}
        itemId={clientId}
        addClientByAdminAsync={addClientByAdminAsync}
      />
    </>
  )
}

export default AddClientPage
