import { useEffect, useMemo, useState } from 'react'
import DynamicListHeader from '../../../../_metronic/shared-components/dynamic-list-header'
import DynamicTable from '../../../../_metronic/shared-components/dynamic-table'
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination'
import { KTSVG } from '../../../../_metronic/helpers'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { useAuth } from '../../auth'
import { deleteProgramSessionById, getAllProgramSessions } from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import LoadingGIF from '../../../../assets/Logo/loading.gif'


export interface iProgramSession {
  name: string
  year: string
  start_date: string
  end_date: string
  capacity: string
  status: string | boolean
  active_days: string
  required_extra_id: number | null
}

const ProgramSessionList = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  // STATES
  const [list, setList] = useState<iProgramSession[]>([])
  const [successDelete, setSuccessDelete] = useState({
    data: false,
    message: '' || null
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [refresh, setRefresh] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [search, setSearch] = useState('')

  const editFlightHandler = (e: any, id: any) => {
    navigate(
      `/apps/${currentUser?.currentClient}/program-session-management/edit-program-session/${id}`
    )
  }

  const handleDeleteDocument = (e: any, id: number) => {
    deleteSession(id)
  }

  const deleteSession = async (id: number) => {
    setLoading(true)
    try {
      const response = await deleteProgramSessionById(id);

      if (response) {
        setSuccessDelete({
          data: response.data,
          message: response.message,
        });

        setTimeout(() => {
          setSuccessDelete({
            data: false,
            message: null,
          });
        }, 3000)
      }

      setRefresh(Math.random())
    } catch (error: any) {

      if (error.response.status === 500) {
        return setErrorMessage(
          'There is no opportunity with provided student and product info. Please try again.'
        )
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 401) {
        return setErrorMessage('Unauthorized please login.')
      } else setErrorMessage('Something went wrong, please try again.')

      setTimeout(() => {
        setErrorMessage('');
      }, 5000)

    } finally {
      setLoading(false)
    }
  }

  const handleOpenAction = (id: any) => {
    navigate(`/apps/${currentUser?.currentClient}/program-session-management/program-session`)
  }

  useEffect(() => {
    setLoading(true)
    const AllProgramSessions = async () => {
      const { data } = await getAllProgramSessions()
      data.sort((a: any, b: any) => b.id - a.id)
      setList(data)
      setLoading(false)
    }

    AllProgramSessions()
  }, [refresh])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Date',
        accessor: 'start_date',
        Cell: (props: any) => {
          const start_date = props.row.original.start_date
          const end_date = props.row.original.end_date
          return moment(start_date).format('M/DD/YYYY') + " - " + moment(end_date).format('M/DD/YYYY') || ''
        }
      },
      {
        Header: 'Enrolled/Capacity',
        accessor: 'capacity',
        Cell: (props: any) => {
          const capacity = props.row.original.capacity;
          const enrollee = props.row.original.enrollee_count;
          return `${enrollee}/${capacity}`;
        }
      },
      {
        Header: 'Active Days',
        accessor: 'active_days',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: any) => (
          <div
            style={{
              backgroundColor: value === 'active' ? '#e8fff3' : '#ffe8e8',
              color: value === 'active' ? '#50cd89' : '#cd5050',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              minWidth: value === 'active' ? 60 : 70,
              maxWidth: value === 'active' ? 60 : 70,
            }}
          >
            {value === 'active' ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: any) => (
          <>
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.value)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={(e) => {
                    editFlightHandler(e, props.value)
                  }}
                >
                  Edit
                </a>
              </div>

              <div className='menu-item px-3'>
                <a
                  onClick={(e) => { handleDeleteDocument(e, props.value) }}
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                >
                  Delete
                </a>
              </div>
            </div>
          </>
        ),
      },
    ],
    [list]
  )

  const [visibleData, setVisibleData] = useState<any[]>([])
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    const filteredList = search.length !== 0 
    ? list.filter(item => item.name?.toLowerCase().includes(search.toLowerCase())) 
    : list

    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    setVisibleData(filteredList.slice(startIndex, endIndex))
  }, [list, currentPage, search])

  const Filtered = Math.ceil(list.filter(item => item.name?.toLowerCase().includes(search.toLocaleLowerCase()))?.length / 10)

  return (
    <>
      {successDelete.data && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successDelete.message}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

    <div className='card card-body p-0'>
      <Header
        buttonLink={`/apps/${currentUser?.currentClient}/program-session-management/add-program-session`}
        buttonText='Add Program Session'
        placeholder='Search Program Session'
        setSearch={setSearch}
        search={search}
      />
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt='LOADING...'/> 
          </div>
        )}
      </div>

      {(!loading && visibleData.length !== 0) && (
        <CustomPagination
        currentPage={currentPage}
        totalPages={Filtered}
        onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default ProgramSessionList
