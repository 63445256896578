import React, { useEffect, useMemo, useState } from 'react'
import Header from '../../../../_metronic/shared-components/Header'
import { Link, useParams } from 'react-router-dom'
import LoadingGIF from '../../../../assets/Logo/loading.gif'
import { axiosPrivate } from '../../../../axiosInstance'
import DynamicTable from '../../../../_metronic/shared-components/dynamic-table'
import { currencyFormat } from '../../../../utils/funcHelpers'
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination'

export interface IDiscounts {
  id?: number
  name?: string
  type?: string
  due?: number | null
  amount?: number | null
  qb_name?: string | null
  division?: string | null
  is_active?: boolean
  scholarship_code: string | null
  sf_id?: string | null
  pb_id?: string | null
}

const Discount = () => {
  const {client} = useParams()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [discounts, setDiscounts] = useState<IDiscounts[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleData, setVisibleData] = useState<IDiscounts[]>([])

  console.log(visibleData, 'qweqweqwe')
  
  useEffect(() => {
    setLoading(true)
    const getAllDiscount = async () => {
      const response = await axiosPrivate.get('/get_discounts')
      const sortedDiscount = response.data.sort((a: any, b: any) => b.id - a.id)
      setDiscounts(sortedDiscount)
      setLoading(false)
    }
    getAllDiscount()
  }, [])
  
  useEffect(() => {
    const filterDiscounts = search.length !== 0 
    ? discounts.filter(item => item.name?.toLowerCase().includes(search.toLowerCase())) 
    : discounts

    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    setVisibleData(filterDiscounts?.slice(startIndex, endIndex))
  }, [discounts, currentPage, search])

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => {
        const id = props.cell.row.original.id
        return (
          <Link to={`/apps/${client}/discount-management/edit/${id}`}>{props.cell.row.original.name}</Link>
        )
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Due',
      accessor: 'due',
      Cell: ({value}: any) => currencyFormat.format(value)
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({value}: any) => currencyFormat.format(value)
    },
    {
      Header: 'QB Name',
      accessor: 'qb_name',
    },
    {
      Header: 'COA',
      accessor: 'coa',
    },
    {
      Header: 'Division',
      accessor: 'division',
    },
    {
      Header: 'Code',
      accessor: 'scholarship_code',
    },
    {
      Header: 'Active',
      accessor: 'is_active',
      Cell: ({value}: any) => (
        <div
          style={{
            backgroundColor: value === true ? '#e8fff3' : '#ffe8e8',
            color: value === true ? '#50cd89' : '#cd5050',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            minWidth: value === true ? 60 : 70,
            maxWidth: value === true ? 60 : 70,
          }}
        >
          {value === true ? 'Active' : 'Inactive'}
        </div>
      ),
    }
  ],[])
  
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const Filtered = Math.ceil(discounts.filter(item => item.name?.toLowerCase().includes(search.toLocaleLowerCase()))?.length / 10)

  return (
    <>
      <div className='card card-body p-0'>
        <Header
          buttonLink={`/apps/${client}/discount-management/add`}
          buttonText='Add Discount'
          placeholder='Search Discount'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt='LOADING...'/> 
          </div>
        )}
      </div>
      {(!loading && visibleData.length !== 0) && (
      <CustomPagination
        currentPage={currentPage}
        totalPages={Filtered}
        onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default Discount