import { useEffect } from 'react'
import {useParams, useLocation, Navigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import { useAuth } from '../auth'
import ConfirmPayBanner from './components/ConfirmPay/ConfirmPayBanner'
import ConfirmPayTableCustom from './components/ConfirmPay/ConfirmPayTableCustom'
import PayOptCustom from './components/ConfirmPay/PayOptCustom'
import GLALayout from './components/GLALayout'
import _queryKeys from './core/_queryKeys'
import { IEnrollmentObject } from './core/_models'
import useAppRequests from './Hooks/useAppRequests'

const CustomPayment = () => {
  const {currentUser} = useAuth();
  const {getPaymentSummaryReq} = useAppRequests()
  const { client, opportunity } = useParams()

  console.log(client + " + " + opportunity)
  const currentOpportunity= Number(window.location.pathname.split('/')[4]) || -1
  const {
    isLoading,
    isError,
    data: paymentSummary,
    refetch
  } = useQuery(_queryKeys.getRecentPaymentSummariesReq, () => getPaymentSummaryReq(client || '', Number(opportunity) || -1))

  console.log(paymentSummary)
  useEffect(() => {
    refetch()
    return () => {
    }
  }, [currentUser])

  // if (paymentSummary?.opp_status.name.includes('Deposit Due') == false) {
  //   return <Navigate to={`/dashboard/${client}`} />
  // }

  const paymentContainer = !isError ? (
    <>
      <ConfirmPayTableCustom />
      <PayOptCustom />
    </>
  ) : (
    <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
      <div className='d-flex flex-column'>
        <h5 className='mb-1'>Oops!</h5>
        <span>Something went wrong!</span>
      </div>
    </div>
  )

  return (
    <GLALayout>
      <>
        <ConfirmPayBanner title='Pay The Remaining Amount' />
        <div className='container'>
          {isLoading ? (
            <div className='w-100 my-10'>
              <div className='d-flex justify-content-center align-items-center'>
                <span className='me-5'>Please wait...</span>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            paymentContainer
          )}
        </div>
      </>
    </GLALayout>
  )
}

export default CustomPayment
