import {KTSVG} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {UseMutateAsyncFunction, useQueryClient} from 'react-query'
import _queryKeys from './core/_queryKeys'
import {IFormResponseTab, IOpportunity, IOtherTab, responsesSchema} from './core/_models'
import _ from 'lodash'
import FilterField from '../../../application/components/Shared/FilterField'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {Dispatch, SetStateAction, useState} from 'react'
import {useAuth} from '../../../auth'
import {createLinkText, currencyFormat} from '../../../../../utils/funcHelpers'
import DatePickerField from '../../../Shared/components/DatePickerField'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import moment from 'moment'
import HtmlParser from 'react-html-parser'
import { ScrollTopComponent } from '../../../../../_metronic/assets/ts/components'

const initOpps = {
  opportunity_detail: {
    id: '',
    name: '',
    account: '',
    end_date: '',
    division: null,
    is_sponsored: null,
  },
} as {[key: string]: any}

interface Props {
  oppDetail?: IOpportunity | undefined
  isLoading: boolean
  setMessage: Dispatch<SetStateAction<string>>
  updateOpportunityByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
}

const AddUpdateOpportunity = ({
  oppDetail,
  setMessage,
  updateOpportunityByAdminAsync,
  isLoading,
}: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()

  const formResponses = queryClient.getQueryData<{data: IFormResponseTab[]; other_tabs: IOtherTab}>(
    oppDetail ? _queryKeys.getAllFormResponsesReq : _queryKeys.getOpportunityFieldsReq
  )

  //create init formik
  if (oppDetail) {
    //for edit
    formResponses?.data.forEach((tab) => {
      initOpps[tab.group] = {}
      tab.form_data.forEach((val) => {
        initOpps[tab.group][val.name] = val.answer || ''
      })
    })
  } else {
    //for add
    formResponses?.data.forEach((tab) => {
      initOpps[tab.group] = {}
      tab.form_data.forEach((val) => {
        initOpps[tab.group][val.name] = ''
      })
    })
  }

  initOpps.opportunity_detail = {
    id: oppDetail?.id || '',
    name: oppDetail?.name || '',
    account: oppDetail?.account || '',
    end_date: oppDetail?.end_date || '',
    division: oppDetail?.division || null,
    is_sponsored: oppDetail?.is_sponsored || null,
  }

  const [dataForEdit] = useState<{[key: string]: any}>(initOpps)

  const onSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      if (oppDetail && updateOpportunityByAdminAsync) {
        console.log('updateOpp', values)
        const res = await updateOpportunityByAdminAsync(values)
        if (res) {
          setMessage('Opportunity has been updated successfully!')
        }
      } else {
        console.log('addOpp', values)
        // if (!createUserByAdmin) return

        // const res = await createUserByAdmin(values)
        // console.log('res', res)
        // if (res) {
        //   setMessage('User has been added successfully!')
        // }
      }
    } catch (err) {
      console.error(err)
    } finally {
      actions.setSubmitting(true)
      ScrollTopComponent.goTop()
    }
  }

  console.log('initOpps', initOpps)
  console.log('oppDetail', oppDetail)

  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ]

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(options)
      }, 1000)
    })

  const OpportunityDetail = ({values, setFieldValue}: any) => (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='row align-items-center mb-5'>
          <div className='col-lg-6 text-end'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Division</label>
            {/* end::Label */}
          </div>
          <div className='col-lg-6'>
            {/* begin::Input */}
            <Field
              type='text'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              name='opportunity_detail.divison'
            />
            {/* end::Input */}
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='opportunity_detail.divison' />
            </div>
            {/* end::Input */}
          </div>
        </div>
        <div className='row align-items-center mb-5'>
          <div className='col-lg-6 text-end'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Opportunity Name</label>
            {/* end::Label */}
          </div>
          <div className='col-lg-6'>
            {/* begin::Input */}
            <Field
              type='text'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              name='opportunity_detail.name'
            />
            {/* end::Input */}
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='opportunity_detail.name' />
            </div>
            {/* end::Input */}
          </div>
        </div>
        {/* <div className='row align-items-center mb-5'>
          <div className='col-lg-6 text-end'>
            <label className='required fw-bold fs-6 mb-2'>Account Name</label>
          </div>
          <div className='col-lg-6'>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={values.opportunity_detail.account_name}
              onChange={(option) => setFieldValue('opportunity_detail.account_name', option)}
              loadOptions={promiseOptions}
            />
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='opportunity_detail.account_name' />
            </div>
          </div>
        </div> */}
      </div>
      <div className='col-lg-6'>
        <div className='row align-items-center mb-5'>
          <div className='col-lg-6 text-end'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Close Date</label>
            {/* end::Label */}
          </div>
          <div className='col-lg-6'>
            {/* begin::Input */}
            <DatePickerField
              className='form-control form-control-lg form-control-solid'
              name='opportunity_detail.end_date'
            />
            {/* end::Input */}
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='opportunity_detail.end_date' />
            </div>
            {/* end::Input */}
          </div>
        </div>
        <div className='row align-items-center mb-5'>
          <div className='col-lg-6 text-end'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Sponsored</label>
            {/* end::Label */}
          </div>
          <div className='col-lg-6'>
            {/* begin::Input */}
            <Field
              type='checkbox'
              className='form-check-input'
              name='opportunity_detail.is_sponsored'
            />
            {/* end::Input */}
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='opportunity_detail.is_sponsored' />
            </div>
            {/* end::Input */}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Formik validationSchema={responsesSchema} initialValues={dataForEdit} onSubmit={onSubmit}>
      {({values, errors, setFieldValue, isSubmitting}) => {
        return (
          <Form>
            <div className='d-flex my-5 p-5 bg-white sticky-top' style={{top: '120px'}}>
              {/* begin::Actions */}
              <div className='text-center'>
                <button
                  type='reset'
                  onClick={() => {
                    navigate(
                      `/apps/${currentUser?.currentClient}/opportunities-management/opportunities`,
                      {replace: true}
                    )
                  }}
                  className='btn btn-secondary me-3'
                  data-kt-users-modal-action='cancel'
                  disabled={isLoading}
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={isLoading}
                >
                  {!isLoading && <span className='indicator-label'>Submit</span>}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Actions */}
            </div>
            <div className='card mb-7'>
              <div className='card-header'>
                <h3 className='card-title'>Opportunity Detail</h3>
              </div>
              <div className='card-body'>
                <OpportunityDetail values={values} setFieldValue={setFieldValue} />
              </div>
            </div>
            <div className='card mb-7'>
              <div className='card-body'>
                <div className='accordion accordion-icon-toggle' id='kt_accordion'>
                  {formResponses?.data.map((tab, idx) => {
                    return (
                      <div className='mb-5 rounded' key={idx}>
                        <div
                          className='accordion-header rounded ps-5 bg-light py-4 d-flex collapsed'
                          data-bs-toggle='collapse'
                          data-bs-target={`#kt_accordion_item_${idx}`}
                        >
                          <span className='accordion-icon'>
                            <KTSVG
                              className='svg-icon svg-icon-4'
                              path='/media/icons/duotune/arrows/arr064.svg'
                            />
                          </span>
                          <h3 className='fs-3 text-gray-800 fw-bold mb-0 ms-4'>{tab.title}</h3>
                        </div>
                        <div
                          id={`kt_accordion_item_${idx}`}
                          className='fs-6 collapse ps-10 mt-7'
                          data-bs-parent={`#kt_accordion_item_${idx}`}
                        >
                          {tab.form_data.map((form, id) => {
                            if (form.field_type === 'option_object' && tab?.choicesObject) {
                              form.choicesObject = tab?.choicesObject
                            }
                            return (
                              <div className='row' key={id}>
                                {form.type === 'question' && (
                                  <>
                                    <div className='col-lg-6 mb-5' key={id}>
                                      <label
                                        className={'form-label fw-bolder text-dark fs-6 required'}
                                      >
                                        {form.label}
                                      </label>
                                      <div className='fw-bold text-dark mb-2'>
                                        <span>{form.helper_text}</span>
                                      </div>
                                    </div>
                                    <div className='col-lg-6 mb-5'>
                                      <FilterField form={form} prefixFieldName={tab.group + '.'} />
                                      <div className='text-danger mt-2'>
                                        <ErrorMessage name={tab.group + '.' + form.name} />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {oppDetail ? (
              <div className='card mb-7'>
                <div className='card-header'>
                  <h3 className='card-title'>Products (Standard Price Book)</h3>
                </div>
                <div className='card-body'>
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted bg-light'>
                          <th className='ps-4 rounded-start'>Action</th>
                          <th>Product</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Sales Price</th>
                          <th>Due Upon Application</th>
                          <th className='rounded-end'>Total Price</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody className='fs-5'>
                        {formResponses?.other_tabs.enrolled_products?.map((p) => (
                          <tr key={p.id}>
                            <td></td>
                            <td>
                              <span className='text-dark d-block'>{p.name}</span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>{p.start_date}</span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>{p.end_date}</span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>
                                {currencyFormat.format(p.tuition)}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>
                                {
                                  <span className='text-dark d-block'>
                                    {currencyFormat.format(p.due_upon_application)}
                                  </span>
                                }
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td>Non-refundable Application Fee</td>
                          <td></td>
                          <td></td>
                          <td>{currencyFormat.format(100)}</td>
                          <td>{currencyFormat.format(100)}</td>
                          <td>{currencyFormat.format(100)}</td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
              </div>
            ) : null}
            {oppDetail ? (
              <div className='card mb-7'>
                <div className='card-header'>
                  <h3 className='card-title'>Documents</h3>
                </div>
                <div className='card-body'>
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted bg-light'>
                          <th className='ps-4 rounded-start'>Action</th>
                          <th>Document Name</th>
                          <th>Document Type</th>
                          <th>View</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody className='fs-5'>
                        {formResponses?.other_tabs?.documents.map((d) => (
                          <tr key={d.id}>
                            <td></td>
                            <td>
                              <span className='text-dark d-block'>{d.original_name}</span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>{d.type}</span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>
                                {HtmlParser(createLinkText('View File', d.view || '/'))}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark d-block'>
                                {moment(d.created_at).format('MM/DD/YYYY')}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
              </div>
            ) : null}
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddUpdateOpportunity
