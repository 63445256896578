import {Field, ErrorMessage} from 'formik'
import { useState } from 'react'
import { COUNTRIES } from '../../../../../../utils'
import AddressInfoStudentField from './AddressInfoStudentField'
import AddressInfoParentField from './AddressInfoParentField'
import { IProgram } from '../../../core/_models'
interface Props{
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}
const AddressInfo = ({currentFormikVal, setFieldValue}: Props) => {
  const [sameAddress, setSameAddress] = useState(true)
  const [country, setCountryValue] = useState('')

  return (
    <div className='row mt-15'>
      <div className='col-lg-6 pe-5'>
        <div className='mb-11'>
          <h4 className='fw-bold'>Student's Address</h4>
          <span className='opacity-0'>note</span>
        </div>

        <AddressInfoStudentField
          sameAddressOpt={sameAddress}
          currentFormikVal={currentFormikVal}
          setFieldValue={setFieldValue}
          country={country}
          setCountryValue={setCountryValue}
        />
      </div>
      <div className='col-lg-6 ps-5'>
        <div className='mb-7'>
          <h4 className='fw-bold'>Parent/Guardian Address</h4>
          <div className='form-check form-check-custom form-check-solid my-5'>
            <input
              className='form-check-input'
              type='checkbox'
              id='sameAddress'
              checked={sameAddress}
              onChange={() => setSameAddress(!sameAddress)}
            />
            <label className='form-check-label fw-bold' htmlFor='sameAddress'>
              Same as Student's Address
            </label>
          </div>
        </div>
        <AddressInfoParentField sameAddressOpt={sameAddress} currentFormikVal={currentFormikVal} country={country}
          setCountryValue={setCountryValue} setFieldValue={setFieldValue}/>
      </div>
    </div>
  )
}

export default AddressInfo
