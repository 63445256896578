import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import UsersProvider from './../user-management/users-list/UsersProvider'

// Document List Components

import {useAuth} from '../../../../app/modules/auth'
import ProgramSessionList from './ProgramSessionList'
import ProgramSesssionAdd from './ProgramSesssionAdd'
import ProgramSessionEdit from './ProgramSessionEdit'

const ProgramSession = () => {
  const {currentUser} = useAuth()

  const program_session_breadcrumbs: Array<PageLink> = [
    {
      title: 'Program Session List',
      path: `/apps/${currentUser?.currentClient}/program-session-management/program-session`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        {/* Flight List */}
        <Route
          path='program-session'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Program Session list</PageTitle>
              <ProgramSessionList />
            </>
          }
        />
        <Route
          path='add-program-session'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Add Program Session</PageTitle>
              <ProgramSesssionAdd />
            </>
          }
        />
        <Route
          path='edit-program-session/:program_session_id'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Edit Program Session</PageTitle>
              <ProgramSessionEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/apps/${currentUser?.currentClient}/program-session-management/program-session`}
          />
        }
      />
    </Routes>
  )
}

export default ProgramSession
