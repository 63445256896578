import {useEffect, useMemo, useState} from 'react'
import DynamicTable from '../../../../_metronic/shared-components/dynamic-table'
import {useAuth} from '../../auth'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import { fetchAllEnrollmentExtras } from '../../../../network/api'
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination'
import Header from '../../../../_metronic/shared-components/Header'
import LoadingGIF from '../../../../assets/Logo/loading.gif'
import { IEnrollmentExtras } from './model'

const EnrollmentExtrasList = () => {

  // STATES
  const [extras, setExtras] = useState<IEnrollmentExtras[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleData, setVisibleData] = useState<IEnrollmentExtras[]>([])
  const [search, setSearch] = useState('')

  // HOOKS
  const navigate = useNavigate()
  const {client} = useParams()

  // Functions
    
  const editEnrollmentExtras = (e: any, enrollment_extras_id: any) => {
    navigate(
      `/apps/${client}/enrollment-extras-management/edit-enrollment-extras/${enrollment_extras_id}`
    )
  }

  const handleOpenAction = (id: any) => {
    navigate(`/apps/${client}/enrollment-extras-management/enrollment-extras`)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setLoading(true)
    const getAllEnrollmentExtras = async () => {
      const response = await fetchAllEnrollmentExtras()
      setExtras(response)
      setLoading(false)
    }
    getAllEnrollmentExtras()
  }, [])

  useEffect(() => {
    const filterEnrollementExtras = search.length !== 0 
    ? extras.filter(item => item.name?.toLowerCase().includes(search.toLowerCase())) 
    : extras

    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    setVisibleData(filterEnrollementExtras?.slice(startIndex, endIndex))
  }, [extras, currentPage, search])

  const Filtered = Math.ceil(extras.filter(item => item.name?.toLowerCase().includes(search.toLocaleLowerCase()))?.length / 10)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'QB Full Name',
        accessor: 'qb_full',
        Cell: ({value}: any) => {
          return <div>{value || ''}</div>
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        minWidth: 130,
        Cell: ({value}: any) => {
          switch (value) {
            case 3:
              value = 'Application Fee'
              break
            case 6:
              value = 'Protection Plan'
              break
            case 5:
              value = 'Airport Supplements'
              break
            case 4:
              value = 'Commuter Supplement'
              break
            case 2:
              value = 'Tuition'
              break
            case 1:
              value = 'Cancellation fee'
              break
            default:
              value = ''
              break
          }
          return <div>{value}</div>
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Client',
        accessor: 'default_client',
      },
      {
        Header: 'Status',
        accessor: 'status_id',
        Cell: ({value}: any) => (
          <div
            style={{
              backgroundColor: value === 14 ? '#e8fff3' : '#ffe8e8',
              color: value === 14 ? '#50cd89' : '#cd5050',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              minWidth: value === 14 ? 60 : 70,
              maxWidth: value === 14 ? 60 : 70,
            }}
          >
            {value === 14 ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: any) => (
          <>
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.value)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={(e) => {
                    editEnrollmentExtras(e, props.value)
                  }}
                >
                  Edit
                </a>
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='card card-body p-0'>
        <Header
          buttonLink={`/apps/${client}/enrollment-extras-management/add-enrollment-extras`}
          buttonText='Add Enrollment Extras'
          placeholder='Search Enrollment Extras'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt='LOADING...'/> 
          </div>
        )}
      </div>
      {(!loading && visibleData.length !== 0) && (
        <CustomPagination
        currentPage={currentPage}
        totalPages={Filtered}
        onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default EnrollmentExtrasList