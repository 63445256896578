import { useState } from 'react'
import { IEnrollmentSummary } from '../../core/_models'
import { Button, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import CustomInput from '../../../apps/enrollment-form/components/CustomInput'
import { addTuitionPlan } from '../../../../../network/api'

type Props = {
  enrollmentSummary?: IEnrollmentSummary
  refetch: () => void
}

const AddPPP = ({enrollmentSummary, refetch}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      const data = {
        totalAmount: Number(enrollmentSummary?.remaining_balance),
        opp_id: enrollmentSummary?.opportunity,
        login_id: enrollmentSummary?.login_user,
        opportunity_sf_id: enrollmentSummary?.opp_sf_id
      }
      let addPlan = await addTuitionPlan(data)
      if(addPlan){
        refetch()
        setTimeout(() => {
          setShowModal(false)
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {!enrollmentSummary?.ppp ? <button onClick={() => setShowModal(true)} className='btn btn-primary ms-4'>Add Protection Plan</button> : ''}
      {/* <button className='btn btn-info ms-4'>Edit Protection Plan</button> */}
      <Modal title="Test" onHide={handleClose} show={showModal} backdrop='static' centered keyboard={false} >
        <Modal.Body>
          <h2>Add Tuition Protection Plan for this family?</h2>
        </Modal.Body>

        <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='danger' onClick={handleClose}>
              No
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
            {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Add Tuition Protection Plan'
            }
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddPPP