import { Field, ErrorMessage } from 'formik'
import { COUNTRIES } from '../../../../../utils'
import { IEnrollPartTwo, IEnrollPartTwoForm } from '../../core/_models'
import DatePickerField from '../../../Shared/components/DatePickerField'

interface Props {
  form: IEnrollPartTwoForm
  currentFormikVal?: any
  setFieldValue?: any
}

const FilterField = ({ form, currentFormikVal, setFieldValue}: Props): any => {

  // const inputFieldTextArea = document.querySelector('textarea');
  const inputFields = document.querySelectorAll('textarea');
  inputFields.forEach(inputFieldTextArea => {
    if (inputFieldTextArea) {
      if (inputFieldTextArea.getAttribute('maxLength') == '3000') {
        let inputMaxLength = inputFieldTextArea.getAttribute('maxLength');
        let inputId = inputFieldTextArea.getAttribute('id')
        let errorMessage = document.getElementById(`error.${inputId}`)
        if (inputMaxLength) {
          if (inputFieldTextArea.value.length == 3000) {
            if (errorMessage) {
              errorMessage.innerText = `You have reached the maximum character limit of ${inputMaxLength}`
            }
          } else {
            if (errorMessage) {
              errorMessage.innerText = ''
            }
          }
        }
      }
    }
  })
  const inputFieldsInput = document.querySelectorAll('input');
  inputFieldsInput.forEach(inputFields => {
    if (inputFields) {
      if (inputFields.getAttribute('maxLength') == '255') {
        let inputMaxLength = inputFields.getAttribute('maxLength');
        let inputId = inputFields.getAttribute('id')
        let errorMessage = document.getElementById(`error.${inputId}`)
        if (inputMaxLength) {
          if (inputFields.value.length == 255) {
            if (errorMessage) {
              errorMessage.innerText = `You have reached the maximum character limit of ${inputMaxLength}`
            }
          } else {
            if (errorMessage) {
              errorMessage.innerText = ''
            }
          }
        }
      }
    }
  })
  const field_type = form.field_type === 'choice' ? 'option' : form?.field_type
  switch (field_type) {
    case 'text':
      return (
        <>
          <Field
            type='text'
            className='form-control form-control-lg form-control-white'
            name={'customField.' + form.name}
            id={'customField.' + form.name + '_' + form.id}
            required={true}
            maxLength="255"
          />
          <p style={{ color: 'red' }} id={'error.customField.' + form.name + '_' + form.id}></p>
        </>
      )
    case 'textarea':
      return (
        <>
          <Field
            as='textarea'
            rows='5'
            className='form-control form-control-lg form-control-white'
            name={'customField.' + form.name}
            id={'customField.' + form.name + '_' + form.id}
            required={true}
            maxLength="3000"
          />
          <p style={{ color: 'red' }} id={'error.customField.' + form.name + '_' + form.id}></p>
        </>
      )
    case 'option':
      return (
        <Field
          as='select'
          name={'customField.' + form.name}
          className='form-select form-select-lg form-select-white'
          id={'customField.' + form.name + '_' + form.id}
          required={true}
        >
          {form.choices?.map((c, idx) => {
            return c === '---- Select Answer ----' ? (
              <option key={idx} value="">---- Select Answer ----</option>
            ) : (
              <option value={idx} key={idx}>
                {c}
              </option>
            )
          })}
        </Field>
      )
      case 'checkbox':
        return form.choices?.map((c, idx) => {
          const isChecked =
            currentFormikVal.customField &&
            currentFormikVal.customField[form.name]
              ? currentFormikVal.customField[form.name].includes(String(idx))
              : false; // Set isChecked to false if currentFormikVal.customField[form.name] is null or undefined
          console.log(currentFormikVal, '777777');
          return (
            <div className='mb-4' key={idx}>
              <Field
                type='checkbox'
                className='form-check-input me-2'
                name={`customField.${form.name}[${idx}]`}
                value={String(idx)}
                id={`customField.${form.name}_${idx}`}
                checked={isChecked}
                onChange={(e: any) => {
                  const checkedValue = e.target.checked;
                  let updatedValues = [...(currentFormikVal.customField[form.name] || [])];
      
                  if (checkedValue) {
                    // Add the index to the array if checked
                    updatedValues.push(String(idx));
                  } else {
                    // Remove the index from the array if unchecked
                    updatedValues = updatedValues.filter((item) => item !== String(idx));
                  }
      
                  // Update Formik values with the new array of checked indexes
                  setFieldValue(`customField.${form.name}`, updatedValues);
                }}
              />
              <label className='form-check-label' htmlFor={`customField.${form.name}_${idx}`}>
                {c}
              </label>
            </div>
          );
        });
    case 'boolean':
      return (
        <div className='d-flex'>
          <div className='mb-4 me-5'>
            <Field
              type='radio'
              className='form-check-input me-2'
              value='0'
              name={'customField.' + form.name}
              id={'customField.' + form.name + '_' + form.id + '1'}
              required={true}
            />
            <label className='form-check-label' htmlFor={'customField.' + form.name + '_' + form.id + '1'}>
              No
            </label>
          </div>
          <div className='mb-4'>
            <Field
              type='radio'
              className='form-check-input me-2'
              value='1'
              name={'customField.' + form.name}
              id={'customField.' + form.name + '_' + form.id + '2'}
              required={true}
            />
            <label className='form-check-label' htmlFor={'customField.' + form.name + '_' + form.id + '2'}>
              Yes
            </label>
          </div>
        </div>
      )
    case 'choice_country':
      return (
        <Field
          as='select'
          name={'customField.' + form.name}
          className='form-select form-select-lg form-select-white'
          id={'customField.' + form.name + '_' + form.id}
          required={true}
        >
          <option>---- Select Country ----</option>
          {COUNTRIES.map((c, idx) => {
            return (
              <option value={c.code} key={idx}>
                {c.name}
              </option>
            )
          })}
        </Field>
      )
    case 'datepicker':
      return (
        <DatePickerField
          className='form-control form-control-lg'
          name={'customField.' + form.name}
        />
      )
    default:
      return null
  }
}

export default FilterField
