import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import { axiosPrivate } from '../../../../../axiosInstance'
import { IEnrollmentSummary } from '../../core/_models'

interface IProps {
  enrollmentSummary: IEnrollmentSummary
  refetch: any
}

const status = [
  {
    label: 'Incomplete - Deposit Due',
    value: 'Incomplete - Deposit Due',
    status: 2,
  }, {
    label: 'Incomplete - Deposit Paid',
    value: 'Incomplete - Deposit Paid',
    status: 3,
  }, {
    label: 'Applied - Pending',
    value: 'Applied - Pending',
    status: 4,
  }, {
    label: 'Enrolled - Balance Due',
    value: 'Enrolled - Balance Due',
    status: 5,
  }, {
    label: 'Enrolled - Paid',
    value: 'Enrolled - Paid',
    status: 6,
  }, {
    label: 'Ready to Depart',
    value: 'Ready to Depart',
    status: 16,
  }, {
    label: 'Cancelled',
    value: 'Cancelled',
    status: 17,
  }, 
]

const Status = ({ enrollmentSummary, refetch }: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedStatus, setSelectedStatus] = useState<any>(null)

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
    setSelectedStatus({
      label: enrollmentSummary.opp_status.name,
      value: enrollmentSummary.opp_status.name,
      status: enrollmentSummary.opp_status.id
    })
  }

  const onSubmit = async () => {
    try {
      setSuccessMessage('')
      if (!selectedStatus) {
        setErrorMessage('Select scholarship')
        return
      }
      setErrorMessage('')
      setLoading(true)
      await axiosPrivate.post('/user/update_status', {
        ...selectedStatus, 
        oppId: enrollmentSummary.opportunity
      })
      setSuccessMessage(`Successfully updated. Please wait for a moment to allow the changes to be reflected`)
      refetch()
      setTimeout(() => {
        setShowModal(false)
      }, 1000)
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <button className='btn btn-primary ms-4' onClick={handleClose}>Application Status</button>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Application Status</Modal.Title>
          </Modal.Header>

          <div className='fv-row my-7 px-7'>
            <Select
              options={status}
              value={selectedStatus}
              onChange={(e: any) => setSelectedStatus(e)}
              noOptionsMessage={() => 'Select a type first'}
            />
          </div>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}
        
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Change status'}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Status