import {useEffect} from 'react'
import {useMutation} from 'react-query'
import {Link, Navigate, useParams} from 'react-router-dom'
import {IResetToken, IResetTokenData} from '../core/_models'
import {axiosCheckResetPasswordToken, axiosProcessResetPassword} from '../core/_requests'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const initialValues = {
  password: '',
  changepassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

const ResetPassword = () => {
  const {token, hash} = useParams()

  const {
    isLoading,
    isError,
    data,
    error,
    mutate: checkResetPasswordToken,
  } = useMutation<IResetTokenData, unknown, IResetToken, unknown>(axiosCheckResetPasswordToken)
  const {
    isLoading: isUpdatingNewPassword,
    status: updatingNewPasswordStatus,
    mutate: processResetPassword,
  } = useMutation(axiosProcessResetPassword)

  useEffect(() => {
    checkResetPasswordToken({
      hash,
      token,
    }, {
      onError: (error)=>{console.log(error);
      }
    })
  }, [token, hash, checkResetPasswordToken])
console.log('error', error)

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log('resetpassword', values)
      processResetPassword(
        {hash, token, password: values.password},
        {
          onSuccess: (data) => {
            console.log('processResetPassword', data)
          },
          onError: (error) => {
            console.log('processResetPassword', error)
            setStatus('Something went wrong while processing the request!')
          },
        }
      )
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (!token || !hash) {
    return <Navigate to='/login' />
  }

  if (isLoading) {
    return (
      <div>
        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </div>
    )
  }

  if (!data?.user || isError || updatingNewPasswordStatus === 'error') {
    return (
      <div>
        <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span className='fs-5'>
              The reset password URL is invalid or expired! Please submit another password reset
              request.
            </span>
          </div>
        </div>
        <Link to='/auth/forgot-password'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Forgot Password
          </button>
        </Link>
      </div>
    )
  }

  if (updatingNewPasswordStatus === 'success') {
    return (
      <div>
        <div className='alert alert-success d-flex align-items-center p-5'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Your password has been successfully changed!</h5>
            <span className='fs-5'>Please use your new password to sign in</span>
          </div>
        </div>
        <div className=''>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Sign In
            </button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Change Password</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!isUpdatingNewPassword && <span className='indicator-label'>Submit</span>}
          {isUpdatingNewPassword && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default ResetPassword
