import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import { Field, Form, Formik, FormikValues } from 'formik'
import { ProgramSessionSchema } from './Schemas/ValidationSchema'
import DatePickerField from '../../Shared/components/DatePickerField'
import { AddProgramSession } from '../../../../network/api'
import { iProgramSession } from './ProgramSessionList'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosPrivate } from '../../../../axiosInstance'
import { IEnrollmentExtras } from '../enrollment-extras/model'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'

const ProgramSesssionAdd = () => {
  const [extras, setExtras] = useState<IEnrollmentExtras[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const navigate = useNavigate()
  const { client } = useParams()

  const fetchExtras = async () => {
    const res = await axiosPrivate.get('/terra-admin/get_all_enrollment_extras')
    const filterActive = res.data?.filter((item: any) => item.status_id === 14)
    setExtras(filterActive)
  }

  useEffect(() => {
    fetchExtras()
  }, [])

  const initialValues: iProgramSession = {
    name: '',
    year: '',
    start_date: '',
    end_date: '',
    capacity: '',
    active_days: '',
    status: false,
    required_extra_id: null
  }

  const onSubmit = async (values: iProgramSession, actions: FormikValues) => {
    try {
      const modifiedValues = {
        ...values,
        status: values?.status ? 'active' : 'inactive'
      }
      await AddProgramSession(null, modifiedValues)
      setSuccessMessage('Program Session added successfully')

      actions.resetForm()

      setTimeout(() => {
        navigate(`/apps/${client}/program-session-management/program-session`)
      }, 3000)
    } catch (error: any) {
      if (error.response.status === 500) {
        return setErrorMessage('Something went wrong, please try again.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else setErrorMessage('Something went wrong, please try again.')

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  return (
    <>
      {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card'>
            <Formik
              initialValues={initialValues}
              validationSchema={ProgramSessionSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue, errors, touched }) => {

                return (
                  <Form>
                    <CustomInput label='Name' name='name' placeholder='Enter a Name' />

                    <CustomInput label='Year' name='year' placeholder='Enter a year' />

                    <CustomInput label='Capacity' name='capacity' placeholder='Enter capacity' />

                    <CustomInput label='Active days' name='active_days' placeholder='Enter Active Days' />

                    <CustomReactSelect
                      label='Required Extra'
                      name='required_extra_id'
                      required
                      placeholder='Select a Client'
                      options={extras.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />

                    <div className='my-3'>
                      <label
                        className='form-label fw-bolder text-dark fs-6 required'
                        htmlFor='start_date'
                      >
                        Start Date
                      </label>
                      <DatePickerField
                        className='form-control form-control-lg'
                        label='Start Date'
                        name='start_date'
                        placeholder='Select a Start Date'
                      />
                    </div>
                    {errors.start_date && touched.start_date ? (
                      <div className='text-danger'>{errors.start_date}</div>
                    ) : null}

                    <div className='my-3'>
                      <label
                        className='form-label fw-bolder text-dark fs-6 required'
                        htmlFor='end_date'
                      >
                        End Date
                      </label>
                      <DatePickerField
                        className='form-control form-control-lg'
                        label='End Date'
                        name='end_date'
                        placeholder='Select an End Date'
                      />
                    </div>
                    {errors.end_date && touched.end_date ? (
                      <div className='text-danger'>{errors.end_date}</div>
                    ) : null}

                    <div className='d-flex align-items-center'>
                      <label
                        className='form-label fw-bolder text-dark fs-6 required'
                        htmlFor='status'
                      >
                        Active
                      </label>
                      <Field
                        className='form-check-input my-3 mx-10'
                        label='Active'
                        type='checkbox'
                        name='status'
                      />
                    </div>

                    <div className='text-center pt-15'>
                      <button
                        disabled={isSubmitting}
                        type='submit'
                        className='btn btn-primary cursor-pointer'
                        data-kt-users-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>

    </>

  )
}

export default ProgramSesssionAdd
