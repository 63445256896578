import {useEffect} from 'react'
import Nav from './Nav'
type Props = {
  children: JSX.Element
}

const GLALayout = ({children} : Props) => {
  const currentRoute = window.location.pathname.split('/')[2] || ''
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-column flex-column-fluid pb-lg-20 bg-white'>
        {/* begin::Logo */}
        <Nav/>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {children}
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-5 border-top text-gray-700'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {currentRoute === '84984438' && (
            <span>Copyright © 2022 Global Leadership Adventures. All rights reserved.</span>
          )}
          {currentRoute === '47821104' && (
            <span>Copyright © 2023 Summer Springboard. All rights reserved.</span>
          )}
          {currentRoute === '88562775' && (
            <span>Copyright © 2023 Teen Travel Network. All rights reserved.</span>
          )}
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export default GLALayout