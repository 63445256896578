import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { currencyFormat } from '../../../../../utils/funcHelpers'
import { IEnrollmentSummary } from '../../core/_models'
import './style.css'
import DeleteScholarship from './DeleteScholarship'
import EditScholarship from './EditScholarship'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise } from '../../../auth'
import LoadingGIF from '../../../../../assets/Logo/loading.gif'
import EditPlan from './EditPlan'
import { axiosPrivate } from '../../../../../axiosInstance'

interface Props {
  enrolledSummary: IEnrollmentSummary | undefined
  refetch?: () => void
  isFetching?: any
}

const EnrollmentTable = ({ enrolledSummary, refetch, isFetching }: Props) => {
  const {client} = useParams()
  const [hasAdmin, setHasAdmin] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<any>(null)
  const [paymentPlan, setPaymentPlan] = useState<any>(null)
  const payments = enrolledSummary?.payment
  let remaining_balance = 0;
  let paidSoFar = 0

  const fetchOpp = async () => {
    if (enrolledSummary) {
      const res = await axiosPrivate.post('/fetch_opportunity', {opportunity: enrolledSummary.opportunity})
      const parsePaymentPlan = JSON.parse(res.data.payment_plan)      
      if (parsePaymentPlan && parsePaymentPlan.length) {
        let selectCurrentIsNotPaid = false
        for (const curPlan of parsePaymentPlan[parsePaymentPlan.length - 1]) {
          if (curPlan.isPaid || selectCurrentIsNotPaid) {
            continue
          } 
          setCurrentPlan(curPlan)
          selectCurrentIsNotPaid = true
        }
        setPaymentPlan(parsePaymentPlan)
      }
    }
  }

  useEffect(() => {
    const checkAdmin = async () => {
      fetchOpp()
      const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (prevAuth) {
        setHasAdmin(true)
      }
    }
    checkAdmin()
  }, [])

  payments?.forEach((p) => {
    if (p.amount && p.status != 'Declined') {
      paidSoFar += Number(p.amount)
    }
  })

  if (paidSoFar) {
    remaining_balance -= paidSoFar
  }

  let arr = enrolledSummary?.payment_schedule?.due_dates || []
  let lastDiff
  let nearest

  for (const date of arr) {
    const diff = moment(date, 'MM/DD/YYYY').diff(moment())
    if (lastDiff === undefined || lastDiff < 0 || (lastDiff > diff && diff > 0)) {
      lastDiff = diff
      nearest = date
    }
  }
  
  let remaining_payment_count =
    arr.length - arr.indexOf(moment(nearest, 'MM/DD/YYYY').format('MM/DD/YYYY').toString())
  let amount =
    enrolledSummary && enrolledSummary?.remaining_balance
      ? enrolledSummary?.remaining_balance / remaining_payment_count
      : 0
  let next_payment_date = moment(nearest, 'MM/DD/YYYY').format('MMMM DD, YYYY')

  const [expandedPrograms, setExpandedPrograms] = useState<any[]>([])
  const { width } = useWindowSize()
  const isMobile = width <= 768;

  const truncateProgramName = (name: string, maxLength: number, programId: any): string => {
    if (!isMobile) {
      return name
    }

    if (name.length <= maxLength || expandedPrograms.includes(programId)) {
      return name
    }

    return name.substring(0, maxLength) + '...'
  }

  let programSummary: any = {}

  if (enrolledSummary && enrolledSummary.detail) {
    enrolledSummary.detail.forEach(item => {
      remaining_balance += Number(item.price)
      if (item.type === 'Product') {
        programSummary = { ...programSummary, ...item }
      }
    })
  }

  const scholarship_list = enrolledSummary?.detail
    ?.filter(item => (item.type === 'Scholarship' || item.type === 'Discount')) || []

  if (enrolledSummary && enrolledSummary.enrollment_objects.length) {
    programSummary = { ...programSummary, ...enrolledSummary.enrollment_objects[0]}
  }

  const ProgramSessions: any = programSummary?.session?.name.match(/-\s?([^(]*)(?:\s?\([^)]*\))?/) 
  const fullName = `${programSummary.program_summary} ${client === '47821104' && ProgramSessions ? ProgramSessions[1] : ''}`
  const isExpanded = expandedPrograms.includes(programSummary.id);

  let currentDate = ''
  let hasPaymentPlan = paymentPlan ? paymentPlan.length > 0 : 0
  if (currentPlan) {
    const formatedDate = moment(currentPlan.date, 'MM/DD/YYYY').format('MMMM DD, YYYY')
    currentDate = formatedDate
    hasPaymentPlan = paymentPlan.length > 0
  }

  return (
    <div className='card-body p-5 relative'>
      {/* begin::Table container */}
      <div className='table-responsive table-confirm-enrollment'>
        {/* Status */}            
        {(enrolledSummary?.opp_status) ? (
          <p className='text-primary p-0 ps-6 text-center'>
            Application Status:{' '}
            <strong>({enrolledSummary?.opp_status?.name})</strong>
          </p>
        ) : ''}
        {/* begin::Table */}
        <table className='table table-row-dashed align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold bg-light text-muted'>
              <th style={{ fontSize: '16px', paddingLeft: 20, textAlign: 'left' }}>Program</th>
              <th style={{ fontSize: '16px', paddingLeft: 0, textAlign: 'left' }}>Price</th>
              <th style={{ fontSize: '16px', paddingLeft: 0, textAlign: 'left' }}>Deposits</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody className='fs-5'>
            {/* {enrolledSummary?.enrollment_objects?.map((p: any) => {
              const ProgramSessions: any = p?.session?.name.match(/-\s?([^(]*)(?:\s?\([^)]*\))?/) 
              const fullName = `${p.program_summary} ${client === '47821104' && ProgramSessions ? ProgramSessions[1] : ''}`
              const isExpanded = expandedPrograms.includes(p.id);

              return (
                <tr key={p.id}>
                  <td className='p-0'>
                    <p className='fw-bold ps-6 m-0 '
                      onClick={() => {
                        if (isExpanded) {
                          setExpandedPrograms(
                            expandedPrograms.filter((programId) => programId !== p.id)
                          )
                        } else {
                          setExpandedPrograms([...expandedPrograms, p.id])
                        }
                      }}
                    >
                      {truncateProgramName(fullName, isMobile ? 20 : 100, p.id)}{' '}
                      {isMobile && fullName.length > 20 && (
                        <span className={`text-primary ${isExpanded ? 'expanded' : ''}`} >
                          {isExpanded}
                        </span>
                      )}
                    </p>
                  </td>
                  <td className='p-0'>
                    <span className='fw-light'>{currencyFormat.format(p.tuition)}</span>
                  </td>
                  <td className='p-0'>
                    <span className='fw-light'>
                      {
                        <span className='text-dark fw-light d-block'>
                          {currencyFormat.format(p.due_upon_application)}
                        </span>
                      }
                    </span>
                  </td>
                </tr>
              )
            })} */}

            {programSummary ? (
              <tr>
                <td className='p-0'>
                  <p className='fw-bold ps-6 m-0 '
                    onClick={() => {
                      if (isExpanded) {
                        setExpandedPrograms(
                          expandedPrograms.filter((programId) => programId !== programSummary.id)
                        )
                      } else {
                        setExpandedPrograms([...expandedPrograms, programSummary.id])
                      }
                    }}
                  >
                    {truncateProgramName(fullName, isMobile ? 20 : 100, programSummary.id)}{' '}
                    {isMobile && fullName.length > 20 && (
                      <span className={`text-primary ${isExpanded ? 'expanded' : ''}`} >
                        {isExpanded}
                      </span>
                    )}
                  </p>
                </td>
                <td className='p-0'>
                  <span className='fw-light'>{currencyFormat.format(programSummary.price || 0)}</span>
                </td>
                <td className='p-0'>
                  <span className='fw-light'>
                    <span className='text-dark fw-light d-block'>
                      {currencyFormat.format(programSummary.due_upon_application)}
                    </span>
                  </span>
                </td>
              </tr>
            ) : ''}

            {enrolledSummary?.detail?.map((ext) => {
              return ext.type == 'Extra' ? (
                <tr key={ext.id}>
                  <td className='p-0'>
                    <span className='fw-bold ps-6'>{ext.product_name}</span>
                  </td>
                  <td className='p-0'>
                    <span className='fw-light'>{currencyFormat.format(ext.price)}</span>
                  </td>
                  <td className='p-0'>
                    <span className='fw-light'>{ext.category ? currencyFormat.format(ext.price) : ''}</span>
                  </td>
                </tr>
              ) : (
                ''
              )
            })}

            <tr>
              <td className='fw-bold p-0 ps-6'>Paid so far: </td>
              <td className='p-0 fw-light'>
                {`(${currencyFormat.format(paidSoFar)})`}
              </td>
            </tr>
            
            {scholarship_list.length ? (
              <tr>
                <td className='p-0 fw-bold ps-6'>Scholarship & Discount</td>
                <td></td>
                <td></td>
              </tr>
            ) : ''}

            {scholarship_list.length ? scholarship_list?.map((item, ind) => (
              <tr key={ind}>
                <td className='py-0 fw-bold ps-10'>
                  {item?.product_name} 
                  {(item.sf_id && hasAdmin) ? (
                    <>
                      <EditScholarship item={item} refetch={refetch} />
                      <DeleteScholarship item={item} refetch={refetch} />
                    </>
                  ) : ''}
                </td>
                <td className='p-0 fw-light'>{`(${currencyFormat.format(item?.price).toString().substring(1)})`}</td>
                <td className='p-0 fw-light'>{item?.due_upon_application ? `(${currencyFormat.format(item.due_upon_application).toString().substring(1)})` : ''}</td>
              </tr>
            )) : ''}

            <tr>
              <td className='p-0 fw-bold ps-6'>Total Remaining Amount</td>
              <td className='p-0 py-2'>
                {enrolledSummary?.opp_status?.id == 17 ? currencyFormat.format(0) : currencyFormat.format(Number(remaining_balance || 0))}
              </td>
            </tr>
            
            {(enrolledSummary && currentPlan && enrolledSummary?.remaining_balance !== 0) ? (
              <>
              <tr>
                <td className='p-0 ps-6'>
                  Registered to Payment Schedule: &nbsp;{hasAdmin ? <EditPlan opportunity={enrolledSummary.opportunity} /> : ''}
                </td>
                <td className='p-0 py-2'></td>
              </tr>
              <>
                {hasPaymentPlan ? (paymentPlan[paymentPlan.length - 1]
                .filter((plan: any) => !plan.isRemoved)
                .map((plan: any, index: number) => {
                  if(plan.isRemoved){
                    return ''
                  }
                  const currentDate = moment(new Date())
                  const currentPlanDate = moment(new Date(currentPlan.date), 'MM/DD/YYYY')
                  const thisPlanDate = moment(new Date(plan.date), 'MM/DD/YYYY')
                  const isDueToday = thisPlanDate.isSame(currentDate) || currentDate.isAfter(thisPlanDate)
                  const isCurrentPayment = currentPlanDate.isSame(thisPlanDate)
                  const className = plan.isPaid || Number(plan.amount) === 0 ? "text-success" : isDueToday ? "text-danger" : isCurrentPayment || index === 0 ? "text-primary" : ""
                  const zeroAmount = Number(plan.amount) === 0 ? "PAID" : currencyFormat.format(plan.amount)
                  const dueWordHelper = ( isDueToday && !plan.isPaid ) || ( isDueToday && Number(plan.amount) ) != 0 ? "Overdue" : "Due";
                  return (<tr>
                    <td className={`${className} p-0 ps-6`}>
                      <strong>Payment {dueWordHelper} {moment(new Date(plan.date), 'MM/DD/YYYY').format('MMMM DD, YYYY')}</strong>
                    </td>
                  <td className={`${className} p-0`}>{zeroAmount}</td>
                  </tr>)
                  }))
                  : ""
                }
              </>

              </>
            ) : (enrolledSummary?.payment_schedule) ? (
              <>
              <tr>
                <td className='p-0 ps-6'>
                  Registered to Payment Schedule: &nbsp;{hasAdmin ? <EditPlan opportunity={enrolledSummary.opportunity} /> : ''}
                </td>
                <td className='p-0 py-2'></td>
              </tr>
              <>
                {hasPaymentPlan ? (paymentPlan[paymentPlan.length - 1].map((plan: any) => {
                  return (<tr>
                    <td className='text-success p-0 ps-6'>
                      <strong>Payment Due {moment(new Date(plan.date), 'MM/DD/YYYY').format('MMMM DD, YYYY')}</strong>
                    </td>
                  <td className='text-success p-0'>PAID</td>
                  </tr>)
                  }))
                  : ""
                }
              </>

              </>
            ) : ''}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}

      {(isFetching && hasAdmin) ? (
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: '#0000001e',
          display: 'grid',
          placeItems: 'center'
        }}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt='LOADING...'/> 
          </div>
        </div>
      ) : ""}
    </div>
  )
}

export default EnrollmentTable
