/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../_metronic/layout/core'
import {StatisticsWidget2} from '../../../_metronic/partials/widgets'
import useAppRequests from '../../modules/application/Hooks/useAppRequests'
import _queryKeys from '../../modules/application/core/_queryKeys'
import YourPrograms from '../../modules/application/components/Dashboard/YourPrograms'
import NotSelectedProprams from '../../modules/application/components/Dashboard/NotSelectedProprams'

import {ROLES} from '../../routing/core/_models'
import {KTSVG} from '../../../_metronic/helpers'
import ConfirmPayBanner from '../../modules/application/components/ConfirmPay/ConfirmPayBanner'
import useRole from '../../modules/auth/hooks/useRole'
import {useProductRequests} from '../../../app/modules/apps/products-management/Hooks/useProductRequests'
import {QUERIES, stringifyRequestQuery} from '../../../_metronic/helpers'
import {useQueryRequest} from '../../../app/modules/Shared/core/QueryRequestProvider'
import { useParams } from 'react-router-dom'
import { IEnrollmentSummary } from '../../modules/application/core/_models'
const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  const {getAllEnrollmentSummariesReq} = useAppRequests()

  const {
    isLoading,
    isError,
    data: getEnrollments,
  } = useQuery(_queryKeys.getAllEnrollmentSummariesReq, getAllEnrollmentSummariesReq, {
    refetchInterval: 300000,
  })


  const oppLenght: any = getEnrollments?.filter((item) => item.opp_status.id !== 15) || []

  if (isLoading) {
    return (
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  } else {
    // if (isError || oppLenght.length === 0) {
    if (oppLenght.length === 0) {
      return (
        <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
          <KTSVG
            path='/media/icons/duotune/general/gen045.svg'
            className='svg-icon-primary svg-icon-2hx me-3'
          />

          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Info</h5>
            <span>No programs found! Please switch a different client!</span>
          </div>
        </div>
      )
    }
    return (
      <>
        <div className='row g-5 g-xl-8'>
          <div className='col-lg-12'>
            <YourPrograms />
          </div>
          <div className='col-lg-12'>{/* <NotSelectedProprams /> */}</div>
        </div>
      </>
    )
  }
}

const DashboardAdmin: FC = () => {
  const {getAllProductsReq} = useProductRequests()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PRODUCTS_LIST}-${query}`,
    () => {
      return getAllProductsReq(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const HandleClickSyncProducts = () => {
    refetch()
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div>Hello Admin</div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {isAdminOrSuperAdmin} = useRole()
  const {client} = useParams()

  const {data: enrollmentSummary} = useQuery<IEnrollmentSummary[]>(
    _queryKeys.getAllEnrollmentSummariesReq
  )

  const subheader = () => {
    if (!enrollmentSummary) {
      return ''
    }
    // if (enrollmentSummary[0].opp_status.name === 'Incomplete - Deposit Due'
    // || enrollmentSummary[0].opp_status.name === 'Incomplete - Deposit Paid') {
    //   return 'YOUR APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE'
    // }
    // if (enrollmentSummary[0].opp_status.name === 'Applied - Pending') {
    //   return 'APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE'
    // }
    if (enrollmentSummary[0].opp_status.name === 'Enrolled - Paid') {
      return 'YOUR APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE.'
    }
    return `YOUR APPLICATION IS ${enrollmentSummary[0].opp_status.name}.`
  }
  
  const clientRoutes = client === '47821104' ? 'Click to view the SSB Travel Portal to access additional resources'
  : client === '88562775' ? 'Click to view the TTN Travel Portal to access additional resources' 
  : 'Click to view the GLA Travel Portal to access additional resources'

  const clientRoutesNavigation = client === '47821104' ? 'https://summerspringboard.com'
  : client === '88562775' ? 'https://sites.google.com/teentravelnetwork.com/travel-portal' 
  : 'https://sites.google.com/teentravelnetwork.com/travel-portal'

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const monthIndex = new Date().getMonth();
  const monthName = months[monthIndex];
  const SSB_Note = client === '47821104' ? `PLEASE NOTE: We will follow up with an email in ${monthName} when the required forms for your program are available.`: 
  'PLEASE NOTE: Your program has extra requirements that are not listed below. Please see the Travel Portal or your Welcome Email for additional forms!'

  const clientTelNumberRoute = client === '47821104' ? '858-780-5660' : '858-771-4886'

  return (
    <>
      <div className='card-body'>
        {!isAdminOrSuperAdmin && (
          <div
            className='alert alert-warning d-flex align-items-center p-5 '
            style={{color: 'black'}}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen045.svg'
              className='svg-icon-warning svg-icon-2x me-4'
            />
            <div className='d-flex flex-column'>
              <span className='fw-bold mb-2'>
                Thank you for submitting your application. Please complete the remaining steps of
                your enrollment process by the deadline.
              </span>
              <span>{SSB_Note}</span>
            </div>
          </div>
       )}
      </div>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      {!isAdminOrSuperAdmin && (
        <ConfirmPayBanner
          title='ENROLLMENT DASHBOARD'
          // subheader='YOUR APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE.'
          // subheader={subheader()}
        />
      )}
      <div className='alert alert-link d-flex align-items-center p-5 w-100'>
        <div className='d-flex flex-column w-100'>
          {currentUser?.role === ROLES.superAdmin ? <DashboardAdmin /> : <DashboardPage />}
          {currentUser?.role !== ROLES.superAdmin && (
            <>
              <a
                className='fw-bold'
                target='_blank'
                href={clientRoutesNavigation}
                rel='noreferrer'
              >
                {clientRoutes}
              </a>
              <p>
                If you would like to make any changes to the information you already submitted on
                your application, you will need to contact us directly to make those updates. Please
                give us a call at <a href={`tel:${clientTelNumberRoute}`}>{clientTelNumberRoute}</a> during office hours
                and we'll be happy to update those details for you on your account!
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
