import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateAccount, createAccountSchemas, initsCreateAccount} from '../core/_models'
import {Link} from 'react-router-dom'
import { useMutation } from 'react-query'
import { axiosRegister } from '../core/_requests'

const RegistrationSetupStep = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(initsCreateAccount)
  const [accountEmail, setAccountEmail] = useState('');
  const [errMessage, setErrMessage] = useState('');

const {isLoading, mutate: onSignup, error} = useMutation(axiosRegister)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    console.log('submitStep', values)

    if (!stepper.current) {
      return
    }

    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1) {
      setAccountEmail(values.email)
      console.log('call signup API here!', stepper.current?.currentStepIndex)

      try {
        onSignup(values, {
          onSuccess: (data) => {
            console.log('onSignupSuccess', data)
            setCurrentSchema(createAccountSchemas[4])
            stepper.current?.goNext()
          },
          onError: (error: any) => {
            console.log('onSignup', error.response.data.error)
            const errMsg = error.response.data.error.join('\r\n');
            setErrMessage(errMsg)
          },
        })
      } catch (err) {
        console.log("asd", err)
      }
      
    }else{
      setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

      if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
        stepper.current.goNext()
      } else {
        stepper.current.goto(1)
        actions.resetForm()
      }
    }

  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
    return ()=>{
      setErrMessage('')
    }
  }, [stepperRef])

  //console.log('stepper', stepper.current)

  return (
    <>
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Welcome!</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Create an Account</h3>

                    <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Clients Info</h3>
                    <div className='stepper-desc fw-semibold'>Your Clients Related Info</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>
                    <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 3*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 errMessage={errMessage} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 accountEmail={accountEmail} />
                </div>

                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber && (
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    )}
                  </div>

                  <div>
                    {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber ||
                    stepper.current === null ? (
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        {!isLoading && (
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex !==
                              stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    ) : (
                      <Link to='/auth/login'>
                        <button
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-lg btn-light-primary w-100 mb-5'
                        >
                          <span className='indicator-label'>
                            Sign In
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export {RegistrationSetupStep}
