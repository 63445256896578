import {IEnrollPartTwo, IEnrollPartTwoForm, IMedicalField, MEDICAL_FORM} from '../../core/_models'
import {ErrorMessage} from 'formik'
import CustomField from './CustomField'
import ReactHtmlParser from 'react-html-parser'
import { replace } from 'lodash'

interface Props {
  form: IEnrollPartTwoForm
  currentFormikVal: any
  prefixFieldName: string
  formsList: IEnrollPartTwoForm[]
  objectName: string
}

const CustomHiddenField = ({
  form,
  currentFormikVal,
  prefixFieldName,
  formsList,
  objectName,
}: Props): any => {
  if (!form.for) return null
  let currentParentField = formsList.filter(f => f.id == form.for)
  if (currentParentField === undefined || !currentParentField) {
    return null
  }
  const currentFormikValObj = currentFormikVal as {[key: string]: any}
  const objName = objectName.toLowerCase().replace(" ", "_").replace("-", "_");
  const objForms = currentFormikValObj.customField[currentParentField[0].name]
  const medicalVal = Array.isArray(objForms)
    ? objForms
    : [objForms]
  if (
    (form.type === 'description' || form.type === 'last_episode') &&
    medicalVal.includes(form.choiceId?.toString())
  ) {
    return (
      <div className='col-lg-12 mb-10'>
        {form.label && (
          <label className={'form-label fw-bolder text-dark fs-6 required'}>
            {ReactHtmlParser(form.label)}
          </label>
        )}
        {/* <Field
          as='textarea'
          row='8'
          className='form-control form-control-lg form-control-white'
          name={prefixFieldName + form.name}
        /> */}
        <CustomField form={form} />
        <div className='text-danger mt-2'>
          <ErrorMessage name={form.name} />
        </div>
      </div>
    )
  }
  return null
}

export default CustomHiddenField
