import React from 'react'
import {useTable} from 'react-table'
import './dynamic-table.css'

interface Props {
  columns: any
  data: any
}

const DynamicTable = ({columns, data}: Props) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
  })

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className='card card-body'>
      <div className='table-responsive scrollbar'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed dataTable no-footer gy-5'
          {...getTableProps()}
        >
          <colgroup>
            {columns.map((column: any) => {
              return <col style={{minWidth: column?.minWidth}} key={column.accessor} />
            })}
          </colgroup>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className='text-start fw-bolder fs-7 text-uppercase gs-0'
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                })}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600 fw-light' {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DynamicTable
