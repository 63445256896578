import { useEffect, useState } from 'react'
import {useQuery} from 'react-query'
import {useLocation, useNavigate} from 'react-router-dom'
import {PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, useAuth} from '../../../auth'
import _queryKeys from '../../core/_queryKeys'
import {IEnrollmentSummary} from '../../core/_models'
import EnrollmentTable from '../Shared/EnrollmentTable'
import Predeparture from './Predeparture'
import EditProgram from '../EditProgram'
import RemoveOpportunity from './RemoveOpportunity'
import Discount from './Discount'
import Status from './Status'
import useAppRequests from '../../Hooks/useAppRequests'
import AddPPP from './AddPPP'

const YourPrograms = () => {
  const {getAllEnrollmentSummariesReq} = useAppRequests()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [currentOpp, setCurrentOpp] = useState(0)
  const [hasAdmin, setHasAdmin] = useState(false)

  // const enrollmentSummary = queryClient.getQueryData<IEnrollmentSummary[]>(
  //   _queryKeys.getAllEnrollmentSummariesReq
  // )

  const {
    data: enrollmentSummary,
    refetch,
    isLoading,
    isFetching,
  } = useQuery<IEnrollmentSummary[]>(
    _queryKeys.getAllEnrollmentSummariesReq,
    getAllEnrollmentSummariesReq
  )

  const handleRefetch = () => {
    console.log('refechting')
    refetch()
  }

  useEffect(() => {
    if (enrollmentSummary) {
      // console.log('= = = Enrollment Summary = = =')
      // console.log(enrollmentSummary)
      // console.log('= = = = =')

      const checkAdmin = async () => {
        const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (prevAuth) {
          setHasAdmin(true)
        }
      }
      checkAdmin()
    }
  }, [enrollmentSummary])

  const onMakePaymentApp = (selectedEnrollment: IEnrollmentSummary | undefined) => {
    if (selectedEnrollment) {
      return navigate(
        `/enroll/${currentUser?.currentClient}/make-a-payment/${selectedEnrollment?.opportunity}`,
        {state: {from: location}, replace: true}
      )
    } else {
      global.alert('Invalid action.')
    }
  }
  const onReviewApp = (selectedDate: IEnrollmentSummary | undefined) => {
    const partTwoStatus = selectedDate?.status
    if (selectedDate?.opp_status?.name === 'Incomplete - Deposit Due') {
      return navigate(
        `/enroll/${currentUser?.currentClient}/confirm-and-pay/${selectedDate?.opportunity}`,
        {state: {from: location}, replace: true}
      )
    } else {
      if (!partTwoStatus) {
        return
      }
      return navigate(
        `/enroll/${currentUser?.currentClient}/enroll-part-two/${selectedDate?.opportunity}`,
        {state: {from: location}, replace: true}
      )
    }
  }
  // const handleMakePayment = () => {
  //   navigate(
  //     `/enroll/${currentUser?.currentClient}/confirm-and-pay/${currentOpportunity?.opportunity}`,
  //     {state: {from: location}, replace: true}
  //   )
  // }

  const onEditApp = (enrollmentApp: IEnrollmentSummary | undefined) => {
    return navigate(
      `/enroll/${currentUser?.currentClient}/enroll-part-two/${enrollmentApp?.opportunity}`,
      {state: {from: location}, replace: true}
    )
  }

  const handleClose = (data = 0) => {
    setCurrentOpp(data)
    setShowModal((prev) => !prev)
  }

  if (isLoading) {
    return (
      <div className='w-100'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  let mmddyyyy = /-\d{2}\/\d{2}\/\d{4}/g // -MM/DD/YYYY (Pattern)

  return (
    <>
      <div className='card p-0 min-height-0 mb-xl-8'>
        <div className='card-header'>
          <h2 className='card-title flex-column justify-center items-center fw-bold text-capitalize text-center'>
            Your Currently Active Programs
          </h2>
        </div>
      </div>

      {enrollmentSummary?.map((item) => {
        if (item.opp_status.id === 15) {
          return ''
        }
        return (
          <>
            <h2 className='mt-5 mb-3 text-center'>{item?.name?.replace(mmddyyyy, '')}</h2>

            <div className='mb-7 card' key={item.opportunity}>
              <EnrollmentTable
                enrolledSummary={item}
                refetch={handleRefetch}
                isFetching={isFetching}
              />
              <div className='card-body m-0 py-0 d-flex justify-content-between'>
                {/* <button
                type='button'
                className='btn btn-primary'
                onClick={() => handleMakePayment()}
              >
                Make a Payment <i className='fa-solid fa-angles-right'></i>
              </button> */}
              {(item.opp_status.id === 2 || hasAdmin) ? (
                <div className='d-flex justify-content-end mb-3'>
                  <button 
                    className='btn btn-primary d-flex align-items-center' 
                    disabled={item.editInProgress}
                    onClick={() => {handleClose(item.opportunity)}}
                  >
                    Edit Program
                    {item.editInProgress ? (
                      <span className='spinner-border spinner-border-sm align-middle ms-5'></span>
                    ) : ''}
                  </button>
                  {hasAdmin ? (
                    <>
                      <Status enrollmentSummary={item} refetch={handleRefetch} />
                      {item?.opp_status.id !== 18 ? <Discount oppId={item.opportunity} refetch={handleRefetch} /> : ""}
                      {item?.opp_status.id !== 18 && item.opp_status.id !== 2 ? <AddPPP enrollmentSummary={item} refetch={handleRefetch} /> : ""}
                      <RemoveOpportunity oppId={item.opportunity} />
                    </>
                  ) : ''}
                </div>
              ) : <div /> }
              
              {item.opp_status.id === 17 ? ''
              : !item.opp_status?.name.includes('Enrolled') &&
              !item.opp_status?.name.includes('Applied') &&
              !item.opp_status?.name.includes('Received') &&
              item.opp_status.id !== 16  && item.opp_status.id !== 18 ? (
                <div className='d-flex justify-content-end mb-3'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => onReviewApp(item)}
                  >
                    Review & Complete This Application <i className='fa-solid fa-angles-right'></i>
                  </button>
                </div>
              ) : (
                <>
                  <div className='mb-5 d-flex justify-content-end'>
                    {(item?.remaining_balance &&
                    item?.remaining_balance > 0 && item?.opp_status.id !== 18) ? (
                      <button
                        type='button'
                        className='btn btn-lg btn-dark me-3'
                        onClick={() => onMakePaymentApp(item)}
                      >
                        <span className='indicator-label'>Pay The Remaining Amount(s)</span>
                      </button>
                    ) : null}
                    {hasAdmin && item?.opp_status.id !== 18 ? (
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => onEditApp(item)}
                      >
                        Edit Application <i className='fa-solid fa-angles-right'></i>
                      </button>
                    ) : null}
                  </div>
                  {/* <div className='alert alert-primary d-flex align-items-center p-5'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='svg-icon-primary svg-icon-2x me-4'
                    />
                    <div className='d-flex flex-column'>
                      <h5 className='mb-1'>
                        {client === '47821104' &&
                          'This application has been submitted to SSB for review.'}
                        {client === '88562775' &&
                          'This application has been submitted to TTN for review.'}
                        {client === '84984438' &&
                          'This application has been submitted to GLA for review.'}
                      </h5>
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </div>
          {(item?.predeparture_items && item?.predeparture_items?.length > 0) && item.opp_status?.name.includes('Enrolled') ? (
            <Predeparture predeparture={item.predeparture_items} opportunity={item.opportunity} />
          ) : null}
          </>
        )
      })}
      <EditProgram
        showModal={showModal}
        handleClose={handleClose}
        opportunityID={String(currentOpp)}
      />
    </>
  )
}

export default YourPrograms
