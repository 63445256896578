import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { Button, Modal } from 'react-bootstrap'
import Step1 from '../EnrollSteps/Step1'
import Step2 from '../EnrollSteps/Step2'
import Step2b from '../EnrollSteps/Step2b'
import Step2a from '../EnrollSteps/Step2a'
import { IProgram } from '../../core/_models'
import { useEffect, useRef, useState } from 'react'
import useCurrentClientRoute from '../../../auth/hooks/useCurrentClientRoute'
import { useAuth } from '../../../auth'
import { axiosInstance, axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  showModal?: boolean
  handleClose?: () => void
  opportunityID?: string
}

const initValues: IProgram = {
  academicTrack: '',
  productgroup: 0,
  product: -1,
}

const Index = ({showModal, handleClose, opportunityID}: IProps) => {
  const {currentClientRoute} = useCurrentClientRoute()
  const {currentUser} = useAuth()
  const childRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [inProgress, setInProgress] = useState('')
  const [maxedProgram, setMaxedProgram] = useState('');
  const [xstatus, setXStatus] = useState<boolean>(false);

  const isSyncing = async () => {
    try {
      const res = await axiosPrivate.post('/sfqueue_checker', {
        opportunity_id: opportunityID,
        type: 'OpportunityProgramProduct'
      })
      if (res.data.inProgress) {
        setInProgress('Your previous update is still in progress. Please try again later')
      }
    } catch (error) {
      setInProgress('Error while checking the salesforce syncing')
    }
  }
  const checkCapacity = async (values: number) => {
    const response = await axiosInstance.get(`/getEnrollee/${values}`);
    setXStatus(response.data.isLimit);
    if(response.data.isLimit) {
      setMaxedProgram(response.data.name);
    }

  }
  useEffect(() => {
    if (!showModal) {
      setError(null)
    } else {
      isSyncing()
    }
  }, [showModal])

  const validation: any = {
    product: Yup.number()
      .moreThan(0, 'You must select a program')
      .notOneOf([-1], 'You must select a program')
      .required('You must select a program'),
  }
  if (currentClientRoute === '47821104') {
    validation.academicTrack = Yup.string().required('You must select a course')
  }
  const schemaValidation = Yup.object(validation)
  
  initValues.client_id = currentClientRoute || ''
  initValues.loginuser_id = currentUser?.id || -1
  initValues.id = Number(opportunityID) || -1

  const toScroll = (name: string) => {
    let totalScroll = 100

    const elementStep1 = document.querySelector('#step1');
    const actualHeightStep1 = elementStep1?.clientHeight;
    
    const elementStep2 = document.querySelector('#step2');
    const actualHeightStep2 = elementStep2?.clientHeight;

    const elementStep2a = document.querySelector('#step2a');
    const actualHeightStep2a = elementStep2a?.clientHeight;

    if (actualHeightStep1 && actualHeightStep2 && actualHeightStep2a) {
      if (name === 'step1') {
        totalScroll += actualHeightStep1
      }
  
      if (name === 'step2') {
        totalScroll += actualHeightStep1 + actualHeightStep2 + 50
      }
  
      if (name === 'step2a') {
        totalScroll += actualHeightStep1 + actualHeightStep2 + actualHeightStep2a
      }
  
      setTimeout(() => {
        if (childRef.current) {
          childRef.current.scrollTop = totalScroll;
        }
      }, 50)
    }

  }

  const submitStep = async (values: IProgram, actions: FormikValues) => {
    setLoading(true)
    setError(null)
    try {
      values.isLimit = xstatus
      const res = await axiosPrivate.post('/update_program', values)
      if (res) {
        window.location.reload()
        setLoading(false)
      }
    } catch (error: any) {
      setError(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} size='xl' >
        <Formik initialValues={initValues} validationSchema={schemaValidation} onSubmit={submitStep}>
          {({values, setFieldValue, errors, setErrors}) => {
            // console.log(values, 'qweqweqweqwe')
            return (
              <>
                <Form noValidate>
                  <Modal.Body style={{height: 'calc(100vh - 140px)', overflow: 'auto', scrollBehavior: 'smooth', padding: 0}} ref={childRef}>
                    <Modal.Header closeButton style={{ position: 'sticky', top: 0, background: '#fff', boxShadow: '0 0 37px #80808049' }}>
                      <Modal.Title>Edit Program</Modal.Title>
                    </Modal.Header>

                      <div className='py-20 w-100' id='kt_create_account_form'>
                        <div id='step1'>
                          <Step1 currentFormikVal={values} setFieldValue={setFieldValue} toScroll={toScroll} />
                        </div>
                        <div id='step2' className='px-10 mt-20'>
                          <Step2 currentFormikVal={values} setFieldValue={setFieldValue} toScroll={toScroll}/>
                        </div>
                        {currentClientRoute === '47821104' ? (
                          <>
                            <div id='step2a' className='py-10'>
                              <Step2a currentFormikVal={values} setFieldValue={setFieldValue} toScroll={toScroll} checkCapacity={checkCapacity} isLimit={xstatus} />
                            </div>
                            {xstatus ? <div className='alert alert-warning d-flex align-items-center py-5 my-10 mx-10'>
                              <div className='d-flex flex-column'>
                                <h5 className='mb-1'>Slots Full!</h5>
                                <span>We are excited to have you join this summer!
                                    Unfortunately at this stage, our {maxedProgram} is fully enrolled,
                                    however you may continue your application in order to join our waitlist.
                                    No application fee / deposit will be charged. If a spot becomes available,
                                    we will contact you in order to confirm your continued interest prior to enrolling you.
                                    In addition, we suggest that you separately apply to your next choice for the upcoming summer,
                                    so as not to miss out on what will be an incredible experience.
                                    We look forward to seeing you this summer.
                                </span>
                              </div>
                            </div> : ""}
                            <div id='step2b' className='px-10 py-10'>
                              <Step2b currentFormikVal={values} setFieldValue={setFieldValue} />
                            </div>
                          </>
                        ) : ''}
                      </div>

                  </Modal.Body>
                  <Modal.Footer style={{padding: '20px 30px'}}>
                    <div className='me-5'>
                      {Object.keys(errors).length ? (
                        <div className='text-danger text-center d-flex align-items-center'>
                          Please complete all the fields.
                        </div>
                      ) : ''}
                      {error ? (
                        <div className='text-danger text-center d-flex align-items-center'>
                          {error}
                        </div>
                      ) : ''}
                      {inProgress ? (
                        <div className='text-danger text-center d-flex align-items-center'>
                          {inProgress}
                        </div>
                      ) : ''}
                    </div>
                    <div>
                      <Button variant='secondary' onClick={handleClose}>
                        Discard
                      </Button>
                      <Button 
                        variant='primary' 
                        className='ms-2' 
                        type='submit'
                        disabled={inProgress ? true : false}
                      >
                        {/* Submit */}
                        {loading ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : 'Submit'}
                      </Button>
                    </div>
                  </Modal.Footer>     
                </Form>
              </>
            )
          }}
        </Formik>
      </Modal>
      {loading ? (
        <div style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: '#0000005c',
          top: 0,
          left: 0,
          zIndex: 9999999,
          pointerEvents: 'none'
        }}>

        </div>
      ) : ''}
    </>
  )
}

export default Index