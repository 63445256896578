import {Field, Form, Formik, FormikValues} from 'formik'
import {useEffect, useState} from 'react'
import {EnrollmentGroupSchema} from './Schemas/ValidationSchema'
import {AddOrEditEnrollmentFormGroup, getDocumentAPI} from '../../../../network/api'
import {IEnrollmentFormGroup} from './EnrollmentList'
import {useNavigate, useParams} from 'react-router-dom'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import CustomTextArea from '../../../../_metronic/shared-components/CustomTextArea'
import {axiosPrivate} from '../../../../axiosInstance'
import DatePickerField from '../../Shared/components/DatePickerField'
import Select from 'react-select'

const clientID = [
  {
    value: '88562775',
    label: 'Teen Travel Network',
    name: 'TTN',
  },
  {
    value: '47821104',
    label: 'Summer Springboard',
    name: 'SSB',
  },
  // {
  //   value: '84984438',
  //   label: 'Experience GLA',
  //   name: 'GLA',
  // },
  // {
  //   value: '14302874',
  //   label: 'Terra Education',
  //   name: 'tEdu',
  // },
]

const SalesforceAPIStatus =[
  {
    value: 'SSB_Extra_Activity__c',
    label: 'Authorized pickups: Status',
  },
  {
    value: 'SSB_Medical_Consent_Student_Name__c',
    label: 'Authorized Persons to Pickup',
  },
  {
    value: 'SSB_Medical_Consent_Student_Signature__c',
    label: 'SSB General Form 1 Status',
  },
  {
    value: 'SSB_Medical_Consent_Complete__c',
    label: 'SSB General Form 2 Status',
  },
  {
    value: 'SSB_Waiver_Student_Initial__c',
    label: 'Campus/Course Form 1 Status',
  },
  {
    value: 'SSB_Waiver_Student_Name__c',
    label: 'Campus/Course Form 2 Status',
  },
  {
    value: 'SSB_Waiver_Student_Phone__c',
    label: 'Campus/Course Form 3 Status',
  },
  {
    value: 'SSB_Current_Gpa__c',
    label: 'Campus/Course Form 4 Status',
  },
  {
    value: 'PDC_Flight_Itinerary_Status__c',
    label: 'Flight Form Status'
  },
  {
    value: 'Immunization_Record_Received__c',
    label: 'Immunization Form Status',
  },
  {
    value: 'SSB_Medical_Consent_Parent_Signature__c',
    label: 'Insurance card Form Status',
  },
  {
    value: 'SSB_Covid_Consent_Parent_Signature__c',
    label: 'SSB Elective Choose 1',
  },
  {
    value: 'SSB_Waiver_Complete__c',
    label: 'SSB Elective Choose 2',
  },
  {
    value: 'SSB_Waiver_Parent_Phone__c',
    label: 'SSB Elective Choose 3',
  },
]

const SalesforceAPIDate =[
  {
    value: 'SSB_Challenge__c',
    label: 'Authorized pickups: Date of Submission',
  },
  {
    value: 'SSB_Medical_Consent_Student_Name__c',
    label: 'Authorized Persons to Pickup',
  },
  {
    value: 'SSB_Medical_Consent_Date__c',
    label: 'SSB General Form 1 Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Initial__c',
    label: 'SSB General Form 2 Submission Date',
  },
  {
    value: 'SSB_Waiver_Student_Signature__c',
    label: 'Campus/Course Form 1 Submission Date',
  },
  {
    value: 'SSB_Waiver_Student_Date__c',
    label: 'Campus/Course Form 2 Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Signature__c',
    label: 'Campus/Course Form 3 Submission Date',
  },
  {
    value: 'SSB_Covid_Consent_Complete__c',
    label: 'Campus/Course Form 4 Submission Date',
  },
  {
    value: 'SSB_Covid_Consent_Date__c',
    label: 'Flight Form Date of Submission'
  },
  {
    value: 'SSB_Covid_Consent_Parent_Name__c',
    label: 'Immunization Form Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Relationship__c',
    label: 'Insurance card Form Submission Date'
  },
  {
    value: 'SSB_Covid_Consent_Parent_Signature__c',
    label: 'SSB Elective Choose 1',
  },
  {
    value: 'SSB_Waiver_Complete__c',
    label: 'SSB Elective Choose 2',
  },
  {
    value: 'SSB_Waiver_Parent_Phone__c',
    label: 'SSB Elective Choose 3',
  },
]

const type = [
  {
    value: 'pre-departure',
    label: 'Pre-Departure',
  },
  {
    value: 'form',
    label: 'Enrollment Forms',
  },
]

const task_title = [
  {
    value: 'waiver',
    label: 'Waiver',
  },
  {
    value: 'upload',
    label: 'Upload',
  },
  {
    value: 'form',
    label: 'Form',
  },
]

const task_type = [
  {
    value: 'upload-template',
    label: 'UPLOAD TEMPLATE',
  },
  {
    value: 'third-party-link',
    label: '3RD PARTY LINK',
  },
]

const task_type_alone = [
  {
    value: 'hellosign',
    label: 'HELLOSIGN',
  },
]
interface SessionDetail {
  name: string
  division: string
  startDate: string
  endDate: string
  id: number
  type: string
  program_track: any[]
}
interface Program {
  groupName: string
  session: SessionDetail[]
}

interface HelloSignDList {
  documentId: number
  templateTitle: string
  // templade_id: string
  // template_description: string
  // template_roles: any
  // template_additional_config: any
}

const EnrollmentAdd = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [programs, setPrograms] = useState<Program[]>([])
  const [selectedCampuses, setSelectedCampuses] = useState<string[]>([])
  const [selectedSessions, setSelectedSessions] = useState<SessionDetail[]>([])
  const [selectedTracks, setSelectedTracks] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [clientChange, setClientChange] = useState<string>('')
  const [helloSignDocumentList, setHelloSignDocumentList] = useState<HelloSignDList[]>([])
  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate()
  const {client} = useParams()

  const initialValues = {
    name: '',
    client_id: '',
    status: '',
    title: '',
    type: '',
    selected_items: '',
    is_selected_all: false,
    selected_programs: ''
  }

  const onSubmit = async (values: IEnrollmentFormGroup, actions: FormikValues) => {
    try {
      if (values.type === 'pre-departure') {
        if (values.title === 'waiver') {
          values.route_name = '/portal/:client/waiver'
        } else {
          values.route_name = '/portal/:client/upload';
        }


        const modifiedValues = {
          ...values,
          status: values?.status ? 'active' : 'inactive',
          client_id: client === '47821104' ? '47821104' : '88562775',
          selected_programs: values.selected_programs && values?.is_selected_all === "false" ? JSON.stringify(values.selected_programs) : null,
          selected_sessions: values.selected_sessions && values?.is_selected_all === "false" ? JSON.stringify(values.selected_sessions) : null,
          selected_items: values.selected_items && values?.is_selected_all === "false" ? JSON.stringify(values.selected_items) : null,
          is_selected_all: values?.is_selected_all !== "false",
        };

        await AddOrEditEnrollmentFormGroup(modifiedValues);
      } else if (values.type === 'form') {
      values.route_name = '';

      const modifiedValues = {
          ...values,
          status: values?.status ? 'active' : 'inactive',
          selected_programs: null,
          selected_sessions: null,
          selected_items: null,
          is_selected_all: null,
      };

      await AddOrEditEnrollmentFormGroup(modifiedValues);
    }
    setSuccessMessage('Enrollment Form Group added successfully')

    actions.resetForm()

    setTimeout(() => {
      navigate(`/apps/${client}/enrollment-group-management/enrollment-group`)
    }, 3000)
      
      } catch (error: any) {
      console.log(error, 'modifiedValues')
      if (error.response.status === 500) {
        return setErrorMessage('Something went wrong, please try again.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else setErrorMessage('Something went wrong, please try again.')

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  const getDocumentList = async () => {
    try {
      const response = await getDocumentAPI();
      setHelloSignDocumentList(response);
    } catch (error) {
      console.error(error) 
    }
  }

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axiosPrivate.get(`terra-admin/fetch_all_products/${client}`)
        setPrograms(response.data)
      } catch (error) {
        console.error('Error fetching programs:', error)
      }
    }

    getDocumentList()
    fetchPrograms()
  }, [])

  const handleTrackChange = (trackId: string, setFieldValue: any) => {
    const updatedSelectedTracks = selectedTracks.includes(trackId)
      ? selectedTracks.filter((track) => track !== trackId)
      : [...selectedTracks, trackId]

    setSelectedTracks(updatedSelectedTracks)

    if (client === '88562775') {
      setFieldValue('selected_items', null);
    } else {
      setFieldValue('selected_items', updatedSelectedTracks)
    }
  }

  return (
    <>
      {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card'>
            <Formik
              initialValues={initialValues}
              validationSchema={EnrollmentGroupSchema}
              onSubmit={onSubmit}
            >
              {({values, isSubmitting, setFieldValue, errors}) => {

                const handleTaskTypeChange = (event: any) => {
                  setFieldValue('task_type', event.value)
                  if (event.value !== values.task_type) {
                    setFieldValue("custom_input", "")
                  }
                }

                const handleCampusChange = (campus: string) => {
                  const updatedSelectedCampuses = selectedCampuses.includes(campus)
                    ? selectedCampuses.filter((c) => c !== campus)
                    : [...selectedCampuses, campus];

                  setSelectedCampuses(updatedSelectedCampuses);

                  if (!updatedSelectedCampuses.includes(campus)) {
                    setSelectedSessions([]);
                    setSelectedTracks([]);
                  }
                  setFieldValue('selected_programs', updatedSelectedCampuses.map((item) => (item)));
                };

                const handleSessionChange = (session: SessionDetail) => {
                  const updatedSelectedSessions = selectedSessions.includes(session)
                    ? selectedSessions.filter((s) => s !== session)
                    : [...selectedSessions, session]

                  setSelectedSessions(updatedSelectedSessions)
                  setFieldValue('selected_sessions', updatedSelectedSessions)

                  if (!updatedSelectedSessions.includes(session)) {
                    const newSelectedSessions = selectedSessions.filter((s: any) => s !== session)
                    let newSelectedCourses: any = [];
        
                    for (const session of newSelectedSessions) {
                        newSelectedCourses = newSelectedCourses.concat(session.program_track.map((track: any) => track.id));
                    }
        
                    const filteredSelectedCourses = selectedTracks.filter((course: any) => newSelectedCourses.includes(course.id))
                    setSelectedTracks(filteredSelectedCourses);
                  }
                
                  if (client === '88562775') {
                    const transformedSessions = updatedSelectedSessions.map(session => session);
                    setFieldValue('selected_sessions', transformedSessions);
                  } 
                }

                const handleSelectAll = () => {
                  const allTracks = selectedSessions.flatMap(session => session.program_track);
                  const allTrackIds = allTracks.map(track => track);
              
                  if (selectAll) {
                      // Deselect all tracks
                      setSelectedTracks([]);
                  } else {
                      // Select all tracks
                      setSelectedTracks(allTracks);
                  }
                  setFieldValue('selected_items', allTrackIds); // Update form field value
                  setSelectAll(!selectAll); // Toggle selectAll state
                };
              
                const isAllSelected = () => {
                  const allTracks = selectedSessions.flatMap(session => session.program_track);
                  return selectedTracks.length === allTracks.length;
                };

                return (
                  <Form className='container'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <CustomReactSelect
                          label='Type'
                          name='type'
                          placeholder='Select a Type'
                          options={type.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                        />
                      </div>

                      <div className='col-lg-6'>
                        <CustomInput label='Name' name='name' placeholder='Enter a Name' />
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label fw-bolder text-dark fs-6 required'>
                          Status
                        </label>
                        <CustomCheckbox label='Active' type='checkbox' name='status' />
                      </div>

                      {values.type === 'pre-departure' && (
                        <>
                          <div className='col-lg-6'>
                            <CustomTextArea
                              label='Task Overview'
                              name='task_overview'
                              placeholder='Enter a Task Overview'
                            />
                          </div>

                          <div className='col-lg-6'>
                            <CustomInput label='Description' required name='description' placeholder='Enter a description' />
                          </div>

                          <div className='col-lg-6'>
                            <CustomTextArea label='Instruction' required name='instruction' placeholder='Enter Instructions' />
                          </div>

                          <div className='col-lg-6'>
                            <CustomReactSelect
                              label='Task Title'
                              name='title'
                              placeholder='Select a Task Title'
                              options={task_title.map((item) => ({
                                value: item.value,
                                label: item.label,
                              }))}
                            />
                          </div>

                          {values.title === 'upload' || values.title === 'form' ? (
                            <>
                              <div className='col-lg-6'>
                                <CustomReactSelect
                                label='Salesforce API Status name'
                                name='sf_api_name_status'
                                placeholder='Enter a salesforce name with __c'
                                options={SalesforceAPIStatus.map((item) => ({
                                  value: item.value,
                                  label: item.label,
                                }))}
                                />
                              </div>
                              <div className='col-lg-6'>
                                <CustomReactSelect
                                  label='Salesforce API Date name'
                                  name='sf_api_name_date'
                                  placeholder='Enter a salesforce name with __c'
                                  options={SalesforceAPIDate.map((item) => ({
                                    value: item.value,
                                    label: item.label,
                                  }))}
                                  />
                              </div>
                            </>
                          ): ''}

                          <div className='col-lg-6'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Task Deadline
                            </label>
                            <DatePickerField
                              className='form-control form-control-lg form-control-solid mb-2'
                              name='task_deadline'
                              placeholder='Select a deadline'
                            />
                          </div>

                          <div className='my-5'>
                            <label className='flex-grow-1 required fw-bold fs-6'>Task Type</label>
                            <Select
                              name='task_type'
                              onChange={(e) => handleTaskTypeChange(e)}
                              options={values.title == 'form'|| values.title == 'upload' ? task_type : task_type_alone}
                              placeholder='Select a Task Type'
                              isClearable
                            />
                          </div>

                          {values.task_type === 'hellosign' ? (
                            <CustomReactSelect
                              label='Template ID'
                              name='custom_input'
                              options={helloSignDocumentList.map((item) => ({
                                value: item.documentId,
                                label: item.templateTitle,
                              }))}
                              placeholder='Enter a valid template ID'
                            />
                          ) : values.task_type === 'upload-template' ? (
                            <CustomInput
                              label='Template Download Link'
                              name='custom_input'
                              placeholder='Enter a template download link'
                            />
                          ) : values.task_type === 'third-party-link' ? (
                            <CustomInput
                              label='3rd Party Link'
                              name='custom_input'
                              placeholder='Enter third party link'
                            />
                          ) : (
                            ''
                          )}

                          <div className='d-flex gap-2 col-lg-6'>
                            <label className='d-flex gap-2'>
                              <Field type="radio" name="is_selected_all" value="true" />
                              All Programs 
                            </label>
                            <label className='d-flex gap-2'>
                              <Field type="radio" name="is_selected_all" value="false" />
                              Custom Program Selection
                            </label>
                          </div>

                          {values.is_selected_all === "false" ? (
                              <>
                                <h2 className='mt-5'>Campus</h2>
                                <div style={{display: 'grid'}}>
                                  {programs.map((program, programIndex) => {
                                    return (
                                      <div key={programIndex} style={{display: 'flex', gap: 10}}>
                                        <Field
                                          type='checkbox'
                                          value={program.groupName}
                                          name='selected_programs'
                                          checked={selectedCampuses.includes(program.groupName)}
                                          onChange={() => handleCampusChange(program.groupName)}
                                        />
                                        <label>{program.groupName}</label>
                                      </div>
                                    )
                                  })}
                                </div>

                                {selectedCampuses.length > 0 ? (
                                  <>
                                    <h3 className='mt-5'>Sessions</h3>
                                    <div>
                                      {selectedCampuses.map((selectedCampus, index) => (
                                        <div className='mt-2' key={index}>
                                          <h1>{selectedCampus}</h1>
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {programs.filter((program) => program.groupName === selectedCampus)
                                              .flatMap((program) => program.session).map((session, sessionIndex) => {
                                                return (
                                                  <div key={sessionIndex}>
                                                    <div className='d-flex gap-2'>
                                                      <Field
                                                        type='checkbox'
                                                        name='selected_sessions'
                                                        value={session.id.toString()}                                                   
                                                        checked={selectedSessions.includes(session)}
                                                        onChange={() => handleSessionChange(session)}
                                                      />
                                                      <label>{`${session.name} (${session.startDate}-${session.endDate}) ${session.type}`}</label>
                                                    </div>
                                                  </div>
                                                )
                                              })}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : ('')
                          }

                          {client === '47821104' && values.is_selected_all === "false" && selectedSessions.length > 0 && (
                            <div>
                              <div>
                                <div  className='d-flex mt-5 align-items-center gap-5'>
                                <h3>Courses</h3>
                                <h3 className='d-flex align-items-center gap-2'>
                                  Select All
                                  <Field
                                      type='checkbox'
                                      name='select_all'
                                      checked={isAllSelected()}
                                      onChange={handleSelectAll}
                                  />
                                </h3>
                                </div>
                                {selectedSessions.map((session) =>
                                  session?.program_track?.map((track, index) => (
                                    <div key={index} className='d-flex gap-2'>
                                      <Field
                                          type='checkbox'
                                          name='selected_items'
                                          value={track.track_id.toString()}
                                          checked={selectAll || selectedTracks.includes(track)}
                                          onChange={() => handleTrackChange(track, setFieldValue)}
                                      />
                                      <label>{track.name}</label>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className='text-center pt-15'>
                        <button
                          disabled={isSubmitting}
                          type='submit'
                          className='btn btn-primary cursor-pointer'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default EnrollmentAdd
