import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAuth } from '../../../../auth'
import {useListView} from '../core/PaymentSchedulesListViewProvider'

const PSListToolbar = () => {
  const {currentUser} = useAuth()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add Payment Schedule */}
      <Link
        to={`/apps/${currentUser?.currentClient}/payment-schedules-management/new`}
        replace={true}
        className='btn btn-primary'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New Payment Schedule
      </Link>
      {/* end::Add Payment Schedule */}
    </div>
  )
}

export {PSListToolbar}
