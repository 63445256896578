import clsx from 'clsx'
import { Field, ErrorMessage } from 'formik'
import { COUNTRIES } from '../../../../../../utils'
import { IProgram } from '../../../core/_models'
import React, { useState } from 'react'
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../../../../../utils'
interface Props {
  sameAddressOpt: boolean
  currentFormikVal: IProgram
  country: string
  setCountryValue: any
  setFieldValue: any
}
const AddressInfoParentField = ({ sameAddressOpt, currentFormikVal, country, setCountryValue, setFieldValue }: Props) => {
  const fieldClassName = clsx(
    'form-control form-control-lg',
    sameAddressOpt ? 'form-control-white' : 'form-control-solid'
  )
  const [selected, setSelected] = useState('')

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (field == 'country') {
      if (sameAddressOpt) {
        setCountryValue(e.target.value);
      } else {
        setFieldValue('contactInfo.parentAddress.country', e.target.value)
        setSelected(e.target.value)
        country = e.target.value
      }
    }
  }
  return (
    <>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Address</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.parentAddress.address'}
          disabled={sameAddressOpt}
          defaultValue={sameAddressOpt?currentFormikVal.contactInfo?.studentAddress?.address:currentFormikVal.contactInfo?.parentAddress?.address}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.parentAddress.address'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>City</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.parentAddress.city'}
          disabled={sameAddressOpt}
          defaultValue={fieldClassName?currentFormikVal.contactInfo?.studentAddress?.city:currentFormikVal.contactInfo?.parentAddress?.city}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.parentAddress.city'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Postal Code</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.parentAddress.postalCode'}
          disabled={sameAddressOpt}
          defaultValue={sameAddressOpt?currentFormikVal.contactInfo?.studentAddress?.postalCode:currentFormikVal.contactInfo?.parentAddress?.postalCode}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.parentAddress.postalCode'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Country</label>
        <Field
          as='select'
          name={'contactInfo.parentAddress.country'}
          className={fieldClassName}
          disabled={sameAddressOpt}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'country')}
          defaultValue={sameAddressOpt?currentFormikVal.contactInfo?.studentAddress?.stateProvince:currentFormikVal.contactInfo?.parentAddress?.stateProvince}
        >
          <option>Select Country</option>
          {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
            return (
              <option value={c.alpha_3} key={idx}>
                {c.name}
              </option>
            )
          })}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.parentAddress.country'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>State/Province</label>
        {sameAddressOpt ?
          <Field
            as='select'
            className={fieldClassName}
            name={'contactInfo.parentAddress.stateProvince'}
            disabled={sameAddressOpt}
            defaultValue={currentFormikVal.contactInfo?.studentAddress?.stateProvince}
          >
            <option>Select State/Province</option>
            {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
              if (c.alpha_3 == country) {
                let provinces = c.divisions?.map((d, id) => {
                  return (
                    <option value={d.code} key={id}>
                      {d.name}
                    </option>
                  )
                })
                return provinces
              }
            })}
          </Field>
          :
          <Field
            as='select'
            className={fieldClassName}
            name={'contactInfo.parentAddress.stateProvince'}
            disabled={sameAddressOpt}
            defaultValue={currentFormikVal.contactInfo?.parentAddress?.stateProvince}
          >
            <option>Select State/Province</option>
            {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
              if (c.alpha_3 == selected) {
                let provinces = c.divisions?.map((d, id) => {
                  return (
                    <option value={d.code} key={id}>
                      {d.name}
                    </option>
                  )
                })
                return provinces
              }
            })}
          </Field>
        }
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.parentAddress.stateProvince'} />
        </div>
      </div>

    </>
  )
}

export default AddressInfoParentField
