import { QueryRequestProvider } from '../../../Shared/core/QueryRequestProvider'
import {ProductsListViewProvider, useListView} from './core/ProductsListViewProvider'

import {QueryResponseProvider} from './core/QueryResponseProvider'

type Props = {
  children: JSX.Element
}

const ProductsProvider = ({children}: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ProductsListViewProvider>{children}</ProductsListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default ProductsProvider
